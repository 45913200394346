import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'

const ProjectsCategories = () => {

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

    const [CatName, setCatName] = useState(searchParams.get('nomdecat') ? searchParams.get('nomdecat') : '')
    const [ValidCat, setValidCat] = useState(searchParams.get('validcat') ? searchParams.get('validcat') : 'vide')


    const [UrlPagination, setUrlPagination] = useState('')

    const [Wilaya, setWilaya] = useState(searchParams.get('wilaya') ? searchParams.get('wilaya') : 'all')




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);

            const NameCat = CatName?.toString().trim() !== '' ? '&nomdecat=' + CatName : '';
            const valid_cat = ValidCat == 1 || ValidCat == 0 ? '&validcat=' + ValidCat : '';


            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects_categories/search?page=${page}${NameCat}${valid_cat}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    async function removeBoutique(id) {

        console.log(id);

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Êtes-vous sûr de vouloir supprimer cette Catégorie ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects_categories/delete_categorie/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {

                        const updatefListVentes = ListVentes.filter(item => item.id_cat !== id);
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            'Supprimée!',
                            'La Catégorie a été supprimée avec succès',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })

    }

    useEffect(() => {


        const cat_name = CatName.trim() !== '' ? '&nomdecat=' + CatName : '';
        const valid_cat = (ValidCat == 1 || ValidCat == 0) ? '&validcat=' + ValidCat : '';

        let url = cat_name + valid_cat;

        setUrlPagination(url)



    }, [CatName, ValidCat])

    function SearchQUERY() {
        const fetchData = async () => {
            setLoading(true);

            const NameCat = CatName?.toString().trim() !== '' ? '&nomdecat=' + CatName : '';
            const valid_cat = ValidCat == 1 || ValidCat == 0 ? '&validcat=' + ValidCat : '';

            

            setSearchParams({ page: 1, ...(CatName.trim() !== '' && { nomdecat: CatName }), ...((ValidCat == 1 || ValidCat == 0) && { validcat: ValidCat }) })
            // setSearchParams({page : 1,nomdecat: CatName.trim() !== '' ?  CatName :  ''})

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects_categories/search?page=${1}${NameCat}${valid_cat}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            SearchQUERY();
        }
    };






    const Validate = async (id, etat) => {
        // setLoading(true);

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: etat == 1 ? "Êtes-vous sûr d'activer la Catégorie ?" : "Êtes-vous sûr de désactiver la Catégorie ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects_categories/valider/${id}/${etat}`,{
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }
        
                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );
                        
                    }
        
                    if (response['error'] == false) {
                        const updatefListVentes = ListVentes.map(obj =>
                            obj.id_cat == id ? { ...obj, active: etat } : obj
                        );
        
                        //   const updatefListVentes = ListVentes.find( p => p.id_vendeur == id && ( p.etat = etat, true ) );
        
        
                        //   const updatefListVentes =  ListVentes.filter(item => item.id_vendeur !== id);
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            etat == 1 ? 'Activé!' : 'Désactiver!',
                            etat == 1 ?  'La Catégorie a été activée avec succès' : 'La Catégorie a été désactivée avec succès',
                            'success'
                        );
                        
                        
                    }
                   
                    
                     
        
                
                } catch (error) {
                    console.error(error.message);
                }
              },
        })
    }





    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Liste Des Catégories de Projets<span>Iamal</span></div>


                    <div className='new-admin-btn' onClick={() => navigate('/createcategory')}> Nouvelle catégorie </div>


                    <div className='store-search-section'>
                        <div>
                            <input onKeyDown={handleKeyDown} type="text" placeholder={"Nom de Catégorie"} onChange={(e) => setCatName(e.target.value)} value={CatName} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setValidCat(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Validité</option>
                                <option selected={ValidCat == 'all'} value={'all'}>Tous</option>
                                <option selected={ValidCat == 1} value={1}>Validé</option>
                                <option selected={ValidCat == 0} value={0}>Non Validé</option>
                            </select>
                        </div>
                        
                        <div className='search-btn-dashboard' onClick={SearchQUERY}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.621" height="30.621" viewBox="0 0 30.621 30.621">
                                <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1.5 1.5)">
                                    <path id="Tracé_1" data-name="Tracé 1" d="M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Tracé_2" data-name="Tracé 2" d="M31.5,31.5l-6.525-6.525" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>


                        </div>

                    </div>


                    <div className='dashboard-table'>
                        <table>

                            <thead>
                                <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Nom</th>
                                    <th scope="col">Activé</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {!Loading && <tbody>

                                {ListVentes?.length > 0 && ListVentes?.map((item, i) => (

                                    <tr key={`AllBoutiquesAdmin${item?.id_boutique}`}>
                                        <td data-label="Logo"><img style={{maxWidth:"141px"}} src={item?.cat_img != 'undefined' && item?.cat_img != null ? `${process.env.REACT_APP_LOCAL_URL}/images/projectscategories/${item?.cat_img}` : 'https://artsmidnorthcoast.com/wp-content/uploads/2014/05/no-image-available-icon-6.png'} /></td>
                                        <td data-label="Nome">{item?.cat_name}</td>
                                        <td data-label="Valid">
                                            <a onClick={() => Validate(item?.id_cat, item?.active == 1 ? 0 : 1)} >
                                                {/* <div className='activeate-btn my-1 me-2'> */}
                                                {/* {item?.active == 1 ? 'inactiveer' : 'activeer'} */}
                                                <div>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={(e) => console.log('')} checked={item?.active == 1 ? true : false} />
                                                        <span className="slider round"><p className={item?.active == 1 ? 'checked-p' : 'unchecked-p'}>{item?.active == 1 ? 'Oui' : 'Non'}</p> </span>
                                                    </label>
                                                </div>
                                                {/* </div> */}
                                            </a>


                                        </td>
                                        <td className='vendeursadminlist' data-label=" ">
                                            <a href={`/updateprojectcategory/${item?.id_cat}`}>
                                                <div className='update-btn'><svg xmlns="http://www.w3.org/2000/svg" width="21.167" height="20.59" viewBox="0 0 21.167 20.59">
                                                    <g id="edit-246" transform="translate(-134.2 -129)">
                                                        <path id="Tracé_9269" data-name="Tracé 9269" d="M152.96,169.464a4,4,0,0,1-3.993,3.993h-9.63a4,4,0,0,1-3.993-3.993v-9.628a4,4,0,0,1,3.993-3.993h3.478V154.7h-3.478a5.143,5.143,0,0,0-5.137,5.137v9.628a5.143,5.143,0,0,0,5.137,5.137h9.628a5.143,5.143,0,0,0,5.137-5.137v-3.478h-1.144v3.478Z" transform="translate(0 -25.011)" fill="#fff" />
                                                        <path id="Tracé_9270" data-name="Tracé 9270" d="M278.443,129.922a3.15,3.15,0,0,0-4.453,0l-11.026,11.026a2.943,2.943,0,0,0-.836,1.675l-.509,3.558a.5.5,0,0,0,.493.571.66.66,0,0,0,.072-.005l3.558-.509a2.959,2.959,0,0,0,1.675-.836l11.026-11.026A3.149,3.149,0,0,0,278.443,129.922ZM264.3,141.227l9.333-9.333,2.832,2.835-9.33,9.333Zm1.278,3.877-2.709.386.386-2.709a1.835,1.835,0,0,1,.265-.715l2.771,2.771A1.747,1.747,0,0,1,265.581,145.1Zm12.053-11.541-.356.356-2.832-2.835.356-.356a2,2,0,1,1,2.832,2.835Z" transform="translate(-123.999)" fill="#fff" />
                                                    </g>
                                                </svg>
                                                </div></a>
                                            <a className='ms-3' onClick={() => removeBoutique(item?.id_cat)} > <div className='delete-btn my-1 '><svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                            </svg>
                                            </div></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                        {Loading &&
                            <div className='dashboard-loader'>
                                <div className='cart-sum-loader flex-column'>
                                    <p className='mb-1'>Chargement</p>
                                    <div><div className="spinner-grow text-danger" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                        <div className="spinner-grow text-danger" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-danger" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            ListVentes?.length === 0 || !ListVentes && !Loading &&
                            <div className='no-results'>Il n'y a pas de données</div>

                        }

                    </div>
                    <div>
                        {PageNumber > 1 &&
                            <div class="text-center mt-5 pt-1 mb-5">
                                <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${PageNumber} ${CurrentPage < PageNumber}`}>
                                    {CurrentPage > 1 ? <a href={`?page=1${UrlPagination}`}  >{'<<'}</a> : <a>{'<<'}</a>}

                                    {CurrentPage !== 1 ? <a href={`?page=${parseInt(CurrentPage) - 1}${UrlPagination}`}>&laquo;</a> : <a>&laquo;</a>}



                                    {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                                        <>
                                            {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                                                <a href={`?page=${item + 1}${UrlPagination}`} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                                                : ''}
                                        </>

                                    ))}



                                    {CurrentPage < PageNumber ? <a href={`?page=${parseInt(CurrentPage) + 1}${UrlPagination}`}>&raquo;</a> : <a>&raquo;</a>}

                                    {CurrentPage < PageNumber ? <a href={`?page=${PageNumber}${UrlPagination}`}  >{'>>'}</a> : <a>{'>>'}</a>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ProjectsCategories