import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import LoadData from '../../components/Global/LoadData';
import GoBack from '../../components/Global/GoBack';

const ContactRequestsByid = () => {

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

  let { id } = useParams();




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/contact/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data'][0]);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
            setLoading(false);

                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Contact<span>Iamal</span></div>

                    <GoBack url={"/contact-requests"} />


                    {Loading ? <LoadData /> : ''}

                    {!Loading && ListVentes?.length != 0 ? 

                    <>
                    <div className="conatiner mb-5">
                        <ul className="list-group list-store-admin">
                            <li className={`list-group-item active gradiant`} aria-current="true">Information Generale</li>
                            {ListVentes?.name ? <li className="list-group-item"> <b>Nom et Prénom </b>: {ListVentes?.name}</li> : ''}
                            {ListVentes?.email ? <li className="list-group-item"><b> Email </b>: {ListVentes?.email}</li> : ''}                        
                            {ListVentes?.mobile ? <li className="list-group-item"><b> Mobile </b>: {ListVentes?.mobile}</li> : ''}
                            {/* {ListVentes?.company_name ? <li className="list-group-item"><b> Nom de l'entreprise </b>: {ListVentes?.company_name}</li> : ''} */}
                        </ul>
                    </div>
                    <div className="conatiner mb-5">
                        <ul className="list-group list-store-admin">
                            <li className={`list-group-item active gradiant`} aria-current="true">Message</li>
                             <li className="list-group-item">{ListVentes?.message ?  <p>{ListVentes?.message}</p>   : "Pas de message"}</li>
                           
                        </ul>
                    </div>
                    
                    </>
                    : ""}
                </div>
            </div>


        </div>
    )
}

export default ContactRequestsByid