import { useEffect, useState, useContext } from 'react'
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import { Link } from "react-router-dom";
import BankContext from '../../Context/bank'
import LangContext from '../../Context/lang';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Swal from 'sweetalert2'


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1400 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 1400, min: 991 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 991, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const FormProjectEquipments = ({ item, EquipmentCart, setEquipmentCart,projectPrice ,projectCategory}) => {

    const { Lang, ProductLang, Global } = useContext(LangContext);


    const [Product, setProduct] = useState([]);

    const { bankActive, setBankActive, GetTaksitPrice, Panier } = useContext(BankContext);


    const [ModalData, setModalData] = useState({})

    const EquipmentQuantity = EquipmentCart?.find(o => o.id == item?.id);
    console.log(EquipmentQuantity?.quantity);

    const [Quantity, setQuantity] = useState(EquipmentQuantity?.quantity || 1);





    function TTC(Price) {
        // Type "1" === "غير ماكثة في البيت"
        // if (Product?.id_category != "7") {

        //     return Math.round((Price + (Price * 0.10 + 16000)) * 1.19) + 25000;

        // } else {
            // Type "2" === "ماكثة في البيت"
            return Math.round((Price + (Price * 0.10 + 9000)) * 1.19);
        // }
    }


    // const showDetail = (id, title, img, price, description) => {

    //     setModalData({ "id": id, "title": title, "img": img, "price": price, "description": description })
    // }

    const GetTotalPrice = ()=> {

        if (EquipmentCart?.length) {
            
            return EquipmentCart?.reduce((n  , { quantity, price }) =>n + (quantity * price), 0) + TTC(projectPrice) || 0


        }

        return TTC(projectPrice) || 0
      }


      const MaxAmount = projectCategory != "7" ? 1500000 : 500000;

    function AddEquipmentToProject(type, Product) {

        if (type == 'add') {

            if ((GetTotalPrice() + (Product.price * Product.quantity)) > MaxAmount ) {

                Swal.fire({
                    title: '',
                    type: 'warning',
                    text: ` لقد تجاوزت الحد الأقصى  لقيمة التمويل المقدرة  بــ ${MaxAmount} دج  `,
                    confirmButtonText: "مفهوم",
                    showConfirmButton: true,
                    // allowOutsideClick: false,
                    // allowEscapeKey: false,
                    icon: 'warning',

                }).then(function (result) {
                    if (result.isConfirmed) {
                        //  return window.location = `/payment`;
                    }
                });

                return
            }

            if (EquipmentCart?.filter(item => item?.id == Product?.id)?.length == 0) {
                setEquipmentCart(prev => [...prev, Product])
            }

            // if (!EquipmentCart?.includes(Product)) {
            //     setEquipmentCart(prev => [...prev, Product])
            // }

        } else {
            const SliceList = EquipmentCart.filter(item => item.id != Product?.id);
            setEquipmentCart(SliceList)

            // const index = EquipmentCart?.indexOf(Product);

            // console.log(index);
            // if (index > -1) {
            //     const eq = EquipmentCart?.splice(index, 1);
            //     console.log(eq);
            //     setEquipmentCart(eq)
            // }
        }
        // if (type == 'add') {

        //     if (ListEquipment.filter(item=> item?.id == Product?.id).length == 0){

        //         setListEquipment( prev => [...prev,Product])
        //     }


        // }

    }

    // console.log(Product);

    function ProductQuantity(type) {

        if (type === "up") {
            if (Quantity !== 1) {
                setQuantity(Quantity - 1)
                // console.log(GetItem.item.hash, Quantity);
                updateState(item?.id, Quantity - 1, 'min')
            }
        } else {

            if (Quantity) {

                if ((GetTotalPrice() + TTC(item?.price)) > MaxAmount ) {

                    Swal.fire({
                        title: '',
                        type: 'warning',
                        text: ` لقد تجاوزت الحد الأقصى  لقيمة التمويل المقدرة  بــ ${MaxAmount} دج  `,
                        confirmButtonText: "مفهوم",
                        showConfirmButton: true,
                        // allowOutsideClick: false,
                        // allowEscapeKey: false,
                        icon: 'warning',
    
                    }).then(function (result) {
                        if (result.isConfirmed) {
                            //  return window.location = `/payment`;
                        }
                    });

                    return
                }


                // console.log(CartTotal() + (GetItem.item.price));
                // if ((CartTotal() + (GetItem.item.price)) < MaxAmount) {

                // } else {
                //     return
                // }


                setQuantity(Quantity + 1)
                // console.log(item?.id, Quantity);
                updateState(item?.id, Quantity + 1)
            }
        }

    }


    const updateState = (id, quantity, action) => {

        console.log(id, quantity);

        // if (action == 'min' || CartTotal() < MaxAmount) {

        // } else {
        //   return
        // }
        // console.log(CartTotal(), MaxAmount);

        const newState = EquipmentCart?.map(obj => {
            if (obj?.id == id) {

                // if (quantity <= obj?.quantity ) {


                return { ...obj, quantity: quantity };
                // }
            }
            return obj;
        });

        setEquipmentCart(newState);
        // localStorage.setItem('cart', JSON.stringify(newState))

    };

    // const [checkboxValues, setCheckboxValues] = useState([]);

    // const handleCheckboxChange = (event) => {
    //     const { value, checked } = event.target;
    //     if (checked) {
    //         setCheckboxValues((prevValues) => [...prevValues, value]);
    //     } else {
    //         setCheckboxValues((prevValues) =>
    //             prevValues.filter((item) => item !== value)
    //         );
    //     }
    // };

    // console.log(checkboxValues);

    console.log(EquipmentCart);


    return (
        <>






           


            <div className="equipment-item mt-2">
                <div className=''>
                <div>                               
                    <input
                        checked={!EquipmentCart?.filter(equipment => equipment?.id == item?.id)?.length == 0}
                        // onChange={handleCheckboxChange}
                        onClick={()=> EquipmentCart?.filter(equipment => equipment?.id == item?.id)?.length == 0 ? AddEquipmentToProject('add', { 'id': item?.id, 'price': item?.price ? TTC(parseInt(item?.price)) : 0, 'quantity': Quantity }) : AddEquipmentToProject('remove', { 'id': item?.id, 'price': item?.price ? TTC(parseInt(item?.price)) : 0, 'quantity': Quantity })}
                        type="checkbox"
                        className="label-checkbox me-3"
                        value={item?.id}
                    />

                    <img className='img-fluid links-equipment-img' src={item?.image_1 != 'undefined' && item?.image_1 != null ? `${process.env.REACT_APP_LOCAL_URL}/images/projectsEquipment/${item?.image_1}` : 'https://artsmidnorthcoast.com/wp-content/uploads/2014/05/no-image-available-icon-6.png'} />
                 </div> 
                    <div className='d-flex'>
                <div className='me-md-3' dir={Lang === 'ar' ? 'rtl' : ''}>
                                    <div  className='product-quantity'>{(Panier?.['Quantité']?.[Lang])}
                                        {/* <div className='max-quantity'> Max {MaxQuantity ? MaxQuantity : '1'}</div> */}
                                        <div className={Lang === 'ar' ? 'quantity-btn ms-0' : 'quantity-btn ms-0'} dir='ltr'>
                                            <button className="qtyminus" onClick={() => ProductQuantity("up")} aria-hidden="true">−</button>
                                            <input style={{width : '37px'}} className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Quantity} />
                                            <button className="qtyplus" onClick={() => ProductQuantity("down")} aria-hidden="true">+</button>
                                        </div>
                                    </div>
                                </div>

                                
                 </div> 

                 
                   <label htmlFor="inputEmail4" className="form-label ">

                       <div className='mb-2 mt-2' style={{maxWidth : "200px" , lineHeight : '1.2', fontSize :'13px',fontWeight : '700'}}> {item?.title}</div>
                        
                        {
                                            item?.price ?
                         <div className="product-price mb-3 me-auto" >
                                                    {(Lang === 'ar' ? <> <span className="me-2"> دج / للشهر  </span>  {GetTaksitPrice(TTC(parseInt(item?.price)), '60')['getallprice']?.toLocaleString('fr')} </> : <>{GetTaksitPrice(TTC(parseInt(item?.price)), '60')['getallprice']?.toLocaleString('fr')} DA <span>/ mois</span></>)}
                                                </div> :'' }
                         </label>


                        
                </div>

                {/* <input type="color"  value={item?.color} className="form-control input-color" id="inputEmail4" /> */}
                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
            </div>

        </>

    )
}

export default FormProjectEquipments