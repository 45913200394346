import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const UpdateNews = () => {

    const [BoutiquesList, setBoutiquesList] = useState([])
    const [Boutique, setBoutique] = useState([])
    const [IdVente, setIdVente] = useState()



    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const module = {
        toolbar: toolbarOptions
    }

    // useEffect(() => {

    //     const fetchData = async () => {
    //         // setLoading(true);
    //         try {
    //             const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/boutique/alluserboutiques/userid`, {
    //                 headers: {
    //                     'Authorization': `Bearer ${Auth?.token}`
    //                 }
    //             });
    //             if (response['message'] == 'Token invalid') {
    //                 setAuth(false);
    //                 return navigate('/account')

    //             }
    //             // console.log(response[0]);
    //             setBoutiquesList(response['data']);
    //             // console.log(DarTaksitCat)

    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //         // setLoading(false);
    //     }

    //     fetchData();

    // }, []);

    const { id_vente } = useParams();
    const [ProductData, setProductData] = useState('')


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/news/admin/${id_vente}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhh');
                console.log(response['data']);
                setProductData(response['data'])

                setIdVente(response['data']['id'])


                setTitreduproduit(response['data']['title'])
                setTitreFr(response['data']['title_fr'])

                // setDatedébutdevente(response['data'][''])               
                setPosition(response['data']['position'])
                setDescription(response['data']['description'] || '')
                setDescriptionFR(response['data']['description_fr'] || '')

                setValid(response['data']['valid'] == 1 ? true : false)
                // setCatégorie(response['data']['id_category'])

                // setPrix(response['data']['price'] || '')
                setFichetechnique(response['data']['Technical_card'] || '')
                setFichetechniqueFr(response['data']['Technical_card_fr'] || '')






                if (response['data']['image_1'] !== 'undefined' && response['data']['image_1'] !== null) {

                    setPreview1(`${process.env.REACT_APP_LOCAL_URL}/images/newsImages/${response['data']['image_1']}`)
                    setImage1(true)
                    setinitPreview1(true)
                }


                if (response['data']['image_2'] !== 'undefined' && response['data']['image_2'] !== null) {
                    setPreview2(`${process.env.REACT_APP_LOCAL_URL}/images/newsImages/${response['data']['image_2']}`)
                    setImage2(true)
                    setinitPreview2(true)

                }

                if (response['data']['image_3'] !== 'undefined' && response['data']['image_3'] !== null) {
                    setPreview3(`${process.env.REACT_APP_LOCAL_URL}/images/newsImages/${response['data']['image_3']}`)
                    setImage3(true)
                    setinitPreview3(true)

                }

                if (response['data']['image_4'] !== 'undefined' && response['data']['image_4'] !== null) {
                    setPreview4(`${process.env.REACT_APP_LOCAL_URL}/images/newsImages/${response['data']['image_4']}`)
                    setImage4(true)
                    setinitPreview4(true)

                }

                if (response['data']['image_5'] !== 'undefined' && response['data']['image_5'] !== null) {
                    setPreview5(`${process.env.REACT_APP_LOCAL_URL}/images/newsImages/${response['data']['image_5']}`)
                    setImage5(true)
                    setinitPreview5(true)

                }



            } catch (error) {
                setLoading(false);

                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    // const [Category, setCategory] = useState([])
    // useEffect(() => {

    //     const fetchData = async () => {
    //         // setLoading(true);
    //         try {
    //             const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/projects_categories`);
    //             // console.log(response[0]);
    //             setCategory(response['data']);
    //             // console.log(DarTaksitCat)

    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //         // setLoading(false);
    //     }

    //     fetchData();

    // }, []);

    // console.log(Category);

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)





    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const hiddenFileInput3 = useRef(null);
    const hiddenFileInput4 = useRef(null);
    const hiddenFileInput5 = useRef(null);





    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Titreduproduit, setTitreduproduit] = useState('')
    const [TitreFr, setTitreFr] = useState('')

    const [Réferenceduproduit, setRéferenceduproduit] = useState('')
    const [Marque, setMarque] = useState('')
    const [Catégorie, setCatégorie] = useState('')
    const [Quantité, setQuantité] = useState('')
    const [Quantitéunit, setQuantitéunit] = useState('')
    const [Datedébutdevente, setDatedébutdevente] = useState('')
    const [Datefindevente, setDatefindevente] = useState('')
    const [Position, setPosition] = useState('')
    const [Conditionderetour, setConditionderetour] = useState('')
    const [Délaisdannulation, setDélaisdannulation] = useState('')
    const [Promo, setPromo] = useState(false)
    const [Stock, setStock] = useState(true)
    const [Valid, setValid] = useState(true)
    const [Prix, setPrix] = useState('')
    const [Fichetechnique, setFichetechnique] = useState('')
    const [FichetechniqueFr, setFichetechniqueFr] = useState('')

    const [Positionpromotionnel, setPositionpromotionnel] = useState('')
    const [Datedébutpromotion, setDatedébutpromotion] = useState('')
    const [Datefinpromotion, setDatefinpromotion] = useState('')
    const [Caractéristiques, setCaractéristiques] = useState('')
    const [Description, setDescription] = useState('')
    const [DescriptionFR, setDescriptionFR] = useState('')

    const [Shortdescription, setShortdescription] = useState('')
    const [Image1, setImage1] = useState('')
    const [Image2, setImage2] = useState('')
    const [Image3, setImage3] = useState('')
    const [Image4, setImage4] = useState('')
    const [Image5, setImage5] = useState('')



    const [preview1, setPreview1] = useState()
    const [preview2, setPreview2] = useState()
    const [preview3, setPreview3] = useState()
    const [preview4, setPreview4] = useState()
    const [preview5, setPreview5] = useState()

    const [initpreview1, setinitPreview1] = useState(false)
    const [initpreview2, setinitPreview2] = useState(false)
    const [initpreview3, setinitPreview3] = useState(false)
    const [initpreview4, setinitPreview4] = useState(false)
    const [initpreview5, setinitPreview5] = useState(false)

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    console.log(Promo);

    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Titreduproduit: '', Réferenceduproduit: '', Marque: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {



        if (Titreduproduit?.toString().trim().length > 300 || Titreduproduit?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Titreduproduit: 'Veuillez saisir un titre correct !!' }))
        } else {
            seterrors((prev) => ({ ...prev, Titreduproduit: '' }))
        }

        if (TitreFr?.toString().trim().length > 300 || TitreFr?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, TitreFr: 'Veuillez saisir un titre fr correct !!' }))
        } else {
            seterrors((prev) => ({ ...prev, TitreFr: '' }))
        }

        
        // if (Catégorie?.toString().trim().length > 20 || Catégorie?.toString().trim() == '' || !onlyNumbers(Catégorie)) {
        //     seterrors((prev) => ({ ...prev, Catégorie: 'Veuillez séléctionner une catégorie' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Catégorie: '' }))
        // }


        if (!onlyNumbers(Position)) {
            seterrors((prev) => ({ ...prev, Position: 'Veuillez saisir le Position (le Position du produit doit étre un nombre entier positif)!!' }))
        } else {
            seterrors((prev) => ({ ...prev, Position: '' }))
        }

        if (Description?.toString().trim().length > 1000000) {
            seterrors((prev) => ({ ...prev, Description: 'Veuillez écrire les informations' }))
        } else {
            seterrors((prev) => ({ ...prev, Description: '' }))
        }

        if (DescriptionFR?.toString().trim().length > 1000000) {
            seterrors((prev) => ({ ...prev, DescriptionFR: 'Veuillez écrire les informations' }))
        } else {
            seterrors((prev) => ({ ...prev, DescriptionFR: '' }))
        }

        if (Fichetechnique?.toString().trim().length > 1000000) {
            seterrors((prev) => ({ ...prev, Fichetechnique: 'Veuillez saisir la Déscription' }))
        } else {
            seterrors((prev) => ({ ...prev, Fichetechnique: '' }))
        }

        if (FichetechniqueFr?.toString().trim().length > 1000000) {
            seterrors((prev) => ({ ...prev, FichetechniqueFr: 'Veuillez saisir la Déscription' }))
        } else {
            seterrors((prev) => ({ ...prev, FichetechniqueFr: '' }))
        }

    //     if (Prix) {
            
    //     if (parseInt(Prix) > 1000000000 || parseInt(Prix) <= 0 || !onlyNumbers(Prix)) {
    //         seterrors((prev) => ({ ...prev, Prix: 'Veuillez saisir le prix du produit (le prix du produit doit étre un nombre entier positif)!!' }))
    //     } else {
    //         seterrors((prev) => ({ ...prev, Prix: '' }))
    //     }
    // }else{
    //     seterrors((prev) => ({ ...prev, Prix: '' }))
    // }


        if (Image1) {
            if (Image1.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image1: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image1: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image1: '' }))
        }

        if (Image2) {
            if (Image2.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image2: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image2: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image2: '' }))
        }
        if (Image3) {
            if (Image3.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image3: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image3: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image3: '' }))
        }

        if (Image4) {
            if (Image4.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image4: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image4: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image4: '' }))
        }

        if (Image5) {
            if (Image5.size > 2097152) {
                seterrors((prev) => ({ ...prev, Image5: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
            } else {
                seterrors((prev) => ({ ...prev, Image5: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image5: '' }))
        }

        if (!Image1 && !Image2 && !Image3 && !Image4 && !Image5) {
            seterrors((prev) => ({ ...prev, Imageall: "Merci de mettre au moins une photo" }))
        } else {
            seterrors((prev) => ({ ...prev, Imageall: '' }))
        }



    }

    useEffect(() => {

        Validate();

    }, [Boutique, Titreduproduit,TitreFr,Fichetechnique,FichetechniqueFr,Prix, Réferenceduproduit, Marque, Quantité, Quantitéunit, Datedébutdevente, Datefindevente, Position, Conditionderetour, Caractéristiques, Description,DescriptionFR, Shortdescription, Promo, Datedébutpromotion, Datefinpromotion, Positionpromotionnel, Image1, Image2, Image3, Image4, Image5])


    useEffect(() => {

        if (Image1 == true) {
            return
        }
        if (!Image1) {
            setPreview1(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image1)
        setPreview1(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image1])


    useEffect(() => {
        if (Image2 == true) {
            return
        }
        if (!Image2) {
            setPreview2(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image2)
        setPreview2(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image2])


    useEffect(() => {
        if (Image3 == true) {
            return
        }
        if (!Image3) {
            setPreview3(undefined)
            return
        }


        const objectUrl = URL.createObjectURL(Image3)
        setPreview3(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image3])

    useEffect(() => {
        if (Image4 == true) {
            return
        }
        if (!Image4) {
            setPreview4(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image4)
        setPreview4(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image4])

    useEffect(() => {
        if (Image5 == true) {
            return
        }
        if (!Image5) {
            setPreview5(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image5)
        setPreview5(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image5])


    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)
 
        if (errors.Titreduproduit !== '' 
            || errors.Position !== '' || errors.Description !== '' || errors.Fichetechnique !== '' || errors.Imageall !== '' || errors.Image1 !== '' || errors.Image2 !== '' || errors.Image3 !== '' || errors.Image4 !== '' || errors.Image5 !== ''
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        const formData = new FormData();


        // formData.append("selectedFile", Délaisdannulation);
        formData.append("title", Titreduproduit);
        formData.append("title_fr", TitreFr);

        // formData.append("id_categorie", Catégorie);
        formData.append("position", Position);
        formData.append("valid", Valid == true ? 1 : 0);
        formData.append("description", Description);
        formData.append("description_fr", DescriptionFR);

        // formData.append("prix", Prix);
        formData.append("fichetechnique", Fichetechnique);
        formData.append("fichetechnique_fr", FichetechniqueFr);


        formData.append("image_1", Image1);
        formData.append("image_2", Image2);
        formData.append("image_3", Image3);
        formData.append("image_4", Image4);
        formData.append("image_5", Image5);







        // try {
        //   const response = await axios.post({
        //     method: "post",
        //     url: "${process.env.REACT_APP_LOCAL_URL}/vente/new_vente/11112222",
        //     data: formData,
        //     headers: { 
        //         "Content-Type": "multipart/form-data" ,
        //          Authorization: `Bearer ${Auth?.token}` 
        //     },
        //   });

        //   console.log(response);
        // } catch(error) {
        //   console.log(error)
        // }

        setLoading(true)


        axios.put(`${process.env.REACT_APP_LOCAL_URL}/news/update_vente/${ProductData?.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)

                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été modifiées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        return navigate("/news")

                        // window.location = "/listventesadmin";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }



    const DeleteImage = async (position, url) => {
        // setLoading(true);

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Êtes-vous sûr de vouloir supprimer cette Image ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/news/deleteimg`, { "position": position, "id": IdVente, "url": url }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'image n'a pas été supprimée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {
                        if (position === "1") {
                            setPreview1(null)
                            setImage1(null)
                            setinitPreview1(false)
                        }
                        if (position === "2") {
                            setPreview2(null)
                            setImage2(null)
                            setinitPreview2(false)
                        }
                        if (position === "3") {
                            setPreview3(null)
                            setImage3(null)
                            setinitPreview3(false)
                        }
                        if (position === "4") {
                            setPreview4(null)
                            setImage4(null)
                            setinitPreview4(false)
                        }
                        if (position === "5") {
                            setPreview5(null)
                            setImage5(null)
                            setinitPreview5(false)
                        }

                        await Swal.fire(
                            'Deleted!',
                            'La boutique a été supprimée avec succès',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })
        // .then(async (result) => {
        //     if (result.isConfirmed) {
        //         // await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/delete_admin/${id}`);






        //     } 
        // })


        // setLoading(false);
    }


    function ClearInputFile(input_number) {

        if (input_number === 1) {
            hiddenFileInput1.current.value = "";
            setImage1(null);
        } else if (input_number === 2) {
            hiddenFileInput2.current.value = "";
            setImage2(null);
        } else if (input_number === 3) {
            hiddenFileInput3.current.value = "";
            setImage3(null);
        } else if (input_number === 4) {
            hiddenFileInput4.current.value = "";
            setImage4(null);
        } else if (input_number === 5) {
            hiddenFileInput5.current.value = "";
            setImage5(null);
        }

    }




    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Mettre à jour l'actualité<span>Iamal</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        <form className="row g-5">
                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Titre (Ar) </label>
                                <input maxLength={100} type="text" onChange={(e) => setTitreduproduit(e.target.value)} value={Titreduproduit} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Titreduproduit !== '' ? errors.Titreduproduit : ''}</div>
                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Titre (Fr)</label>
                                <input maxLength={300} type="text" onChange={(e) => setTitreFr(e.target.value)} value={TitreFr} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors?.TitreFr !== '' ? errors?.TitreFr : ''}</div>
                            </div>

                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Date début de vente</label>
                                <input type="date" className="form-control" onChange={(e) => setDatedébutdevente(e.target.value)} value={Datedébutdevente} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Datedébutdevente !== '' ? errors.Datedébutdevente : ''}</div>

                            </div> */}
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Date fin de vente</label>
                                <input type="date" className="form-control" onChange={(e) => setDatefindevente(e.target.value)} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Datefindevente !== '' ? errors.Datefindevente : ''}</div>

                            </div> */}
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Position</label>
                                <input type="tel" className="form-control" onChange={(e) => setPosition(e.target.value.replace(/[^0-9]+/g, ''))} value={Position} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Position !== '' ? errors.Position : ''}</div>

                            </div>
                            {/* <div class="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label for="inputState" class="form-label">Catégorie</label>
                                <select id="inputState" class="form-select" onChange={(e) => setCatégorie(e.target.value)}>
                                    <option selected={"selected"} disabled value="">Catégories</option>
                                    {Category?.length > 0 && Category?.map((item, i) => (
                                        <option selected={item?.id_cat == Catégorie ? 'selected' : ''} value={item?.id_cat}>{item?.cat_name}</option>
                                    ))
                                    }
                                </select>
                                <div className='err-cart-sum'>{Showerrors && errors.Catégorie !== '' ? errors.Catégorie : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Prix</label>
                                <input type="tel" className="form-control" onChange={(e) => setPrix(e.target.value.replace(/[^0-9]+/g, ''))} value={Prix} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Prix !== '' ? errors.Prix : ''}</div>

                            </div> */}
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Condition de retour</label>
                                <input type="text" className="form-control" onChange={(e) => setConditionderetour(e.target.value)} value={Conditionderetour} id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Conditionderetour !== '' ? errors.Conditionderetour : ''}</div>
                            </div> */}

                            {/* <div class="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label for="inputState" class="form-label">Par</label>
                                <select id="inputState" class="form-select">
                                    <option selected>Choose...</option>
                                    <option>...</option>
                                </select>
                            </div> */}
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Délais d'annulation</label>
                                <input type="text" className="form-control" onChange={(e) => setDélaisdannulation(e.target.value)} value={Délaisdannulation} id="inputEmail4" />
                            </div> */}

                            {/* 
                       
                            <div className="col-md-12">
                                <div className="form-floating">
                                    <textarea maxLength={6000} className="form-control" onChange={(e) => setDescription(e.target.value)} value={Description} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Description</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Description !== '' ? errors.Description : ''}</div>

                                </div>
                            </div>
                           */}
                            <div className="col-md-12">
                                {/* <div className="form-floating">
                                    <textarea maxLength={6000} className="form-control" onChange={(e) => setDescription(e.target.value)} value={Description} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Description</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Description !== '' ? errors.Description : ''}</div>

                                </div> */}
                                <div>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputEmail4" className="form-label">information (Ar)</label>
                                    <ReactQuill theme="snow" modules={module} value={Description} onChange={setDescription} />
                                    <div className='err-cart-sum'>{Showerrors && errors.Description !== '' ? errors.Description : ''}</div>

                                </div>
                            </div>

                            <div className="col-md-12">
                                {/* <div className="form-floating">
                                    <textarea maxLength={6000} className="form-control" onChange={(e) => setDescription(e.target.value)} value={Description} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Description</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Description !== '' ? errors.Description : ''}</div>

                                </div> */}
                                <div>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputEmail4" className="form-label">information (Fr)</label>
                                    <ReactQuill theme="snow" modules={module} value={DescriptionFR} onChange={setDescriptionFR} />
                                    <div className='err-cart-sum'>{Showerrors && errors.DescriptionFR !== '' ? errors.DescriptionFR : ''}</div>

                                </div>
                            </div>

                            {/* <div className="col-md-12">
                              
                                <div>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputEmail4" className="form-label">Déscription (Ar)</label>
                                    <ReactQuill theme="snow" modules={module} value={Fichetechnique} onChange={setFichetechnique} />
                                    <div className='err-cart-sum'>{Showerrors && errors.Fichetechnique !== '' ? errors.Fichetechnique : ''}</div>

                                </div>
                            </div> */}

                            {/* <div className="col-md-12">
                                
                                <div>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label htmlFor="inputEmail4" className="form-label">Déscription (Fr)</label>
                                    <ReactQuill theme="snow" modules={module} value={FichetechniqueFr} onChange={setFichetechniqueFr} />
                                    <div className='err-cart-sum'>{Showerrors && errors.FichetechniqueFr !== '' ? errors.FichetechniqueFr : ''}</div>

                                </div>
                            </div> */}


                            <div className="col-md-12 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValid(e.target.checked)} checked={Valid} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between flex-wrap">
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image1 && <div className='up-close' onClick={() => initpreview1 ? DeleteImage('1', preview1.split("/").pop()) : ClearInputFile(1)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput1.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image1 &&
                                            <div onClick={() => { hiddenFileInput1.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview1} />

                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image1 !== '' ? errors.Image1 : ''}</div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Imageall !== '' ? errors.Imageall : ''}</div>

                                    <input ref={hiddenFileInput1} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage1(e.target.files[0]) : setImage1()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image2 && <div className='up-close' onClick={() => initpreview2 ? DeleteImage('2', preview2.split("/").pop()) : ClearInputFile(2)}>X</div>}

                                        <svg onClick={() => { hiddenFileInput2.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image2 &&
                                            <div onClick={() => { hiddenFileInput2.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview2} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image2 !== '' ? errors.Image2 : ''}</div>

                                    <input ref={hiddenFileInput2} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage2(e.target.files[0]) : setImage2()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image3 && <div className='up-close' onClick={() => initpreview3 ? DeleteImage('3', preview3.split("/").pop()) : ClearInputFile(3)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput3.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image3 &&
                                            <div onClick={() => { hiddenFileInput3.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview3} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image3 !== '' ? errors.Image3 : ''}</div>

                                    <input ref={hiddenFileInput3} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage3(e.target.files[0]) : setImage3()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image4 && <div className='up-close' onClick={() => initpreview4 ? DeleteImage('4', preview4.split("/").pop()) : ClearInputFile(4)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput4.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image4 &&
                                            <div onClick={() => { hiddenFileInput4.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview4} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image4 !== '' ? errors.Image4 : ''}</div>

                                    <input ref={hiddenFileInput4} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage4(e.target.files[0]) : setImage4()} id="formFile" />
                                </div>
                                <div className="mb-3">

                                    {/* <div onClick={() => { hiddenFileInput.current.click() }}>hhhh</div> */}
                                    <div className='img-up'>
                                        {Image5 && <div className='up-close' onClick={() => initpreview5 ? DeleteImage('5', preview5.split("/").pop()) : ClearInputFile(5)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput5.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image5 &&
                                            <div onClick={() => { hiddenFileInput5.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview5} />
                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image5 !== '' ? errors.Image5 : ''}</div>

                                    <input ref={hiddenFileInput5} className="form-control d-none" accept="image/png, image/jpg ,image/jpeg ,image/webp" type="file" onChange={(e) => e.target.files.length > 0 ? setImage5(e.target.files[0]) : setImage5()} id="formFile" />
                                </div>

                            </div>

                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Modifier</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdateNews