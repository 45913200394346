import { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';
import { ToWords } from 'to-words';
import jsPDF from 'jspdf';



const CreateFacture = () => {



    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const {id_user} = useParams();


    const [FactureItems, setFactureItems] = useState([])
    const [UserInfo, setUserInfo] = useState('')
    const [DateFacture, setDateFacture] = useState('');



    useEffect(() => {
        // setLoadingFactureItems(true);

        const GetFactureItems = async () => {

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/facturation/facture_items/${id_user}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Auth?.token}`
                    },
                });
                console.log(response);
                setFactureItemsList(response['data']);
                setUserInfo(response['user_info']);
                setDateFacture(response['user_info']['facture_date'] || '')


                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoadingFactureItems(false);
        }

        GetFactureItems();

    }, []);





    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);




    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [CatName, setCatName] = useState('')

    const [Validé, setValidé] = useState(false)
    const [Image1, setImage1] = useState('')
    // const [Image2, setImage2] = useState('')



    const [preview1, setPreview1] = useState()
    // const [preview2, setPreview2] = useState()
    const [initpreview1, setinitPreview1] = useState(false)
    // const [initpreview2, setinitPreview2] = useState(false)


    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ CatName: '', Email: '', Adresse: '', Site: '', Facebook: '', Instagram: '', Heurededébut: '', Heuredefin: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        // if (CatName?.toString().trim().length > 300 || CatName?.toString().trim() == '') {
        //     seterrors((prev) => ({ ...prev, CatName: 'Veuillez écrire le nom de la Catégorie !! (Max 300 Caractères)' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, CatName: '' }))
        // }



        // if (Image1) {
        //     if (Image1.size > 2097152) {
        //         seterrors((prev) => ({ ...prev, Image1: "L'image que vous avez choisi ne doit pas dépasser 2 Mo" }))
        //     } else {
        //         seterrors((prev) => ({ ...prev, Image1: '' }))
        //     }

        // } else {
        //     seterrors((prev) => ({ ...prev, Image1: '' }))
        // }

        // if (Image2) {
        //     if (Image2.size > 4097152 ) {
        //         seterrors((prev) => ({ ...prev, Image2: 'La couverture que vous avez sélectionné ne doit pas dépasser 3 Mo' }))
        //     } else {
        //         seterrors((prev) => ({ ...prev, Image2: '' }))
        //     }

        // }else{
        //     seterrors((prev) => ({ ...prev, Image2: '' }))
        // }



    }

    // useEffect(() => {

    //     Validate();

    // }, [CatName, Image1])


    // useEffect(() => {
    //     if (!Image1) {
    //         setPreview1(undefined)
    //         return
    //     }

    //     const objectUrl = URL.FactureObjectURL(Image1)
    //     setPreview1(objectUrl)

    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [Image1])


    // useEffect(() => {
    //     if (!Image2) {
    //         setPreview2(undefined)
    //         return
    //     }

    //     const objectUrl = URL.createObjectURL(Image2)
    //     setPreview2(objectUrl)

    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [Image2])


    // useEffect(() => {

    //     if (Showerrors) {
    //         const matches = document.querySelectorAll(".err-cart-sum");

    //         for (let index = 0; index < matches.length; index++) {

    //             if (matches[index].textContent.trim() !== '') {

    //                 let position = matches[index].getBoundingClientRect();


    //                 window.scrollTo(position.left, position.top + window.scrollY - 120);
    //                 return
    //             }
    //             // if (matches[index].textContent.trim() !== '') {

    //             //     matches[index].scrollIntoView({behavior: "smooth"});
    //             //     return
    //             // }              
    //         }
    //     }


    // }, [Showerrors])



    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        // if (errors.CatName !== '' || errors.Image1 !== ''
        // ) {
        //     const matches = document.querySelectorAll(".err-cart-sum");

        //     for (let index = 0; index < matches.length; index++) {

        //         if (matches[index].textContent.trim() !== '') {

        //             let position = matches[index].getBoundingClientRect();


        //             window.scrollTo(position.left, position.top + window.scrollY - 60);
        //             return
        //         }
        //         // if (matches[index].textContent.trim() !== '') {

        //         //     matches[index].scrollIntoView({behavior: "smooth"});
        //         //     return
        //         // }              
        //     }


        //     // matches[0].scrollIntoView({behavior: "smooth"});
        //     console.log(matches[0].offsetTop);
        //     // matches[0].focus();

        //     console.log(errors);
        //     return;
        // }



        // const formData = new FormData();
        // formData.append("nom_categorie", CatName);
        // formData.append("active", Validé == true ? 1 : 0);
        // formData.append("facture_items", JSON.stringify((EquipmentCart)) || null);
        // formData.append("bg", Image2 || null);

        let Data = {
            "facture_items": JSON.stringify((FactureItemsList?.filter(verifyObject))),
            "date" : DateFacture,
            "ht" : parseFloat(GetTotalPrice())?.toFixed(2),
            "tva" : parseFloat(GetTotalPrice() * .19)?.toFixed(2),
            "ttc" : (GetTotalPrice() + GetTotalPrice() * .19)?.toFixed(2)
        }

        console.log(FactureItemsList);


        setLoading(true)


        axios.put(`${process.env.REACT_APP_LOCAL_URL}/facturation/edit_facture/${id_user}`, Data, {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)
                    if (response.data['errorList'].constructor == Array) {

                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'La facture a été mise à jour avec succès',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location.reload();
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }

    const [FactureItemsList, setFactureItemsList] = useState([{ 'name': "", 'price': "", 'quantity': "" }])


    const handleChange = (e, i) => {
        const { name, value } = e.target
        const onchangeVal = [...FactureItemsList]
        function VerifyInput() {

            if (name == 'price') {
                return value?.replace(/[^0-9\.]+/g, '')
            } else if (name == 'quantity') {
                return value?.replace(/[^0-9]+/g, '')
            } else {
                return value
            }

        }
        onchangeVal[i][name] = VerifyInput()
        setFactureItemsList(onchangeVal)
    }

    const handleDelete = (i) => {
        const deleteVal = [...FactureItemsList]
        deleteVal.splice(i, 1)
        setFactureItemsList(deleteVal)
    }

    const handleClick = () => {
        setFactureItemsList([...FactureItemsList, { 'name': "", 'price': "", 'quantity': "" }])
    }
    function TTC(Price) {

        const PriceFormat = parseFloat(Price)
        // Type "1" === "غير ماكثة في البيت"
        // if (TitleProject?.id_category != "7") {

        //     return Math.round((Price + (Price * 0.10 + 16000)) * 1.19) + 25000;

        // } else {
        // Type "2" === "ماكثة في البيت"
        return Math.round((PriceFormat + (PriceFormat * 0.10 + 9000)) * 1.19);
        // }
    }
    // console.log(parseFloat(TTC(parseFloat(FactureItemsList[0]?.price)) / 1.19));
    function verifyObject(obj) {
        return (
            // typeof obj.id === 'number' &&
            // typeof obj.facture_id === 'number' &&
            typeof obj.name === 'string' &&
            typeof obj.price === 'string' && !isNaN(parseFloat(obj.price)) &&
            (typeof obj.quantity === 'number' || (typeof obj.quantity === 'string' && !isNaN(parseInt(obj.quantity))))
        );
    }

    function GetTotalPrice() {

        
        // Filter the array based on verification criteria
        // const filteredData = data.filter(verifyObject);

        if (FactureItemsList?.length) {

            return FactureItemsList?.filter(verifyObject)?.reduce((n, { quantity, price }) => n + (quantity * parseFloat(TTC(price) / 1.19)), 0) || 0


        }

        return 0
    }
    const handleGeneratePdf = () => {







        const doc = new jsPDF({
            format: 'a4',
            unit: 'pt',
        });

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();

        // doc.viewerPreferences({"Direction": "R2L"}, true);
        // doc.setLanguage('ar');


        // Adding the fonts.
        // doc.setFont('Arial', 'normal');
        // doc.addFont("/fonts/ARIAL.ttf", "MyFont", "normal");
        // doc.addFileToVFS("/fonts/ARIAL.ttf", "font");
        // doc.setFont("font")
        doc.addFont("/fonts/hhh.ttf", "hhh", "normal");
        doc.addFont("/fonts/ARIALBD 1.ttf", "hhh2", "bold");


        doc.setFont("hhh"); // set font
        if (UserInfo?.type == 1 ) {
            doc.addImage("/img/facture-.png", "png", 0, 0, width, height);
        }else{
            doc.addImage("/img/facture-avoir.png", "png", 0, 0, width, height);
        }

        doc.autoTable({ html: "#my-table" });
        // const columns = [{ content: 'Text', colSpan: 3, styles: { halign: 'center' } }];

        const columns = ["Désignation du Produit", "Qté", "PU HT", "P HT"];
        const rows = [];



        if (FactureItemsList?.length) {

            for (let index = 0; index < FactureItemsList?.length; index++) {
                rows.push([FactureItemsList[index]?.name, FactureItemsList[index]?.quantity, parseFloat(TTC(FactureItemsList[index]?.price) / 1.19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 }), parseFloat((TTC(FactureItemsList[index]?.price) / 1.19) * FactureItemsList[index]?.quantity)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })])

            }
        }



        //   Product.map((item) => item.title), products.map((item) => item.price)

        doc.autoTable({
            startX: 130,
            startY: 280,
            theme: "grid",
            tableWidth: doc.internal.pageSize.getWidth() - 40,
            margin: { left: 20, right: 20 },
            styles: { overflow: 'linebreak', fontSize: 9, cellPadding: { top: 2, right: 8, bottom: 2, left: 8 }, },
            tableLineColor: [189, 195, 199]
            , tableLineWidth: 0.75,
            headStyles: { fillColor: [124, 95, 240], cellPadding: 5 },
            // padding: {top: 2, right: 8, bottom: 2, left: 8},
            footStyles: {
                lineWidth: 1, font: "hhh2",
                lineColor: [255, 255, 255],halign: 'right'
            },
            bodyStyles: { font: "hhh" },

            columnStyles: {
                0: {cellWidth: 'auto'},
                1: {cellWidth: 'auto'},
                2: {cellWidth: 110 , halign: 'right'},
                3: {cellWidth: 110 , halign: 'right'},

                // etc
              },


            head: [columns],
            body: rows,
            foot: [
                // [{content: `Total =`, colSpan: 3, styles: { fillColor: [239, 154, 154] }}],

                [{ content: ``, colSpan: 2, styles: { fillColor: [255, 255, 255], lineWidth: .5, lineColor: [189, 195, 199] } }, "Total DZD HT :", parseFloat(GetTotalPrice())?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })], [{ content: ``, colSpan: 2, styles: { fillColor: [255, 255, 255], lineWidth: .5, lineColor: [189, 195, 199] } }, "Total TVA (19%) :", parseFloat(GetTotalPrice() * .19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })], [{ content: ``, colSpan: 2, styles: { fillColor: [255, 255, 255], lineWidth: .5, lineColor: [189, 195, 199] } }, "Total DZD TTC :", (GetTotalPrice() + GetTotalPrice() * .19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })]],
            // footStyles: {
            //     fillColor: [217, 217, 214],
            //     textColor: [0, 0, 0],
            //     fontSize: 12,
            //   },
        });

        let finalY = doc.lastAutoTable.finalY;
        doc.setFontSize(8);

        const toWords = new ToWords({
            localeCode: 'fr-FR',

            converterOptions: {
                currency: true,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: false,
                currencyOptions: { // can be used to override defaults for the selected locale
                    name: 'Dinar Algérien',
                    plural: 'Dinars Algérien',
                    symbol: 'DZD',
                    fractionalUnit: {
                        name: 'Centimes',
                        plural: 'Centimes',
                        symbol: '',
                    },
                }
            }


        });

        let words = toWords.convert(GetTotalPrice() + GetTotalPrice() * .19);

        const lineshh7 = doc.splitTextToSize(`Arrêté(e) le(la) présent(e) FACTURE à la somme de \n${words} \nEn votre aimable règlement`, 210)

        doc.text(375, finalY + 15, lineshh7, { align: 'left' })

        // if (AlsalamList) {

        //     firstPage.drawText(`${alsalamListJson?.[AlsalamList]?.['Colonne1']}`, {
        //         x: 45,
        //         y: (height / 2) + 295,
        //         size: 11,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        //     firstPage.drawText(`${AlsalamList}`, {
        //         x: 410,
        //         y: (height / 2) + 275,
        //         size: 11,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })


        //     firstPage.drawText(`${reverseNumbers(alsalamListJson?.[AlsalamList]?.['Colonne2'])}`, {
        //         x: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 27 : 80,
        //         y: (height / 2) + 277,
        //         size: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 9 : 11,
        //         font: helveticaFont,
        //         // features: ['rtla'],
        //         // rtl: true
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }

        // if (AlsalamList) {

        //     doc.setFontSize(11);
        //     doc.text(`${alsalamListJson?.[AlsalamList]?.['Colonne1']}`, 173, 127, { align: 'right' });
        //     doc.text(`${AlsalamList}`, 505, 145, { align: 'right' });
        //     doc.setFontSize(10);
        //     const lineshh = doc.splitTextToSize(`${alsalamListJson?.[AlsalamList]?.['Colonne2']}`, 330)
        //     doc.text(lineshh, 308, 145, { align: 'right' });
        // }
        doc.setFontSize(8);
        if (UserInfo?.id) {
            doc.text(`DT-${UserInfo?.id || ''}`, 22, 726, { align: 'left' });
        }

        doc.setFontSize(11);
        doc.setFont('hhh2', 'bold')

        if (UserInfo?.nom || UserInfo?.prenom) {
            doc.text(`${UserInfo?.nom} ${UserInfo?.prenom}`, 22, 156, { align: 'left' });
        }

        
        doc.text(`${new Date(DateFacture).getFullYear()}-${UserInfo?.id_facture?.toString()?.padStart(4, '0') || ''}`, 246, 255, { align: 'left' });
        doc.text(`${new Date(DateFacture).toLocaleDateString('fr')}`, 356, 255, { align: 'left' });
        
        doc.text(`${(GetTotalPrice() + GetTotalPrice() * .19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 459, 255, { align: 'left' });
        



        // doc.setFontSize(15);
        // if (MaxAmount) {

        //     doc.text(`${parseFloat(MaxAmount).toLocaleString('fr')}`, 364, 369, { align: 'center' });
        // }

        // if (Duree) {
        //     doc.text(`${Duree}`, 233, 391, { align: 'left' });
        //     doc.text(`شهر`, 200, 391, { align: 'left' });
        // }







        //         var text2 = `
        //         - توقيع الوعد باالستئجار.
        // - إمضاء سند ألمر بإجمالي األقساط اإليجارية.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.

        //         `;

        //         var loremipsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id eros turpis. Vivamus tempor urna vitae sapien mollis molestie. Vestibulum in lectus non enim bibendum laoreet at at libero. Etiam malesuada erat sed sem blandit in varius orci porttitor. Sed at sapien urna. Fusce augue ipsum, molestie et adipiscing at, varius quis enim. Morbi sed magna est, vel vestibulum urna. Sed tempor ipsum vel mi pretium at elementum urna tempor. Nulla faucibus consectetur felis, elementum venenatis mi mollis gravida. Aliquam mi ante, accumsan eu tempus vitae, viverra quis justo.\n\nProin feugiat augue in augue rhoncus eu cursus tellus laoreet. Pellentesque eu sapien at diam porttitor venenatis nec vitae velit. Donec ultrices volutpat lectus eget vehicula. Nam eu erat mi, in pulvinar eros. Mauris viverra porta orci, et vehicula lectus sagittis id. Nullam at magna vitae nunc fringilla posuere. Duis volutpat malesuada ornare. Nulla in eros metus. Vivamus a posuere libero.'

        // This line works. Try generating PDF.
        // doc.setFontSize(12);
        // // doc.setR2L(true)
        // doc.text("- توقيع الوعد بالإستئجار", 522, 560, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: true, isInputRtl: true, });
        // doc.text("- إمضاء سند لأمر بإجمالي الأقساط الإيجارية", 522, 572, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: true, isInputRtl: true, });

        // if (Condition) {
        //     const lines = doc.splitTextToSize(Condition, 570)
        //     doc.text(lines, 522, 584, { align: 'right', isSymmetricSwapping: false, isInputVisual: false, isOutputVisual: true, isInputRtl: true, })
        //     // doc.text(lines, 517, 588, {align: 'right', isSymmetricSwapping: true, isInputVisual: false, isOutputVisual: true,isInputRtl : true ,})
        // }

        // doc.text(`صفحة 1`, 300, 820, { align: 'center' });



        // doc.addPage("a4", "p");
        // doc.addImage("/img/annexe11-2.png", "png", 0, 0, width, height);

        // // doc.text("Do you like that?", 20, 20);
        // doc.text(`صفحة 2`, 300, 820, { align: 'center' });


        doc.save(`Facture ${UserInfo?.nom} ${UserInfo?.prenom}.pdf`);
        // doc.html(reportTemplateRef.current, {
        // 	async callback(doc) {
        // 		await 
        // 	}, x: 10,
        //     y: 10,
        //     autoPaging: 'text',
        // });
    };

    const toWords = new ToWords({
        localeCode: 'fr-FR',

        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { // can be used to override defaults for the selected locale
                name: 'Dinar Algérien',
                plural: 'Dinars Algérien',
                symbol: 'DZD',
                fractionalUnit: {
                    name: 'Centimes',
                    plural: 'Centimes',
                    symbol: '',
                },
            }
        }


    });
    let words = toWords.convert(GetTotalPrice() + GetTotalPrice() * .19);
    console.log(FactureItemsList?.filter(verifyObject));

    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Facture<span>iamal</span></div>
                    <div className='page-content'>

                        <div className='container py-3'>
                            {err?.length > 0 && err?.map((item, i) => (

                                item.constructor == String ?
                                    <div className="alert-store" role="alert">
                                        - {item}
                                    </div>
                                    : ''

                            ))
                            }

                        </div>
                        <div className='d-flex justify-content-between align-items-center flex-wrap'>
                            <div >
                                <div className='facture-edit-title mb-0 pb-0'>  Facture N° : <span style={{ color: '#34b494' }}> {UserInfo?.id_facture?.toString()?.padStart(4, '0') || ''}</span></div>
                                <div className='facture-edit-user mb-0'>{UserInfo?.nom || ''} {UserInfo?.prenom || ''}</div>
                                <div className='facture-edit-user mb-4'>DT-{UserInfo?.id || ''}</div>

                            </div>
                            <div>
                                <img src='/img/logo.png' width={'180'} />


                            </div>
                        </div>

                        <div className="d-flex btn-doc text-center mb-5">
                            <div className="donwload-document-btn me-4" onClick={() => handleGeneratePdf()}>Téléchargez la facture</div>
                        </div>
                        {/* {err?.length > 0 && err?.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}

                        {/* <button onClick={handleClick}>Add</button> */}
                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-0 pb-0'>

                            <h3 className='mt-3 mb-0 pb-0' style={{ fontWeight: '900' }}>Produits</h3>
                            <h4 className='mb-0 pb-0' style={{ fontWeight: '700' }}>
                                Total TTC :  {`${(GetTotalPrice() + GetTotalPrice() * .19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            </h4>
                        </div>
                        <hr className='mb-4' />
                        <form className="row gy-4">

                        <div className="col-md-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress2" className="form-label">Date</label>
                                <input type="date" className="form-control" onChange={(e) => setDateFacture(e.target.value)} value={DateFacture} id="inputAddress2" placeholder="Apartment, studio, or floor" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.Date !== '' ? errors.Date : ''}</div> */}

                            </div>



                            {FactureItemsList?.length > 0 && FactureItemsList?.map((item, i) => (

                                <div className="col-md-12">
                                    <div className='d-flex justify-content-start align-items-end flex-wrap'>

                                        <div className='me-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24">
                                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                </g>
                                            </svg>

                                            <label htmlFor="inputEmail4" className="form-label mb-0 pb-0">Désignation du Produit</label>
                                            <input style={{ minWidth: '499px' }} maxLength={300} type="text" name='name' onChange={(e) => handleChange(e, i)} value={item?.name} className="form-control" id="inputEmail4" />
                                            {/* <div className='err-cart-sum'>{Showerrors && errors.Titreduproduit !== '' ? errors.Titreduproduit : ''}</div> */}
                                        </div>
                                        <div className='me-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24">
                                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                </g>
                                            </svg>
                                            <label htmlFor="inputEmail4" className="form-label mb-0 pb-0">Prix</label>
                                            <input type="tel" className="form-control" name='price' onChange={(e) => handleChange(e, i)} value={item?.price} id="inputEmail4" />
                                            {/* <div className='err-cart-sum'>{Showerrors && errors.Prix !== '' ? errors.Prix : ''}</div> */}
                                        </div>

                                        <div className='me-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-2' viewBox="0 0 24 24">
                                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                </g>
                                            </svg>
                                            <label htmlFor="inputEmail4" className="form-label mb-0 pb-0">Quantité</label>
                                            <input style={{ maxWidth: '90px' }} type="tel" className="form-control" name='quantity' onChange={(e) => handleChange(e, i)} value={item?.quantity} id="inputEmail4" />
                                            {/* <div className='err-cart-sum'>{Showerrors && errors.Position !== '' ? errors.Position : ''}</div> */}
                                        </div>
                                        <div className='delete-btn' style={{ margin: 'unset' }} onClick={() => handleDelete(i)}><svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                            <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                        </svg></div>
                                    </div>



                                </div>

                            ))}



                            {/* <div className="col-md-12 d-flex justify-content-around flex-wrap">
                                <div className="mb-3">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label for="inputState" class="form-label">Image</label>

                                    <div className='img-up mt-3'>
                                        {Image1 && <div className='up-close' onClick={() => setImage1(null)}>X</div>}
                                        <svg onClick={() => { hiddenFileInput1.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image1 &&
                                            <div onClick={() => { hiddenFileInput1.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview1} />

                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image1 !== '' ? errors.Image1 : ''}</div>
                                    <input ref={hiddenFileInput1}  accept="image/png, image/jpg ,image/jpeg ,image/webp" className="form-control d-none" type="file" onChange={(e) => e.target.files.length > 0 ?  setImage1(e.target.files[0]) : setImage1()} id="formFile" />
                                </div>

                                <div className="col-md-12 mt-4 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValidé(e.target.checked)} checked={Validé} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>
                               




                            </div> */}

                            <div className="col-12 mt-4">
                                <div onClick={handleClick} className="btn btn-secondary">+ Ajouter</div>
                            </div>

                            <hr className='mt-4 pt-0 mb-4' />


                        </form>

                        <div className='d-flex justify-content-between align-items-center flex-wrap'>



                            <div>
                                <p className='facture-ttc mb-0 pb-0'><b>Total DZD HT : </b>{parseFloat(GetTotalPrice())?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} DA</p>
                                <p className='facture-ttc mb-0 pb-0'><b>Total TVA (19%) : </b>{parseFloat(GetTotalPrice() * .19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} DA</p>
                                <p className='facture-ttc mb-0 pb-0'><b>Total DZD TTC :  </b>{(GetTotalPrice() + GetTotalPrice() * .19)?.toLocaleString("fr", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} DA</p>
                            </div>
                            <div style={{ maxWidth: '400px' }}>


                                {`Arrêté(e) le(la) présent(e) FACTURE à la somme de \n${words} \nEn votre aimable règlement`}
                            </div></div>
                        <div className="col-12 text-center mt-5">
                            <div onClick={(e) => handleSubmit(e)} className="store-next-btn m-auto">Modifier</div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateFacture