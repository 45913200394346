import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  RadialLinearScale,
  ArcElement
} from 'chart.js';
import { Line, Bar,PolarArea } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
  RadialLinearScale
);


let datetoday = new Date();
let daytodayFormat = datetoday.toISOString().split("T")[0];

let date1 = new Date();
date1.setDate(date1.getDate() - 1);
let day1Format = date1.toISOString().split("T")[0];

let date2 = new Date();
date2.setDate(date2.getDate() - 2);
let day2Format = date2.toISOString().split("T")[0];


let date3 = new Date();
date3.setDate(date3.getDate() - 3);
let day3Format = date3.toISOString().split("T")[0];


let date4 = new Date();
date4.setDate(date4.getDate() - 4);
let day4Format = date4.toISOString().split("T")[0];



let date5 = new Date();
date5.setDate(date5.getDate() - 5);
let day5Format = date5.toISOString().split("T")[0];



let date6 = new Date();
date6.setDate(date6.getDate() - 6);
let day6Format = date6.toISOString().split("T")[0];


function subtractMonth(date, months) {
  let d = date?.getDate();
  date?.setMonth(date?.getMonth() - months);
  if (date?.getDate() != d) {
    date?.setDate(0);
  }
  return date?.toISOString()?.split("T")?.[0]?.slice(0, -3);
}


let monthtoday = new Date();
let monthtodayFormat = monthtoday.toISOString().split("T")[0].slice(0, -3);

let month1 = new Date();
let month1Format = subtractMonth(month1, 1) ;

let month2 = new Date();
let month2Format = subtractMonth(month2, 2);

let month3 = new Date();
let month3Format = subtractMonth(month3, 3);

let month4 = new Date();
let month4Format = subtractMonth(month4, 4);

let month5 = new Date();
let month5Format = subtractMonth(month5, 5);

let month6 = new Date();
let month6Format = subtractMonth(month6, 6);

let month7 = new Date();
let month7Format = subtractMonth(month7, 7);

let month8 = new Date();
let month8Format = subtractMonth(month8, 8);

let month9 = new Date();
let month9Format = subtractMonth(month9, 9);

let month10 = new Date();
let month10Format =  subtractMonth(month10, 10);

let month11 = new Date();
let month11Format =  subtractMonth(month11, 11);



const Dashboard = () => {

  const { Auth, setAuth } = useContext(AuthContext);
  let navigate = useNavigate();


  const [TotalClients, setTotalClients] = useState('')
  const [TotlaViewsPerDay, setTotlaViewsPerDay] = useState('')
  const [TotlaViewsPerMonth, setTotlaViewsPerMonth] = useState('')
  const [Mostvisitedhours, setMostvisitedhours] = useState('')
  const [MostvisitedDays, setMostvisitedDays] = useState('')



  const DaysFr = {
    "Monday": "Lundi",
    "Tuesday": "Mardi",
    "Wednesday": "Mercredi",
    "Thursday": "Jeudi",
    "Friday": "Vendredi",
    "Saturday": "Samedi",
    "Sunday": "Dimanche"
}


  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      title: {
        display: true,
        text: '# Visiteurs Par Jour',
      },
    },
  };

  const optionstwo = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      title: {
        display: true,
        text: 'Visiteurs Par Mois',
      },
    },
  };

  const optionsthree = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      title: {
        display: true,
        text: 'Horaires les plus visités',
      },
    },
  };

  const optionsfour = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      title: {
        display: true,
        text: 'Jours les plus visités',
      },
    },
  };

  const labels = [day6Format, day5Format, day4Format, day3Format, day2Format, day1Format, daytodayFormat];

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Visiteurs',
        data: [TotlaViewsPerDay?._6day, TotlaViewsPerDay?._5day, TotlaViewsPerDay?._4day, TotlaViewsPerDay?._3day, TotlaViewsPerDay?._2day, TotlaViewsPerDay?._1day, TotlaViewsPerDay?.today],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };



  const labels2 = [month11Format, month10Format, month9Format, month8Format, month7Format, month6Format, month5Format, month4Format, month3Format, month2Format, month1Format, monthtodayFormat];

  // const data2 = {
  //   labels2,
  //   datasets: [
  //     {
  //       fill: true,
  //       label: 'Visiteurs Par Mois',
  //       data: [TotlaViewsPerMonth?.Months_11,TotlaViewsPerMonth?.Months_10,TotlaViewsPerMonth?.Months_9,TotlaViewsPerMonth?.Months_8,TotlaViewsPerMonth?.Months_7, TotlaViewsPerMonth?.Months_6, TotlaViewsPerMonth?.Months_5, TotlaViewsPerMonth?.Months_4, TotlaViewsPerMonth?.Months_3, TotlaViewsPerMonth?.Months_2, TotlaViewsPerMonth?.Months_1,TotlaViewsPerMonth?.Months],
  //       borderColor: '#f783ee',
  //       backgroundColor: '#ff6db678',
  //     },
  //   ],
  // };

  const data2 = {
    labels: labels2,
    datasets: [{
      label: 'Visiteurs',
      data: [TotlaViewsPerMonth?.Months_11, TotlaViewsPerMonth?.Months_10, TotlaViewsPerMonth?.Months_9, TotlaViewsPerMonth?.Months_8, TotlaViewsPerMonth?.Months_7, TotlaViewsPerMonth?.Months_6, TotlaViewsPerMonth?.Months_5, TotlaViewsPerMonth?.Months_4, TotlaViewsPerMonth?.Months_3, TotlaViewsPerMonth?.Months_2, TotlaViewsPerMonth?.Months_1, TotlaViewsPerMonth?.Months],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]
  };


  const labels3 = [Mostvisitedhours?.[0]?.hour?.toString()?.padStart(2, "0") + ":00", Mostvisitedhours?.[1]?.hour?.toString()?.padStart(2, "0") + ":00", Mostvisitedhours?.[2]?.hour?.toString()?.padStart(2, "0") + ":00"];
  const data3 = {
    labels : labels3,
    datasets: [
      {
        type: 'line',
        fill: true,
        label: 'Visiteurs',
        data: [Mostvisitedhours?.[0]?.visitors, Mostvisitedhours?.[1]?.visitors, Mostvisitedhours?.[2]?.visitors],
        borderColor: '#ff6384',
        backgroundColor: "rgb(255 99 132 / 18%)",
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15
      },
    ],
  };


  const labels4 = [DaysFr?.[MostvisitedDays?.[0]?.day] , DaysFr?.[MostvisitedDays?.[1]?.day] , DaysFr?.[MostvisitedDays?.[2]?.day] ];
  const data4 = {
    labels : labels4,
    datasets: [
      {
        type: 'polarArea',
        fill: true,
        label: 'Visiteurs',
        data: [MostvisitedDays?.[0]?.visitors, MostvisitedDays?.[1]?.visitors, MostvisitedDays?.[2]?.visitors],
        // borderColor: '#ff6384',
        backgroundColor: ["#7288dbb0","#1b6b7694","#5a559fa6"],
        // pointStyle: 'circle',
        // pointRadius: 10,
        // pointHoverRadius: 15
      },
    ],
  };

 


  // useEffect(() => {


  //   if (Auth) {
  //     console.log('hi!');

  //   }else{
  //     console.log('no!'); 
  //     return navigate("/");
  //   }


  // }, [Auth])

  const [MenuShow, setMenuShow] = useState(true)

  const [windowSize, setWindowSize] = useState(getWindowSize());


  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  console.log(windowSize);

  // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

  const ValidateToken = async () => {
    // setLoading(true)
    try {
      const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/categorie/get_total_clients`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Auth?.token}`
        },
      });

      console.log(response);


      // console.log(response['errorCode']);
      if (response['error'] == false) {

        setTotalClients(response['data'])
        // return navigate('/')
      }



      // setLoading(false)


    } catch (error) {
      // console.error(error.message);
      // setLoading(false)

    }
  }

  const GetViewsPerDay = async () => {
    // setLoading(true)
    try {
      const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL_Documents}/views/viewsperday`, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response);


      // console.log(response['errorCode']);
      if (response['error'] == false) {

        setTotlaViewsPerDay(response['data'])
        // return navigate('/')
      }



      // setLoading(false)


    } catch (error) {
      // console.error(error.message);
      // setLoading(false)

    }
  }


  const GetViewsPerMonth = async () => {
    // setLoading(true)
    try {
      const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL_Documents}/views/viewspermonth`, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response);


      // console.log(response['errorCode']);
      if (response['error'] == false) {

        setTotlaViewsPerMonth(response['data'])
        // return navigate('/')
      }



      // setLoading(false)


    } catch (error) {
      // console.error(error.message);
      // setLoading(false)

    }
  }


  const GetMostvisitedhours = async () => {
    // setLoading(true)
    try {
      const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL_Documents}/views/mostvisitedhours`, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response);


      // console.log(response['errorCode']);
      if (response['error'] == false) {

        setMostvisitedhours(response['data'])
        // return navigate('/')
      }



      // setLoading(false)


    } catch (error) {
      // console.error(error.message);
      // setLoading(false)

    }
  }

  const GetMostvisitedDays = async () => {
    // setLoading(true)
    try {
      const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL_Documents}/views/mostvisiteddays`, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response);


      // console.log(response['errorCode']);
      if (response['error'] == false) {

        setMostvisitedDays(response['data'])
        // return navigate('/')
      }



      // setLoading(false)


    } catch (error) {
      // console.error(error.message);
      // setLoading(false)

    }
  }


  useEffect(() => {
    // setLoading(true)

    ValidateToken();
    GetViewsPerDay();
    GetViewsPerMonth();
    GetMostvisitedhours();
    GetMostvisitedDays();

  }, [])

  return (
    <div className='dashboard'>


      <div className=''>
        <div className=''>

          <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

        </div>
        <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
          <div className='menu-mobile-section'>
            <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
              <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
              </g>
            </svg>
          </div>
          <div className='page-title'>Mon Compte <span>Mes Information</span></div>
          <div className='client-information mt-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-lg-4 gy-5 gy-lg-0'>
                  <div className='box-info bg-g-first'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24.631" height="24.631" viewBox="0 0 24.631 24.631">
                      <path id="Tracé_6" data-name="Tracé 6" d="M23.028,27.063H3.6A2.6,2.6,0,0,1,1,24.46V11.6A2.6,2.6,0,0,1,3.6,9H23.028a2.6,2.6,0,0,1,2.6,2.6V24.46A2.6,2.6,0,0,1,23.028,27.063ZM3.6,10.642a.961.961,0,0,0-.961.961V24.46a.961.961,0,0,0,.961.961H23.028a.961.961,0,0,0,.961-.961V11.6a.961.961,0,0,0-.961-.961Z" transform="translate(-1 -2.432)" fill="#fff" />
                      <rect id="Rectangle_4" data-name="Rectangle 4" width="16" height="2" rx="1" transform="translate(4 3)" fill="#fff" />
                      <rect id="Rectangle_5" data-name="Rectangle 5" width="12" height="2" rx="1" transform="translate(6)" fill="#fff" />
                      <path id="Tracé_7" data-name="Tracé 7" d="M16.039,15.708H14.4a2.775,2.775,0,0,1-2.767-2.767V9h7.184v3.933a2.775,2.775,0,0,1-2.775,2.775Zm-2.767-5.066v2.291A1.133,1.133,0,0,0,14.4,14.066h1.642a1.133,1.133,0,0,0,1.133-1.125v-2.3Z" transform="translate(-2.902 -2.432)" fill="#fff" />
                    </svg>

                    <p>Nombre de clients</p>
                    <p>{TotalClients && TotalClients?.total ? TotalClients?.total : ''}</p>
                  </div>
                </div>
                <div className='col-12 col-lg-4 gy-5 gy-lg-0'>
                  <div className='box-info bg-g-second'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#fff" />
                      </g>
                    </svg>

                    <p>Nombre d'abonnés</p>
                    <p>{TotalClients && TotalClients?.subscribe ? TotalClients?.subscribe : ''}</p>
                  </div>
                </div>
                <div className='col-12 col-lg-4 gy-5 gy-lg-0'>
                  <div className='box-info bg-g-third'>
                    <svg id="dashboard-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="30.776" height="25.646" viewBox="0 0 30.776 25.646">
                      <g id="Groupe_431" data-name="Groupe 431">
                        <g id="Groupe_430" data-name="Groupe 430">
                          <path id="Tracé_9269" data-name="Tracé 9269" d="M15.388,2.334h0A15.339,15.339,0,0,0,4.51,6.838l0,0,0,0A15.339,15.339,0,0,0,0,17.708s0,.009,0,.014,0,.009,0,.013a15.326,15.326,0,0,0,3.517,9.78,1.282,1.282,0,0,0,.989.466H26.269a1.282,1.282,0,0,0,.989-.466A15.387,15.387,0,0,0,15.388,2.334ZM25.647,25.415H5.128A12.742,12.742,0,0,1,2.629,19H6.412a1.282,1.282,0,1,0,0-2.565H2.629A12.763,12.763,0,0,1,5.459,9.606l.861.861A1.282,1.282,0,0,0,8.134,8.654l-.861-.861a12.763,12.763,0,0,1,6.833-2.831V8.745a1.282,1.282,0,0,0,2.565,0V4.962A12.763,12.763,0,0,1,23.5,7.793l-.861.861a1.282,1.282,0,1,0,1.813,1.813l.861-.861a12.763,12.763,0,0,1,2.831,6.833H24.364a1.282,1.282,0,0,0,0,2.565h3.784A12.743,12.743,0,0,1,25.647,25.415Z" transform="translate(0 -2.334)" fill="#fff" />
                          <path id="Tracé_9270" data-name="Tracé 9270" d="M18.153,12.067,10.9,15.695l3.626,3.626Z" transform="translate(1.081 -1.369)" fill="#fff" />
                          <path id="Tracé_9271" data-name="Tracé 9271" d="M18.309,19.833H10.615a1.282,1.282,0,1,0,0,2.565h7.694a1.282,1.282,0,0,0,0-2.565Z" transform="translate(0.925 -0.599)" fill="#fff" />
                        </g>
                      </g>
                    </svg>


                    <p >Payé les droits d'étude du dossier</p>
                    <p>{TotalClients && TotalClients?.subscribe_documents ? TotalClients?.subscribe_documents : ''}</p>
                  </div>
                </div>

                <div className='col-12 col-lg-4 gy-5 gy-lg-0 mt-4'>
                  <div className='box-info' style={{background : 'linear-gradient(45deg, #9490c0, #6d8cff)'}}>
                  <svg id="dashboard-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="30.776" height="25.646" viewBox="0 0 30.776 25.646">
                    <g id="Groupe_431" data-name="Groupe 431">
                      <g id="Groupe_430" data-name="Groupe 430">
                        <path id="Tracé_9269" data-name="Tracé 9269" d="M15.388,2.334h0A15.339,15.339,0,0,0,4.51,6.838l0,0,0,0A15.339,15.339,0,0,0,0,17.708s0,.009,0,.014,0,.009,0,.013a15.326,15.326,0,0,0,3.517,9.78,1.282,1.282,0,0,0,.989.466H26.269a1.282,1.282,0,0,0,.989-.466A15.387,15.387,0,0,0,15.388,2.334ZM25.647,25.415H5.128A12.742,12.742,0,0,1,2.629,19H6.412a1.282,1.282,0,1,0,0-2.565H2.629A12.763,12.763,0,0,1,5.459,9.606l.861.861A1.282,1.282,0,0,0,8.134,8.654l-.861-.861a12.763,12.763,0,0,1,6.833-2.831V8.745a1.282,1.282,0,0,0,2.565,0V4.962A12.763,12.763,0,0,1,23.5,7.793l-.861.861a1.282,1.282,0,1,0,1.813,1.813l.861-.861a12.763,12.763,0,0,1,2.831,6.833H24.364a1.282,1.282,0,0,0,0,2.565h3.784A12.743,12.743,0,0,1,25.647,25.415Z" transform="translate(0 -2.334)" fill="#fff"/>
                        <path id="Tracé_9270" data-name="Tracé 9270" d="M18.153,12.067,10.9,15.695l3.626,3.626Z" transform="translate(1.081 -1.369)" fill="#fff"/>
                        <path id="Tracé_9271" data-name="Tracé 9271" d="M18.309,19.833H10.615a1.282,1.282,0,1,0,0,2.565h7.694a1.282,1.282,0,0,0,0-2.565Z" transform="translate(0.925 -0.599)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>


                     <p >Nombre de visiteurs (Total)</p>
                     <p>{TotalClients && TotalClients?.total_visitors   ?  TotalClients?.total_visitors  : 0}</p>
                  </div>
              </div>

              <div className='col-12 col-lg-4 gy-5 gy-lg-0 mt-4'>
                  <div className='box-info' style={{background : 'linear-gradient(161deg, #abafae, #8d8d8d)'}}>
                  <svg id="dashboard-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="30.776" height="25.646" viewBox="0 0 30.776 25.646">
                    <g id="Groupe_431" data-name="Groupe 431">
                      <g id="Groupe_430" data-name="Groupe 430">
                        <path id="Tracé_9269" data-name="Tracé 9269" d="M15.388,2.334h0A15.339,15.339,0,0,0,4.51,6.838l0,0,0,0A15.339,15.339,0,0,0,0,17.708s0,.009,0,.014,0,.009,0,.013a15.326,15.326,0,0,0,3.517,9.78,1.282,1.282,0,0,0,.989.466H26.269a1.282,1.282,0,0,0,.989-.466A15.387,15.387,0,0,0,15.388,2.334ZM25.647,25.415H5.128A12.742,12.742,0,0,1,2.629,19H6.412a1.282,1.282,0,1,0,0-2.565H2.629A12.763,12.763,0,0,1,5.459,9.606l.861.861A1.282,1.282,0,0,0,8.134,8.654l-.861-.861a12.763,12.763,0,0,1,6.833-2.831V8.745a1.282,1.282,0,0,0,2.565,0V4.962A12.763,12.763,0,0,1,23.5,7.793l-.861.861a1.282,1.282,0,1,0,1.813,1.813l.861-.861a12.763,12.763,0,0,1,2.831,6.833H24.364a1.282,1.282,0,0,0,0,2.565h3.784A12.743,12.743,0,0,1,25.647,25.415Z" transform="translate(0 -2.334)" fill="#fff"/>
                        <path id="Tracé_9270" data-name="Tracé 9270" d="M18.153,12.067,10.9,15.695l3.626,3.626Z" transform="translate(1.081 -1.369)" fill="#fff"/>
                        <path id="Tracé_9271" data-name="Tracé 9271" d="M18.309,19.833H10.615a1.282,1.282,0,1,0,0,2.565h7.694a1.282,1.282,0,0,0,0-2.565Z" transform="translate(0.925 -0.599)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>


                     <p >Nombre de demandes</p>
                     <p>{TotalClients && TotalClients?.total_demandes   ?  TotalClients?.total_demandes  : 0}</p>
                  </div>
              </div>

              <div className='col-12 col-lg-4 gy-5 gy-lg-0 mt-4'>
                  <div className='box-info'  style={{background : 'linear-gradient(45deg, #3a87d9, #2fb382)'}}>
                  <svg className='mb-1' id="dashboard-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="30.776" height="25.646" viewBox="0 0 30.776 25.646">
                    <g id="Groupe_431" data-name="Groupe 431">
                      <g id="Groupe_430" data-name="Groupe 430">
                        <path id="Tracé_9269" data-name="Tracé 9269" d="M15.388,2.334h0A15.339,15.339,0,0,0,4.51,6.838l0,0,0,0A15.339,15.339,0,0,0,0,17.708s0,.009,0,.014,0,.009,0,.013a15.326,15.326,0,0,0,3.517,9.78,1.282,1.282,0,0,0,.989.466H26.269a1.282,1.282,0,0,0,.989-.466A15.387,15.387,0,0,0,15.388,2.334ZM25.647,25.415H5.128A12.742,12.742,0,0,1,2.629,19H6.412a1.282,1.282,0,1,0,0-2.565H2.629A12.763,12.763,0,0,1,5.459,9.606l.861.861A1.282,1.282,0,0,0,8.134,8.654l-.861-.861a12.763,12.763,0,0,1,6.833-2.831V8.745a1.282,1.282,0,0,0,2.565,0V4.962A12.763,12.763,0,0,1,23.5,7.793l-.861.861a1.282,1.282,0,1,0,1.813,1.813l.861-.861a12.763,12.763,0,0,1,2.831,6.833H24.364a1.282,1.282,0,0,0,0,2.565h3.784A12.743,12.743,0,0,1,25.647,25.415Z" transform="translate(0 -2.334)" fill="#fff"/>
                        <path id="Tracé_9270" data-name="Tracé 9270" d="M18.153,12.067,10.9,15.695l3.626,3.626Z" transform="translate(1.081 -1.369)" fill="#fff"/>
                        <path id="Tracé_9271" data-name="Tracé 9271" d="M18.309,19.833H10.615a1.282,1.282,0,1,0,0,2.565h7.694a1.282,1.282,0,0,0,0-2.565Z" transform="translate(0.925 -0.599)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>


                     <p className='mb-1' style={{fontSize :"15px"}} >Demandes au niveau de la banque</p>
                     <p className='mb-0'>{TotalClients && TotalClients?.vu_bank  ?  TotalClients?.vu_bank : 0}</p>
                     <p className='mb-0' style={{lineHeight : "1"}}><span className='dashboard-span me-1'><b> En attente : {TotalClients && TotalClients?.waiting_bank   ?  TotalClients?.waiting_bank  : 0}</b></span> <span className='dashboard-span me-1'> <b>Validé : {TotalClients && TotalClients?.valid_bank   ?  TotalClients?.valid_bank  : 0}</b> </span> <span className='dashboard-span' ><b>Rejeté :{TotalClients && TotalClients?.rejected_bank   ?  TotalClients?.rejected_bank  : 0} </b></span></p>
                  </div>
              </div>

              

             

              
                
              </div>
            </div>
          </div>
          <div className='page-content'>



            <div
              className="titel-chart"
              style={{ background: "linear-gradient(45deg, #feb6b0, #fe7199)" }}
            >
              <i className="fas fa-desktop" />
              <p className="mb-0 ms-3">Visiteurs Par Jour</p>
            </div>
            <div className="data-state">
              <div className="day">
                <div className="day-d">
                  <span className="color-r" />
                  Aujourd'hui : {TotlaViewsPerDay?.today}
                </div>
                <div className="day-d">
                  <span className="color-r" />
                  Hier : {TotlaViewsPerDay?._1day}
                </div>
                <div className="day-d">
                  <span className="color-r" />
                  Avant-hier : {TotlaViewsPerDay?._2day}
                </div>
              </div>
            </div>
            <div className='m-auto text-center chart-iamal' style={{ width: '100%', height: '400px' }}>
              <Line options={options} data={data} className="m-auto" style={{ width: '100%', height: '400px' }} />
            </div>


            <div className="titel-chart mt-5">
              <i className="far fa-chart-bar" />
              <p className="mb-0 ms-3">Visiteurs Par Mois</p>
            </div>

            <div className="data-state">
              <div className="month">
                {/*?php 
$mois = array("","Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"); 
// echo $mois[date('n', strtotime('07-'.$months))];
?*/}
                <div className="day-d">
                  <span className="color-b" />
                  {monthtodayFormat + " (" + TotlaViewsPerMonth?.Months + " visiteurs )" /*?php echo $mois[date('n', strtotime('03-'.$months))] .' : '. $month_data; ?*/}
                </div>
                <div className="day-d">
                  <span className="color-b" />
                  {month1Format + " (" + TotlaViewsPerMonth?.Months_1 + " visiteurs )" /*?php echo $mois[date('n', strtotime('03-'.$months_1))] .' : '. $month_data1; ?*/}
                </div>
                <div className="day-d">
                  <span className="color-b" />
                  {month2Format + " (" + TotlaViewsPerMonth?.Months_2 + " visiteurs )" /*?php echo $mois[date('n', strtotime('03-'.$months_2))] .' : '. $month_data2; ?*/}
                </div>
              </div>
            </div>

            <div className='m-auto text-center chart-iamal' style={{ width: '100%', height: '400px' }}>
              <Bar options={optionstwo} data={data2} className="m-auto" style={{ height: '400px' }} />
            </div>


            <div className="titel-chart mt-5 bg-g-second">
              <i className="far fa-chart-bar" />
              <p className="mb-0 ms-3">Horaires les plus visités</p>
            </div>


            <div className="data-state">
              <div className="month">
                {/*?php 
$mois = array("","Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"); 
// echo $mois[date('n', strtotime('07-'.$months))];
?*/}
                <div className="day-d">
                  <span className="color-b" />
                  {Mostvisitedhours?.[0]?.hour?.toString()?.padStart(2, "0") + ":00 (" + Mostvisitedhours?.[0]?.visitors + " visiteurs )" /*?php echo $mois[date('n', strtotime('03-'.$months))] .' : '. $month_data; ?*/}
                </div>
                <div className="day-d">
                  <span className="color-b" />
                  {Mostvisitedhours?.[1]?.hour?.toString()?.padStart(2, "0") + ":00 (" + Mostvisitedhours?.[1]?.visitors + " visiteurs )" /*?php echo $mois[date('n', strtotime('03-'.$months_1))] .' : '. $month_data1; ?*/}
                </div>
                <div className="day-d">
                  <span className="color-b" />
                  {Mostvisitedhours?.[2]?.hour?.toString()?.padStart(2, "0") + ":00 (" + Mostvisitedhours?.[2]?.visitors + " visiteurs )" /*?php echo $mois[date('n', strtotime('03-'.$months_2))] .' : '. $month_data2; ?*/}
                </div>
              </div>
            </div>

            <div className='m-auto text-center chart-iamal' style={{ width: '100%', height: '400px' }}>
              <Bar options={optionsthree} data={data3} className="m-auto" style={{ height: '400px' }} />
            </div>


            <div className="titel-chart mt-5 "  style={{ background: "linear-gradient(45deg, #feb6b0, #fe7199)" }}>
              <i className="far fa-chart-bar" />
              <p className="mb-0 ms-3">Jours les plus visités</p>
            </div>


            <div className="data-state">
              <div className="month">
             
                <div className="day-d">
                  <span className="color-b" />
                  {DaysFr?.[MostvisitedDays?.[0]?.day] + " (" + MostvisitedDays?.[0]?.visitors + " visiteurs )" }
                </div>
                <div className="day-d">
                  <span className="color-b" />
                  {DaysFr?.[MostvisitedDays?.[1]?.day] + " (" + MostvisitedDays?.[1]?.visitors + " visiteurs )"}
                </div>
                <div className="day-d">
                  <span className="color-b" />
                  {DaysFr?.[MostvisitedDays?.[2]?.day] + " (" + MostvisitedDays?.[2]?.visitors + " visiteurs )"}
                </div>
              </div>
            </div>

            <div className='m-auto text-center chart-iamal' style={{ width: '100%', height: '600px' }}>
              <PolarArea options={optionsfour} data={data4} className="m-auto" style={{ height: '600px' }} />
            </div>


          </div>
        </div>
      </div>

    </div>
  )
}

export default Dashboard