import { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import AuthContext from '../../Context/auth';
// import LangContext from '../../Context/lang';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from '../Global/Loader';




const Login = () => {

  const [Email, setEmail] = useState('')
  const [passLogin, setpassLogin] = useState('')
  const [loading, setloading] = useState(false)

  const [errors, seterrors] = useState({ Email: '' })
  const [err, seterr] = useState([])

  const [Showerrors, setShowerrors] = useState(false)

  const { Auth, setAuth, setPermission} = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (Auth?.token) {
      ValidateToken()
    }
    console.log(Auth.token);


  }, [])


  const ValidateToken = async () => {
    console.log('run');

    setloading(true)
    try {
        const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/checktoken`,{},  {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Auth?.token}`
            },
        });

        console.log(response);
        // if (response['Valid'] == false) {
        //     return navigate('/')
        // }

        if (response['Valid'] == true) {
            // return navigate('/')

            // if (response['Role'] == 'adminDZ') {
          setPermission(response?.['permission'])


              window.location ='/dashboardadmin'
              
            // }else{
            //   window.location ='/dashboard'
            // }
            
        }else{

            setloading(false)
            

        }





    } catch (error) {
      setloading(false)

        // return navigate('/')
    }
}

  // const { Lang, Global, Account,confirmation } = useContext(LangContext);

  const Lang = 'fr';

  const [Account] = useState({

    'Retour': { 'fr': 'Retour au Diar Dzair', 'ar': 'العودة إلى موقع ديار دزاير' },
    'Créeruncompte': { 'fr': 'Créer un compte', 'ar': 'إفتح حساب' },
    'Seconnecter': { 'fr': 'Se connecter', 'ar': 'تسجيل الدخول' },
    'Nomdutilisateur': { 'fr': "Nom d'utilisateur", 'ar': 'إسم المستخدم' },
    'MotdePasse': { 'fr': 'Mot de Passe', 'ar': 'كلمة السر' },
    'e-mail': { 'fr': 'Adresse e-mail', 'ar': 'البريد الإلكتروني' },
    'Répéterlemotdepasse': { 'fr': 'Répéter le mot de passe', 'ar': 'أعد كلمة السر' },
    'Motdepasseoublié': { 'fr': 'Mot de passe oublié ?', 'ar': 'نسيت كلمة السر ؟' },

  })

  const [confirmation] = useState({
    'titre': { 'fr': 'Confirmation', 'ar': 'التأكيد' },
    'texte': { 'fr': 'Veuillez assurer la certitude des informatins saisies, en cas de contradiction entre les informations saisis et le dossier fourni, votre dossier sera rejeté et ou un dossier complémentaire vous sera demandé et les frais d’un deuxième ramassage vous seront imposés.', 'ar': 'يرجى التأكد من صحة المعلومات التي تم إدخالها ، في حالة التناقض بين المعلومات التي تم إدخالها والملف المقدم ، سيتم رفض ملفك أو سيتم طلب ملف إضافي وستُفرض عليك تكاليف جمع الملف الثاني' },
    'confirmation': { 'fr': 'Voulez vous confirmer ?', 'ar': ' تريد التاكيد؟' },
    'captcha': { 'fr': 'Veuillez vérifier le captcha pour passer', 'ar': 'يرجى فحص "CAPTCHA" للمرور' },
    'condition': { 'fr': "Conditions d'utilisation", 'ar': 'شروط الإستخدام' },
    'conditioncheck': { 'fr': "J'accepte toutes les conditions d'utilisation", 'ar': 'أنا أقبل جميع شروط الاستخدام' },
    'conditionerr': { 'fr': "Veuillez lire et accepter les conditions d'utilisation", 'ar': 'يرجى قراءة شروط الإستخدام والموافقة عليها' },


  })



  const recaptchaRef = useRef('');
  const [CaptchaChecked, setCaptchaChecked] = useState(false)
  const [captchaerror, setcaptchaerror] = useState('')
  function onChange(value) {
    // console.log("Captcha value:", value);
    setCaptchaChecked(true)
  }

  useEffect(() => {

    // setcaptchaerror(CaptchaChecked ? '' : confirmation['captcha'][Lang])
    console.log(confirmation['captcha'][Lang]);


  }, [CaptchaChecked, Lang])

  const errText = {
    "Email": { 'fr': "Veuillez vérifier votre Email Min(2)Mots", "ar": "يرجى التأكد من إسم المستخدم الحد الأدنى (2) كلمات الحد الأقصى (20) كلمة" },
    "password": { "fr": "Veuillez vérifier le mot de passe Min(6)Mots", "ar": " يرجى التأكد من كلمة المرور الحد الأدنى (2) كلمات الحد الأقصى (20) كلمة" }
  }

  const errTextapi = {
    "Email": { 'fr': "l'utilisateur n'existe pas", "ar": "هذا المستخدم غير موجود" },
    "password": { "fr": 'Le mot de passe est incorrect', "ar": 'كلمة السر غير صحيحة' }
  }

  // function errTextapifun(arg) {

  //   return errTextapi[arg][Lang]

  // }


  function isValidEmail(string) {
    // The regular expression used by [type="email"]
    var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
    // Test the string against the regular expression
    return regex.test(string)
  }


  function Validate() {



    if (Email?.toString().trim().length > 200 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
      seterrors((prev) => ({ ...prev, Email: 'Veuillez saisir un e-mail valide !!' }))
    } else {
      seterrors((prev) => ({ ...prev, Email: '' }))
    }




    if (passLogin?.toString().length > 300 || passLogin?.toString().length < 6 || passLogin?.toString() == '') {
      seterrors((prev) => ({ ...prev, passLogin: 'Mot de passe incorrect (Min 6 lettres ou chiffres)' }))
    } else {
      seterrors((prev) => ({ ...prev, passLogin: '' }))
    }

  }

  useEffect(() => {

    Validate();

  }, [Email, passLogin])


  // console.log(Email, passLogin);

  function ScrolltoCaptcha() {

    const matches = document.querySelector(".captcha-section");

    console.log(matches);

    if (matches) {

      let position = matches.getBoundingClientRect();


      window.scrollTo(position.left, position.top + window.scrollY - 60);
      return
    }

  }

  function Login(e) {

    e.preventDefault();

    setShowerrors(true)

    if (errors.Email !== '' || errors.passLogin !== '') {
      const matches = document.querySelectorAll(".err-cart-sum");

      for (let index = 0; index < matches.length; index++) {

        if (matches[index].textContent.trim() !== '') {

          let position = matches[index].getBoundingClientRect();


          window.scrollTo(position.left, position.top + window.scrollY - 120);
          return
        }
        // if (matches[index].textContent.trim() !== '') {

        //     matches[index].scrollIntoView({behavior: "smooth"});
        //     return
        // }              
      }


      // matches[0].scrollIntoView({behavior: "smooth"});
      console.log(matches[0].offsetTop);
      // matches[0].focus();

      console.log(errors);
      return;
    }
    setloading(true)
    // console.log(Email, passLogin);

    axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/login`, { "email": Email, "password": passLogin }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    )
      .then(function (response) {

        console.log(response.data);

        // if (response.data['email'] && response.data['Email'] && response.data['token']) {
        //   // console.log(response.data['email'], response.data['Email'], response.data['token']);
        //   setAuth({ email: response.data['email'], Email: response.data['Email'], token: response.data['token'] })
        //   setloading(false)
        //   navigate('/profil')
        //   navigate(0)
        // }

        // if (response.data['errorCode'] == "-1") {
        //   seterr("Email")
        //   recaptchaRef.current.reset();
        //   setCaptchaChecked(false)
        // } else if (response.data['errorCode'] == "-2") {
        //   seterr("password")
        //   recaptchaRef.current.reset();
        //   setCaptchaChecked(false)
        // }

        if (response.data['error'] == true) {
          console.log(response.data['error']);
          // setAuth({email : response.data['email'] , Email : response.data['Email'] , token :response.data['token']})
          setloading(false)

          console.log('hhhhhhhhhh');
          if (response.data['errorList'].constructor == Array) {
            seterr(response.data['errorList'])
          }

          const matches = document.querySelectorAll(".alert-store");

          for (let index = 0; index < matches.length; index++) {

            if (matches[index].textContent.trim() !== '') {

              let position = matches[index].getBoundingClientRect();


              window.scrollTo(position.left, position.top + window.scrollY - 60);
              return
            }
            // if (matches[index].textContent.trim() !== '') {

            //     matches[index].scrollIntoView({behavior: "smooth"});
            //     return
            // }              
          }


          // matches[0].scrollIntoView({behavior: "smooth"});
          console.log(matches[0].offsetTop);
          // matches[0].focus();

          // console.log(errors);
          return;
          // return navigate('/')
        }

        if (response.data['msg']) {
          setAuth({ token: response.data['token'] })
          setPermission(response.data['permission'])
          setloading(false)
          // if (response.data["role"] === "adminDZ") {
            // return navigate('/dashboardadmin')
            return window.location = '/dashboardadmin'
          // } else {
            // return window.location = '/dashboard';
          // }
        }

        setloading(false)
        // console.log(response.data);

      })
      .catch(function (error) {

        console.log(error);
        setloading(false)
        recaptchaRef.current.reset();
        setCaptchaChecked(false)

        // console.log(error);
      });

  }

  return (
    <div className='container'>
      {/* {err &&
        <div className="alert alert-danger" role="alert">
          {errTextapi[err][Lang]}
        </div>} */}

      {err?.length > 0 && err?.map((item, i) => (

        item.constructor == String ?
          <div className="alert-store" role="alert">
            - {item}
          </div>
          : ''

      ))
      }

      {loading && 
      <Loader />
      }
      <form className="row g-3 m-auto justify-content-center" >
        <div className="col-md-8">
          <label htmlFor="inputEmail4" className="form-label">{(Account['e-mail'][Lang])}</label>
          <input maxLength={200} type="text" required onChange={(e) => setEmail(e.target.value.replace(/[^a-zA-Z0-9\.\@]+/g, ''))} value={Email} className="form-control" id="inputEmail4" />
          <div className='err-cart-sum'>{Showerrors && errors.Email !== '' ? errors.Email : ''}</div>
        </div>
        <div className="col-md-8">
          <label htmlFor="inputPassword4" className="form-label">{(Account['MotdePasse'][Lang])}</label>
          <input maxLength={300} type="password" required  onChange={(e) => setpassLogin(e.target.value)} value={passLogin} className="form-control" id="inputPassword4" />
          <div className='err-cart-sum'>{Showerrors && errors.passLogin !== '' ? errors.passLogin : ''}</div>

        </div>

        <div className="col-8 text-center">
          {/* <div className="form-check"> */}

          {/* <label onClick={() => navigate('/forgetpassword')} className="form-check-label forget-password" htmlFor="gridCheck">
            {(Account['Motdepasseoublié'][Lang])}
          </label> */}
          {/* </div> */}
        </div>
        <div className="col-8">
          {CaptchaChecked ? <button onClick={Login} className="login-btn">{(Account['Seconnecter'][Lang])}</button> : <button type='button' onClick={ScrolltoCaptcha} className="login-btn">{(Account['Seconnecter'][Lang])}</button>}
        </div>

        <div className='text-center d-flex justify-content-center captcha-section'>


          <ReCAPTCHA
            sitekey="6Lel8MshAAAAABqg5dtbZL6Tlm1mpRJnO8m8qz68"
            onChange={onChange}
            onExpired={() => setCaptchaChecked(false)}
            ref={recaptchaRef}
          />
        </div>
        <div className='text-center captcha-err' dir={Lang === 'fr' ? 'ltr' : 'rtl'}> {captchaerror} </div>

        {/* <div className="col-12 text-center">
          <button type="submit" className="btn-google login-btn-fb">
            <svg xmlns="http://www.w3.org/2000/svg" width="38.25" height="39.027" viewBox="0 0 38.25 39.027">
              <g id="_7123025_logo_google_g_icon" data-name="7123025_logo_google_g_icon" transform="translate(-30.025 -29.098)">
                <path id="Tracé_249" data-name="Tracé 249" d="M94.648,70.754a24.405,24.405,0,0,0-.34-3.954H75.9v7.525H86.443a8.869,8.869,0,0,1-3.911,5.909l6.292,4.889a19.189,19.189,0,0,0,5.824-14.369Z" transform="translate(-26.373 -21.674)" fill="#4280ef" />
                <path id="Tracé_250" data-name="Tracé 250" d="M52.33,99.629a18.667,18.667,0,0,0,12.924-4.719l-6.292-4.846a11.836,11.836,0,0,1-17.6-6.164L34.9,88.874A19.494,19.494,0,0,0,52.33,99.629Z" transform="translate(-2.803 -31.505)" fill="#34a353" />
                <path id="Tracé_251" data-name="Tracé 251" d="M38.559,66.9a11.854,11.854,0,0,1,0-7.482L32.1,54.4a19.539,19.539,0,0,0,0,17.515Z" transform="translate(0 -14.546)" fill="#f6b704" />
                <path id="Tracé_252" data-name="Tracé 252" d="M52.33,36.836a10.646,10.646,0,0,1,7.482,2.933l5.569-5.612A18.779,18.779,0,0,0,52.33,29.1,19.494,19.494,0,0,0,34.9,39.854l6.462,5.016A11.673,11.673,0,0,1,52.33,36.836Z" transform="translate(-2.803 0)" fill="#e54335" />
              </g>
            </svg>

            Continuer avec Google</button>
        </div> */}

      </form>
    </div>
  )
}

export default Login