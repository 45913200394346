import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const NewAdmin = () => {

    const { id } = useParams();
    const [ProductData, setProductData] = useState('')


    // useEffect(() => {

    //     const fetchData = async () => {
    //         // setLoading(true);
    //         try {
    //             const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/${id}`, {
    //                 headers: {
    //                     'Authorization': `Bearer ${Auth?.token}`
    //                 }
    //             });
    //             console.log(response['data']);
    //             setProductData(response['data'])
    //             setNom(response['data']['nom'])
    //             setEmail(response['data']['email'])
    //             setRole(response['data']['role'])


    //         } catch (error) {
    //             console.error(error.message);
    //         }
    //         // setLoading(false);
    //     }

    //     fetchData();

    // }, []);




    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)











    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Nom, setNom] = useState('')
    const [Email, setEmail] = useState('')
    const [Role, setRole] = useState('adminDZ')
    const [ShowPass, setShowPass] = useState(false)

    const [Password, setPassword] = useState('')
    const [RePassword, setRePassword] = useState('')





    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
      }



    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Nom: '', Email: '', Role: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        if (Nom?.toString().trim().length > 100 || Nom?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Nom: 'Veuillez entrer le nom !!' }))
        } else {
            seterrors((prev) => ({ ...prev, Nom: '' }))
        }

        if (Email?.toString().trim().length > 200 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
            seterrors((prev) => ({ ...prev, Email: 'Veuillez saisir un e-mail valide !!' }))
        } else {
            seterrors((prev) => ({ ...prev, Email: '' }))
        }

        if (Role?.toString()?.trim().length > 20 || Role?.toString()?.trim() == '') {
            seterrors((prev) => ({ ...prev, Role: 'Veuillez entrer le rôle' }))
        } else {
            seterrors((prev) => ({ ...prev, Role: '' }))
        }

         

            if (Password?.toString().length > 300 || Password?.toString().length < 6 || Password?.toString() == '') {
                seterrors((prev) => ({ ...prev, Password: 'Mot de passe incorrect (Min 6 lettres ou chiffres)' }))
            } else {
                seterrors((prev) => ({ ...prev, Password: '' }))
            }
    
            if (RePassword?.toString().length > 300 || RePassword?.toString().length < 6 || RePassword?.toString() == '' ||  RePassword?.toString() !== Password?.toString()) {
                seterrors((prev) => ({ ...prev, rePassword: 'Les deux mots de passe doivent être identiques' }))
            } else {
                seterrors((prev) => ({ ...prev, rePassword: '' }))
            }

         


    }

    useEffect(() => {

        Validate();

    }, [Nom, Email, Role , Password, RePassword])


    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])








    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.Nom !== '' || errors.Email !== '' || errors.Role !== '' || errors.Password !== '' || errors.rePassword !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }












        setLoading(true)


        axios.post(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/new_admin`, { "email": Email?.toString().trim(), "password": Password, "role": Role, "nom": Nom?.toString().trim() }, {
            headers: {

                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)
                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                        }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été ajoutées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function() {
                        window.location = "/listadmins";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }

    const Roles = [
        {name : "Niveau 1", id: 1},
        {name : "Niveau 2", id: 2},
        {name : "Niveau 3", id: 3},
        {name : "Logistique", id: 4},
        {name : "Réception", id: 5},
        {name : "Admin" , id : "Admin"},
    ]; 








    return (
        <div className='dashboard'>

            {Loading && 
            <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'> Nouvel administrateur <span>Store</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        <form className="row g-5">
                        <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (
                                    
                                    item.constructor == String ?
                                    <div className="alert-store" role="alert">
                                        - {item}
                                    </div>
                                    :''

                                ))
                                }

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom</label>
                                <input type="text" onChange={(e) => setNom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Nom} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Nom !== '' ? errors.Nom : ''}</div>
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Email</label>
                                <input type="text" onChange={(e) => setEmail(e.target.value.replace(/[^a-zA-Z0-9\.\@]+/g, ''))} value={Email} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Email !== '' ? errors.Email : ''}</div>

                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Role</label>

                                <select id="inputState" class="form-select" onChange={(e) => setRole(e.target.value)}>
                                    <option disabled value="">Role</option>
                                    {Roles?.length > 0 && Roles?.map((item, i) => (
                                    <option selected={Role == item?.id} value={item?.id}>{item?.name}</option>
                                ))
                                }
                                    {/* <option selected value={"admin"}>Admin</option> */}
                                    {/* <option value={"adminBoutique"}>User</option> */}
                                </select>
                                {/* <input type="text" onChange={(e) => setRole(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Role} className="form-control" id="inputEmail4" /> */}
                                <div className='err-cart-sum'>{Showerrors && errors.Role !== '' ? errors.Role : ''}</div>
                            </div>

                            {/* <div className="col-md-12 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Changer mot de passe ?</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setShowPass(e.target.checked)} checked={ShowPass} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div> */}

                            {/* {
                                ShowPass &&
                                <> */}

                                    <div className="col-md-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputEmail4" className="form-label">Nouveau mot de passe</label>
                                        <input type="text" onChange={(e) => setPassword(e.target.value)} value={Password} className="form-control" id="inputPassword4" />
                                        <div className='err-cart-sum'>{Showerrors && errors.Password !== '' ? errors.Password : ''}</div>

                                    </div>

                                    <div className="col-md-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputEmail4" className="form-label">Répétez le nouveau mot de passe</label>
                                        <input type="text" onChange={(e) => setRePassword(e.target.value)} value={RePassword} className="form-control" id="inputRePassword4" />
                                        <div className='err-cart-sum'>{Showerrors && errors.rePassword !== '' ? errors.rePassword : ''}</div>

                                    </div>
                                {/* </>
                            } */}













                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Créer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NewAdmin