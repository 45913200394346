import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAlsalam/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';
import LangContext from '../../Context/lang';
import LoadData from '../../components/Global/LoadData';
import BankContext from '../../Context/bank';

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import downloadjs from "downloadjs";
import FormProjectEquipments from '../DashboardAdmin/FormProjectEquipments';
import FormProjectEquipmentsAlsalam from './FormProjectEquipmentsAlsalam';


const VoirDemande = ({ Step, setStep, StepTitel, setFormdata, dar, Local }) => {

    const { bankActive, GetTaksitPrice } = useContext(BankContext);

    async function ANNEXE03() {


        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/ANNEXE 03 تصريح بالالتزام.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page





        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 264,
                y: (height / 2) + 79,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (NIN) {

            firstPage.drawText(`${NIN}`, {
                x: 260,
                y: (height / 2) + 60,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }














        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `ANNEXE 03 تصريح بالالتزام ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function Rapport() {


        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/Rapport d'entretien.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        firstPage.drawText(`${new Date().toLocaleDateString('fr')}`, {
            x: 437,
            y: (height / 2) + 198,
            size: 10,
            font: helveticaFont,
            // color: rgb(0.95, 0.1, 0.1),
        })



        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 322,
                y: (height / 2) + 115,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Civilite) {

            firstPage.drawText(`${Civilite}`, {
                x: 300,
                y: (height / 2) + 115,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }





        if (id) {

            firstPage.drawText(`${id}`, {
                x: 322,
                y: (height / 2) + 90,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (CreatedAt) {

            firstPage.drawText(`${new Date(CreatedAt)?.toLocaleDateString('fr')}`, {
                x: 313,
                y: (height / 2) + 9,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }



        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 280,
                y: (height / 2) + -12,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }




        if (id) {

            firstPage.drawText(`${id}`, {
                x: 155,
                y: (height / 2) + -31,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (activité) {

            let TitleProject = ProjectsList?.find(o => o.id == activité);



            if (TitleProject && TitleProject?.title) {
                firstPage.drawText(`${TitleProject?.title}`, {
                    x: 347,
                    y: (height / 2) + -91,
                    size: 9,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            }

            // if (TitleProject && TitleProject?.cat_name) {

            //     var catNameFr = '';
            //     if (TitleProject?.cat_name =="المشاريع المتنقلة") {
            //         catNameFr = "Mobile"
            //     }else if(TitleProject?.cat_name =="المشاريع الثابتة"){
            //         catNameFr = "Fixe"

            //     }else if (TitleProject?.cat_name == "المشاريع الخاصة بذوي الهمم") {
            //         catNameFr = "Handicapé"
            //     }
            //     ThirdPage.drawText(` / ${catNameFr}`, {
            //         x: 213,
            //         y: (height / 2) + 228,
            //         size: 8,
            //         font: helveticaFont,

            //         // color: rgb(0.95, 0.1, 0.1),
            //     })
            // } 


        }









        // if (DatededElivrence) {

        //     firstPage.drawText(`${new Date(DatededElivrence).toLocaleDateString('fr')}`, {
        //         x: 290,
        //         y: (height / 2) + 129,
        //         size: 14,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Wilayadedélivrence) {

        //     firstPage.drawText(`${Wilayadedélivrence}`, {
        //         x: 80,
        //         y: (height / 2) + 129,
        //         size: 12,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 58,
        //         y: (height / 2) + 69,
        //         size: 10,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }





        // firstPage.drawText(`${new Date().toLocaleDateString('fr')}`, {
        //     x: 393,
        //     y: (height / 2) + -165,
        //     size: 12,
        //     font: helveticaFont,
        //     // color: rgb(0.95, 0.1, 0.1),
        // })

        // if (Wilaya) {


        //     firstPage.drawText(`${Wilaya}`, {
        //         x: 210,
        //         y: (height / 2) + -165,
        //         size: 12,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }









        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `Rapport d'entretien ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function BMCCafeteria() {


        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/BMC Cafeteria.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 630,
                y: (height / 2) + 260,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `BMC Cafeteria ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function BMCVegetablesandfruits() {


        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/BMC خضر وفواكه.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 630,
                y: (height / 2) + 260,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `BMC خضر وفواكه ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function BMCFastFood() {


        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/BMC FAST FOOD.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 630,
                y: (height / 2) + 260,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `BMC FAST FOOD ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function createPdf4() {


        const pdfFileOriginale = `/demande_financement.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]
        const SecondPage = pages[1]
        const ThirdPage = pages[2]



        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page




        if (IdDemande) {

            firstPage.drawText(`${IdDemande}`, {
                x: 130,
                y: (height / 2) + 233,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (CreatedAt) {

            firstPage.drawText(`${new Date(CreatedAt).toLocaleString('fr')}`, {
                x: 206,
                y: (height / 2) + 233,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }








        if (Civilite && (Civilite == 'M' || Civilite == 'Mme' || Civilite == 'Mle')) {

            firstPage.drawText(`${Civilite}`, {
                x: 181,
                y: (height / 2) + 148,
                size: 12,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (Nom) {

            firstPage.drawText(`${Nom}`, {
                x: 181,
                y: (height / 2) + 121,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Prenom) {

            firstPage.drawText(`${Prenom}`, {
                x: 181,
                y: (height / 2) + 93,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Datedenaissance) {

            firstPage.drawText(`${new Date(Datedenaissance).toLocaleDateString('fr')}`, {
                x: 181,
                y: (height / 2) + 65,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }




        if (Wilayadenaissance) {

            firstPage.drawText(`${Wilayadenaissance}`, {
                x: 181,
                y: (height / 2) + 37,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Communedenaissance) {

            firstPage.drawText(`${Communedenaissance}`, {
                x: 181,
                y: (height / 2) + 9,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Situationfamiliale) {

            firstPage.drawText(`${Situationfamiliale}`, {
                x: 181,
                y: (height / 2) + -19,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (onlyNumbers(Children)) {

            firstPage.drawText(`${Children}`, {
                x: 181,
                y: (height / 2) + -48,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (onlyNumbers(Personnes)) {

            firstPage.drawText(`${Personnes}`, {
                x: 181,
                y: (height / 2) + -76,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (Wilaya) {


            firstPage.drawText(`${Wilaya}`, {
                x: 181,
                y: (height / 2) + -190,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Daira) {


            firstPage.drawText(`${Daira}`, {
                x: 181,
                y: (height / 2) + -218,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (CodePostal) {


            firstPage.drawText(`${CodePostal}`, {
                x: 181,
                y: (height / 2) + -246,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Adresse) {

            firstPage.drawText(`${Adresse}`, {
                x: 181,
                y: (height / 2) + -274,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Mobile) {

            firstPage.drawText(`${Mobile}`, {
                x: 181,
                y: (height / 2) + -302,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (Mail) {

            firstPage.drawText(`${Mail}`, {
                x: 181,
                y: (height / 2) + -330,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Numerodecartenational) {

            SecondPage.drawText(`${Numerodecartenational}`, {
                x: 181,
                y: (height / 2) + 394,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (DatededElivrence) {

            SecondPage.drawText(`${new Date(DatededElivrence).toLocaleDateString('fr')}`, {
                x: 181,
                y: (height / 2) + 366,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Wilayadedélivrence) {

            SecondPage.drawText(`${Wilayadedélivrence}`, {
                x: 181,
                y: (height / 2) + 338,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (NIN) {

            SecondPage.drawText(`${NIN}`, {
                x: 181,
                y: (height / 2) + 310,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Profession) {

            SecondPage.drawText(`${Profession}`, {
                x: 181,
                y: (height / 2) + 198,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }




        if (Adressedelemployeur) {

            SecondPage.drawText(`${Adressedelemployeur}`, {
                x: 181,
                y: (height / 2) + 58,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Salaire && Salaire != null && Salaire != 0 && Salaire != undefined && Salaire != '' && Salaire != 'undefined' && Salaire != 'NULL') {

            SecondPage.drawText(`${Salaire} DA`, {
                x: 181,
                y: (height / 2) + -56,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }



        if (Compte == 'CCP') {

            SecondPage.drawText(`${CompteCCP} Clé ${CCPClé}`, {
                x: 181,
                y: (height / 2) + -84,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (créditempruntimmobilier == 'oui') {

            SecondPage.drawText(`oui`, {
                x: 181,
                y: (height / 2) + -168,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        } else {


            SecondPage.drawText(`non`, {
                x: 181,
                y: (height / 2) + -168,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })



        }



        if (créditvoiture == 'oui') {

            SecondPage.drawText(`oui`, {
                x: 181,
                y: (height / 2) + -196,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        } else {


            SecondPage.drawText(`non`, {
                x: 181,
                y: (height / 2) + -196,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })



        }




        if (Autrecrédit == 'oui') {

            SecondPage.drawText(`oui`, {
                x: 181,
                y: (height / 2) + -224,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        } else {


            SecondPage.drawText(`non`, {
                x: 181,
                y: (height / 2) + -224,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })



        }



        if (Compteépargne == 'oui') {

            SecondPage.drawText(`oui`, {
                x: 181,
                y: (height / 2) + -340,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        } else {


            SecondPage.drawText(`non`, {
                x: 181,
                y: (height / 2) + -340,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })



        }




        if (Compteépargne == 'oui') {

            ThirdPage.drawText(`${CompteépargneText} DA`, {
                x: 181,
                y: (height / 2) + 394,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }



        if (immeublesoumeubles) {

            ThirdPage.drawText(`${immeublesoumeubles} DA`, {
                x: 181,
                y: (height / 2) + 366,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }



        if (activité) {

            let TitleProject = ProjectsList?.find(o => o.id == activité);



            if (TitleProject && TitleProject?.title) {
                ThirdPage.drawText(`${TitleProject?.title}`, {
                    x: 34,
                    y: (height / 2) + 228,
                    size: 7,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            }

            if (TitleProject && TitleProject?.cat_name) {

                var catNameFr = '';
                if (TitleProject?.cat_name == "المشاريع المتنقلة") {
                    catNameFr = "Mobile"
                } else if (TitleProject?.cat_name == "المشاريع الثابتة") {
                    catNameFr = "Fixe"

                } else if (TitleProject?.cat_name == "المشاريع الخاصة بذوي الهمم") {
                    catNameFr = "Handicapé"
                }
                ThirdPage.drawText(`${catNameFr}`, {
                    x: 213,
                    y: (height / 2) + 228,
                    size: 8,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            }


        }



        if (Time) {

            ThirdPage.drawText(`${Time} Mois`, {
                x: 288,
                y: (height / 2) + 228,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }



        if (activité) {

            let TitleProject = ProjectsList?.find(o => o.id == activité);

            function TTC(Price) {

                return Math.round((Price + (Price * 0.10 + 9000)) * 1.19);
                // return Math.round((Price + (Price * 0.27 + 9000)) * 1.19) + 25000
            }



            if (TitleProject && TitleProject?.price) {
                ThirdPage.drawText(`${TitleProject?.price?.toLocaleString('en')} DA`, {
                    x: 333,
                    y: (height / 2) + 228,
                    size: 8,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })

                ThirdPage.drawText(`${TTC(parseInt(TitleProject?.price))?.toLocaleString('en')} DA`, {
                    x: 415,
                    y: (height / 2) + 228,
                    size: 8,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })

                ThirdPage.drawText(`${GetTaksitPrice(parseInt(TTC(parseInt(TitleProject?.price))), Time)['getallprice']?.toLocaleString('en')} DA`, {
                    x: 501,
                    y: (height / 2) + 228,
                    size: 8,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            }

        }



        if (Wilaya) {


            ThirdPage.drawText(`${Wilaya}`, {
                x: 99,
                y: (height / 2) + 173,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        ThirdPage.drawText(`${new Date().toLocaleDateString('fr')}`, {
            x: 233,
            y: (height / 2) + 173,
            size: 9,
            font: helveticaFont,
            // color: rgb(0.95, 0.1, 0.1),
        })









        // if (Profession) {

        //     firstPage.drawText(`${Profession}`, {
        //         x: 103,
        //         y: (height / 2) + 146,
        //         size: 8,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }

        // if (Adressedelemployeur && Adressedelemployeur != '' && Adressedelemployeur != 0) {

        //     firstPage.drawText(`${Adressedelemployeur}`, {
        //         x: 103,
        //         y: (height / 2) + 136,
        //         size: 8,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }


        // firstPage.drawText(`Algerienne`, {
        //     x: 70,
        //     y: (height / 2) + -77,
        //     size: 9,
        //     font: helveticaFont,
        //     // color: rgb(0.95, 0.1, 0.1),
        // })




        // if (CodePostal) {

        //     firstPage.drawText(`${CodePostal}`, {
        //         x: 70,
        //         y: (height / 2) + -153,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }




        // if (GreenCard && (GreenCard == 'oui' || GreenCard == 'non')) {

        //     if (GreenCard == 'oui') {
        //         firstPage.drawText(`/`, {
        //             x: 297,
        //             y: (height / 2) + -302,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })
        //     } else {

        //         firstPage.drawText(`/`, {
        //             x: 430,
        //             y: (height / 2) + -302,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })

        //     }

        // }



        // if (Nom && Prenom && Civilite) {

        //     firstPage.drawText(`${Civilite} ${Nom} ${Prenom}`, {
        //         x: 100,
        //         y: (height / 2) + 189,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Datedenaissance) {

        //     firstPage.drawText(`${Datedenaissance}`, {
        //         x: 290,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Wilayadenaissance) {

        //     firstPage.drawText(`${Wilayadenaissance}`, {
        //         x: 120,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 120,
        //         y: (height / 2) + 66,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }









        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `demande_financement ${Nom} ${Prenom}.pdf`, "application/pdf");


        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function createPdf3() {


        // Embed our custom font in the document
        // const customFont = await pdfDoc.embedFont(fontBytes)

        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'


        const pdfFileOriginale = `/fiche_FATCA.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page


        if (Civilite && (Civilite == 'M' || Civilite == 'Mme' || Civilite == 'Mle')) {

            if (Civilite == 'M') {
                firstPage.drawText(`/`, {
                    x: 283,
                    y: (height / 2) + 4,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            } else if (Civilite == 'Mme' || Civilite == 'Mle') {

                firstPage.drawText(`/`, {
                    x: 432,
                    y: (height / 2) + 4,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })

            }


        }

        if (Nom) {

            firstPage.drawText(`${Nom}`, {
                x: 72,
                y: (height / 2) + -37,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Prenom) {

            firstPage.drawText(`${Prenom}`, {
                x: 170,
                y: (height / 2) + -37,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Datedenaissance) {

            firstPage.drawText(`${new Date(Datedenaissance).toLocaleDateString('fr')}`, {
                x: 260,
                y: (height / 2) + -37,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }




        if (Wilayadenaissance) {

            firstPage.drawText(`${Wilayadenaissance}`, {
                x: 390,
                y: (height / 2) + -37,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        firstPage.drawText(`Algerienne`, {
            x: 70,
            y: (height / 2) + -77,
            size: 9,
            font: helveticaFont,
            // color: rgb(0.95, 0.1, 0.1),
        })



        if (Adresse) {

            firstPage.drawText(`${Adresse}`, {
                x: 70,
                y: (height / 2) + -113,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (CodePostal) {

            firstPage.drawText(`${CodePostal}`, {
                x: 70,
                y: (height / 2) + -153,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Mobile) {

            firstPage.drawText(`${Mobile}`, {
                x: 70,
                y: (height / 2) + -230,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Mail) {

            firstPage.drawText(`${Mail}`, {
                x: 70,
                y: (height / 2) + -270,
                size: 9,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (GreenCard && (GreenCard == 'oui' || GreenCard == 'non')) {

            if (GreenCard == 'oui') {
                firstPage.drawText(`/`, {
                    x: 297,
                    y: (height / 2) + -302,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            } else {

                firstPage.drawText(`/`, {
                    x: 430,
                    y: (height / 2) + -302,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })

            }

        }



        // if (Nom && Prenom && Civilite) {

        //     firstPage.drawText(`${Civilite} ${Nom} ${Prenom}`, {
        //         x: 100,
        //         y: (height / 2) + 189,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Datedenaissance) {

        //     firstPage.drawText(`${Datedenaissance}`, {
        //         x: 290,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Wilayadenaissance) {

        //     firstPage.drawText(`${Wilayadenaissance}`, {
        //         x: 120,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 120,
        //         y: (height / 2) + 66,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }









        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `FATCA ${Nom} ${Prenom}.pdf`, "application/pdf");


        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }
    async function createPdf2() {


        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/annexe.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 100,
                y: (height / 2) + 192,
                size: 12,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Civilite) {

            firstPage.drawText(`${Civilite}`, {
                x: 70,
                y: (height / 2) + 192,
                size: 12,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (DatededElivrence) {

            firstPage.drawText(`${new Date(DatededElivrence).toLocaleDateString('fr')}`, {
                x: 290,
                y: (height / 2) + 129,
                size: 14,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (Wilayadedélivrence) {

            firstPage.drawText(`${Wilayadedélivrence}`, {
                x: 80,
                y: (height / 2) + 129,
                size: 12,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }



        if (Adresse) {

            firstPage.drawText(`${Adresse}`, {
                x: 58,
                y: (height / 2) + 69,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }





        firstPage.drawText(`${new Date().toLocaleDateString('fr')}`, {
            x: 393,
            y: (height / 2) + -165,
            size: 12,
            font: helveticaFont,
            // color: rgb(0.95, 0.1, 0.1),
        })

        if (Wilaya) {


            firstPage.drawText(`${Wilaya}`, {
                x: 210,
                y: (height / 2) + -165,
                size: 12,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }









        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `CENTRALE DES RISQUES ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }

    async function createPdf() {

        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'

        const pdfFileOriginale = `/demande_d_ouverture_de_compte.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)


        const urlFont = `/fonts/Fairuz-Bold.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 100,
                y: (height / 2) + 218,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (Civilite) {

            firstPage.drawText(`${Civilite}`, {
                x: 82,
                y: (height / 2) + 218,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }
        if (Nom && Prenom) {

            firstPage.drawText(`${Nom} ${Prenom}`, {
                x: 100,
                y: (height / 2) + 206,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }


        if (PrenomPere) {

            firstPage.drawText(`${PrenomPere}`, {
                x: 81,
                y: (height / 2) + 196,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (PrenomMere && NomMere) {

            firstPage.drawText(`${PrenomMere} ${NomMere}`, {
                x: 167,
                y: (height / 2) + 196,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (Datedenaissance && Wilayadenaissance && Communedenaissance) {

            firstPage.drawText(`${new Date(Datedenaissance).toLocaleDateString('fr')} ${Wilayadenaissance} ${Communedenaissance}`, {
                x: 127,
                y: (height / 2) + 186,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (Situationfamiliale) {

            firstPage.drawText(`${Situationfamiliale}`, {
                x: 103,
                y: (height / 2) + 176,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (onlyNumbers(Children)) {

            firstPage.drawText(`${Children}`, {
                x: 103,
                y: (height / 2) + 166,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (Profession) {

            firstPage.drawText(`${Profession}`, {
                x: 103,
                y: (height / 2) + 146,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (Adressedelemployeur && Adressedelemployeur != '' && Adressedelemployeur != 0) {

            firstPage.drawText(`${Adressedelemployeur}`, {
                x: 103,
                y: (height / 2) + 136,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }



        if (Adresse) {

            firstPage.drawText(`${Adresse}`, {
                x: 70,
                y: (height / 2) + 116,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (SéjourUSA && (SéjourUSA == 'oui' || SéjourUSA == 'non')) {

            if (SéjourUSA == 'oui') {
                firstPage.drawText(`/`, {
                    x: 217,
                    y: (height / 2) + 46,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            } else if (SéjourUSA == 'non') {

                firstPage.drawText(`/`, {
                    x: 262,
                    y: (height / 2) + 46,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })

            }


        }


        if (GreenCard && (GreenCard == 'oui' || GreenCard == 'non')) {

            if (GreenCard == 'oui') {
                firstPage.drawText(`/`, {
                    x: 221,
                    y: (height / 2) + 34,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })
            } else {

                firstPage.drawText(`/`, {
                    x: 265,
                    y: (height / 2) + 34,
                    size: 12,
                    font: helveticaFont,

                    // color: rgb(0.95, 0.1, 0.1),
                })

            }

        }

        if (Mobile && onlyNumbers(Mobile)) {

            firstPage.drawText(`${Mobile}`, {
                x: 81,
                y: (height / 2) + -22,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (Mail) {

            firstPage.drawText(`${Mail}`, {
                x: 81,
                y: (height / 2) + -42,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        if (Salaire) {

            firstPage.drawText(`${Salaire} DA`, {
                x: 110,
                y: (height / 2) + -52,
                size: 8,
                font: helveticaFont,

                // color: rgb(0.95, 0.1, 0.1),
            })
        }


        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `Demande d’Ouverture de Compte ${Nom} ${Prenom}.pdf`, "application/pdf");

        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // url.title = 'hh'
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }

    const [ProjectsList, setProjectsList] = useState([])


    useEffect(() => {
        // setLoadingProjectsList(true);

        const GetProjectsList = async () => {

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam/projects/projects_all/admin`,{
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${Auth?.token}`
                    },
                  });
                console.log(response);
                setProjectsList(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoadingProjectsList(false);
        }

        GetProjectsList();

    }, []);



    const { Global, dartaksitsim,ProductLang, Account, adresse, situationprofessionnelle, chargesetpatrimoine, RecapitulatifSimTaksit, dari3marsim, Panier, infoperssonnelle } = useContext(LangContext);
    const Lang = 'ar';
    const ComuneMountdenaissance = useRef(true);


    const [DemandeType, setDemandeType] = useState("0")
    const [Civilite, setCivilite] = useState('M')
    const [Nom, setNom] = useState('')
    const [Prenom, setPrenom] = useState('')
    const [PrenomPere, setPrenomPere] = useState('')
    const [NomMere, setNomMere] = useState('')
    const [PrenomMere, setPrenomMere] = useState('')
    const [Datedenaissance, setDatedenaissance] = useState('')
    const [Wilayadenaissance, setWilayadenaissance] = useState('')
    const [Communedenaissance, setCommunedenaissance] = useState('')
    const [CommunedenaissanceList, setCommunedenaissanceList] = useState([])
    const [Actedenaissance, setActedenaissance] = useState('')
    const [Typeactnaissnce, setTypeactnaissnce] = useState('')
    const [Situationfamiliale, setSituationfamiliale] = useState('')
    const [NiveauDinstructions, setNiveauDinstructions] = useState('')
    const [Children, setChildren] = useState(0)
    const [Personnes, setPersonnes] = useState(0)



    const DairaMount = useRef(true);
    const ComuneMount = useRef(true);
    const PostalCodeMount = useRef(true);
    const CommunededélivrenceMount = useRef(true);

    const [Wilaya, setWilaya] = useState('')
    const [DairaList, setDairaList] = useState([])
    const [Daira, setDaira] = useState('')
    const [CommuneList, setCommuneList] = useState([])
    const [Commune, setCommune] = useState('')
    const [CodePostalList, setCodePostalList] = useState([])
    const [CodePostal, setCodePostal] = useState('')
    const [Adresse, setAdresse] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Mobile2, setMobile2] = useState('')
    const [Mail, setMail] = useState('')
    const [ReMail, setReMail] = useState('')
    const [NIN, setNIN] = useState('')
    const [Numerodecartenational, setNumerodecartenational] = useState('')
    const [DatededElivrence, setDatededElivrence] = useState('')
    const [Wilayadedélivrence, setWilayadedélivrence] = useState('')
    const [CommunededélivrenceList, setCommunededélivrenceList] = useState('')
    const [Communededélivrence, setCommunededélivrence] = useState('')
    const [Habitation, setHabitation] = useState('')




    const [activité, setactivité] = useState('')
    const [Certificat, setCertificat] = useState('non')
    const [TypeCertificat, setTypeCertificat] = useState('')
    const [Experience, setExperience] = useState('non')


    const [Compte, setCompte] = useState('Non')
    const [CompteCCP, setCompteCCP] = useState('')
    const [CCPClé, setCCPClé] = useState('')
    const [Banque, setBanque] = useState('')
    const [SalamBank, setSalamBank] = useState('non')
    const [sourcesderevenus, setsourcesderevenus] = useState('non')
    const [Time, setTime] = useState('60')


    const [TypeOfPerson, setTypeOfPerson] = useState("0")
    const [Datedébutdactivité, setDatedébutdactivité] = useState('')
    const [ImmatriculationauregistreCommerce, setImmatriculationauregistreCommerce] = useState('')
    const [DateImmatriculationauregistreCommerce, setDateImmatriculationauregistreCommerce] = useState('')
    const [LieuImmatriculationauregistreCommerce, setLieuImmatriculationauregistreCommerce] = useState('')
    const [Adressedusiège, setAdressedusiège] = useState('')
    const [Situationdulocal, setSituationdulocal] = useState(0)









    const [créditempruntimmobilier, setcréditempruntimmobilier] = useState('non')
    const [Mensualitéducréditimmobilier, setMensualitéducréditimmobilier] = useState('')
    const [Organismeprêteurimmobilier, setOrganismeprêteurimmobilier] = useState('')
    const [Datedefinducréditimmobilier, setDatedefinducréditimmobilier] = useState('')

    const [créditvoiture, setcréditvoiture] = useState('non')
    const [Mensualitéducréditvoiture, setMensualitéducréditvoiture] = useState('')
    const [Organismeprêteurdecréditvoiture, setOrganismeprêteurdecréditvoiture] = useState('')
    const [Datedefinducréditvoiture, setDatedefinducréditvoiture] = useState('')

    const [Autrecrédit, setAutrecrédit] = useState('non')
    const [Mensualitéduautrecrédit, setMensualitéduautrecrédit] = useState('')
    const [Organismeprêteurautrecrédit, setOrganismeprêteurautrecrédit] = useState('')
    const [Datedefinduautrecrédit, setDatedefinduautrecrédit] = useState('')

    const [Loyerpayer, setLoyerpayer] = useState('non')
    const [Loyer, setLoyer] = useState('')


    const [SéjourUSA, setSéjourUSA] = useState('non')
    const [GreenCard, setGreenCard] = useState('non')
    const [Compteépargne, setCompteépargne] = useState('non')
    const [CompteépargneText, setCompteépargneText] = useState('')
    const [immeublesoumeubles, setimmeublesoumeubles] = useState('')



    const [Secteurdactivité, setSecteurdactivité] = useState('')
    const [Typedecontrat, setTypedecontrat] = useState('')
    const [Périodedessai, setPériodedessai] = useState('oui')
    const [Ancienneté, setAncienneté] = useState('')
    const [Nomdelemployeur, setNomdelemployeur] = useState('')
    const [Wilayadelemployeur, setWilayadelemployeur] = useState('')

    const [Profession, setProfession] = useState('')
    const [Adressedelemployeur, setAdressedelemployeur] = useState('')
    const [Salaire, setSalaire] = useState('')






    const [FormValidate, setFormValidate] = useState(true)
    const [Showerr, setShowerr] = useState(false)
    const [FormChecked, setFormChecked] = useState(false)

    useEffect(() => {


        Validation();

    }, [Civilite, Nom, Prenom, PrenomPere, NomMere, PrenomMere, Wilayadenaissance, Commune, Children, Datedenaissance, Actedenaissance, Typeactnaissnce, Situationfamiliale, NiveauDinstructions, Personnes, Wilaya, Daira, Commune, CodePostal, Adresse, Mobile, Mobile2, Mail, ReMail, NIN, Numerodecartenational, DatededElivrence, Wilayadedélivrence, Communededélivrence, Habitation, activité, Certificat, TypeCertificat, Experience, créditempruntimmobilier, Mensualitéducréditvoiture, Mensualitéducréditimmobilier, Organismeprêteurimmobilier, Datedefinducréditimmobilier, créditvoiture, Loyer, Organismeprêteurdecréditvoiture, Datedefinducréditvoiture, Autrecrédit, Mensualitéduautrecrédit, Organismeprêteurautrecrédit, Datedefinduautrecrédit, Loyerpayer, Profession, Adressedelemployeur, Salaire, Compte, CompteCCP, CCPClé, Banque, SalamBank, sourcesderevenus, Time, SéjourUSA, GreenCard, Compteépargne, CompteépargneText, immeublesoumeubles])

    useEffect(() => {

        if (Showerr) {
            const matches = document.querySelectorAll(".alert-form");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerr])


    function Validation() {

        setFormValidate(true)

        if (Civilite !== "Mme" && Civilite !== "Mle" && Civilite !== "M") {
            setFormValidate(false)
        }
        if (Nom.trim().length < 2) {
            setFormValidate(false)
        }
        if (Prenom.trim().length < 2) {
            setFormValidate(false)
        }
        if (PrenomPere.trim().length < 2) {
            setFormValidate(false)
        }
        if (NomMere.trim().length < 2) {
            setFormValidate(false)
        }
        if (PrenomMere.trim().length < 2) {
            setFormValidate(false)
        }
        if (!dateIsValid(new Date(Datedenaissance))) {
            setFormValidate(false)
        }
        if (Wilayadenaissance === "") {
            setFormValidate(false)
        }
        if (Communedenaissance === "") {
            setFormValidate(false)
        }
        if (!onlyNumbers(Actedenaissance) || Actedenaissance.trim().length > 5) {
            setFormValidate(false)
        }
        if (Typeactnaissnce !== "biss" && Typeactnaissnce !== "ter" && Typeactnaissnce !== "autre") {
            setFormValidate(false)
        }
        if (Situationfamiliale == "") {
            setFormValidate(false)
        }
        if (NiveauDinstructions == "") {
            setFormValidate(false)
        }
        if (!onlyNumbers(Children)) {
            setFormValidate(false)
        }
        if (!onlyNumbers(Personnes)) {
            setFormValidate(false)
        }


        if (Wilaya === "") {
            setFormValidate(false)
        }
        if (Daira === "") {
            setFormValidate(false)
        }
        if (Commune === "") {
            setFormValidate(false)
        }
        if (CodePostal === "") {
            setFormValidate(false)
        }
        if (Adresse.trim() === "") {
            setFormValidate(false)
        }

        if (!onlyNumbers(Mobile) || Mobile.length !== 10 || (Mobile.slice(0, 2) !== "07" && Mobile.slice(0, 2) !== "05" && Mobile.slice(0, 2) !== "06")) {
            setFormValidate(false)
        }
        // if (!onlyNumbers(Mobile2) || Mobile2.length !== 10 || (Mobile2.slice(0, 2) !== "07" && Mobile2.slice(0, 2) !== "05" && Mobile2.slice(0, 2) !== "06") || Mobile == Mobile2) {
        //   setFormValidate(false)
        // }
        if (!isValidEmail(Mail.trim())) {
            setFormValidate(false)
        }
        if (!isValidEmail(ReMail.trim())) {
            setFormValidate(false)
        }
        if (Mail.trim() !== ReMail.trim()) {
            setFormValidate(false)
        }
        if (!onlyNumbers(NIN) || NIN.length !== 18) {
            setFormValidate(false)
        }
        if (!onlyNumbers(Numerodecartenational) || Numerodecartenational.length !== 9) {
            setFormValidate(false)
        }
        if (DatededElivrence === "") {
            setFormValidate(false)
        }
        if (Wilayadedélivrence === "") {
            setFormValidate(false)
        }
        if (Communededélivrence === "") {
            setFormValidate(false)
        }

        if (!activité || !onlyNumbers(activité)) {
            setFormValidate(false)
            // console.log('err');
        }
        if (Certificat == 'oui') {

            if (TypeCertificat?.toString().trim() == "") {
                setFormValidate(false)
            }

        }

        if ((créditempruntimmobilier !== 'non' && créditempruntimmobilier !== 'oui') || (créditvoiture !== 'non' && créditvoiture !== 'oui') || (Autrecrédit !== 'non' && Autrecrédit !== 'oui') || (Loyerpayer !== 'non' && Loyerpayer !== 'oui')) {
            setFormValidate(false)
        }

        if (créditempruntimmobilier == 'oui') {

            if (!onlyNumbers(Mensualitéducréditimmobilier)) {
                setFormValidate(false)
            }
            if (Organismeprêteurimmobilier == '') {
                setFormValidate(false)
            }
            if (Datedefinducréditimmobilier == '') {
                setFormValidate(false)

            }

        }

        if (créditvoiture == 'oui') {

            if (!onlyNumbers(Mensualitéducréditvoiture)) {
                setFormValidate(false)
            }
            if (Organismeprêteurdecréditvoiture == '') {
                setFormValidate(false)
            }
            if (Datedefinducréditvoiture == '') {
                setFormValidate(false)

            }

        }

        if (Autrecrédit == 'oui') {

            if (!onlyNumbers(Mensualitéduautrecrédit)) {
                setFormValidate(false)
            }
            if (Organismeprêteurautrecrédit == '') {
                setFormValidate(false)
            }
            if (Datedefinduautrecrédit == '') {
                setFormValidate(false)
            }

        }

        if (Loyerpayer == 'oui') {

            if (!onlyNumbers(Loyer)) {
                setFormValidate(false)
            }

        }



        if (Profession?.toString().trim().length > 300) {
            setFormValidate(false)
            // console.log('err');
        }

        if (Salaire !== '') {

            if (!onlyNumbers(Salaire)) {
                setFormValidate(false)
            }
        }

        if (Adressedelemployeur?.toString().trim().length > 300) {
            setFormValidate(false)
            // console.log('err');
        }



        if (Compte == 'CCP') {

            if (!onlyNumbers(CompteCCP)) {
                setFormValidate(false)
                // console.log('err');
            }
            if (!onlyNumbers(CCPClé) || CCPClé?.toString().length !== 2) {
                setFormValidate(false)
                console.log('ccp');
                // console.log('err');
            }
        } else if (Compte == 'Banque') {

            if (Banque.trim() === '') {
                setFormValidate(false)
                // console.log('err');
            }

        }


        if (Time != '12' && Time != '24' && Time != '36' && Time != '48' && Time != '60') {
            setFormValidate(false)
        }


        if (Compteépargne == 'oui') {

            if (CompteépargneText.trim() == '') {
                setFormValidate(false)
            }

        }


    }

    // const CommuneDeNaissanceMount = useRef(true);
    // const DairaMount = useRef(true);
    // const CommuneMount = useRef(true);
    // const PostalCodeMount = useRef(true);




    const { id ,id_user} = useParams();
    // const [ProductData, setProductData] = useState('')





    const { Auth, setAuth, AuthFournisseur, setAuthFournisseur } = useContext(AuthContext);

    let navigate = useNavigate();


    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)




    const [AllData, setAllData] = useState(new FormData())

    const [NoInfo, setNoInfo] = useState(true)
    const [LoadDataWait, setLoadDataWait] = useState(false)

    const [CreatedAt, setCreatedAt] = useState('')
    const [IdDemande, setIdDemande] = useState('')





    useEffect(() => {

        const fetchData = async () => {
            setLoadDataWait(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam/getuserinfo/admin/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                console.log(response);
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login-alsalam')

                }

                if (response['data'] == 'empty') {
                    setLoadDataWait(false);
                    return setNoInfo(true)

                } else {
                    setNoInfo(false)
                }
                console.log(response['data']);
                // setProductData(response['data'])


                console.log(response?.data[0]?.type_vendeur);

                setCreatedAt(response?.data[0]?.created_at || '')
                setIdDemande(response?.data[0]?.id || '')


                setCivilite(response?.data[0]?.civilite || '');
                setNom(response?.data[0]?.nom || '');
                setPrenom(response?.data[0]?.prenom || '');
                setPrenomPere(response?.data[0]?.prenom_pere || '');
                setPrenomMere(response?.data[0]?.prenom_mere || '');
                setNomMere(response?.data[0]?.nom_mere || '');
                setDatedenaissance(response?.data[0]?.date_naissance || '');
                setWilayadenaissance(response?.data[0]?.wilaya_naissance || '');
                setCommunedenaissance(response?.data[0]?.communen_naissance || '');
                // setTel(response?.data[0]?.tel || '');
                // setEmail(response?.data[0]?.email_admin || '');
                setActedenaissance(response?.data[0]?.num_act_naissance || '');
                setTypeactnaissnce(response?.data[0]?.type_act_naissance || '');
                setSituationfamiliale(response?.data[0]?.situation_familiale || '');
                setNiveauDinstructions(response?.data[0]?.niveau_dinstructions || '');
                setChildren(Number(response?.data[0]?.numbre_enfants) || 0);
                setPersonnes(Number(response?.data[0]?.numbre_personnes) || 0);



                setWilaya(response?.data[0]?.wilaya || '');
                setDaira(response?.data[0]?.daira || '');
                setCommune(response?.data[0]?.commune || '');
                setCodePostal(response?.data[0]?.codepostal || '');
                setAdresse(response?.data[0]?.adresse || "");
                setMobile(response?.data[0]?.mobile || '');
                setMobile2(response?.data[0]?.mobile2 || '');
                setMail(response?.data[0]?.email || '');
                setReMail(response?.data[0]?.email || '');
                setNIN(response?.data[0]?.nin || '');
                setNumerodecartenational(response?.data[0]?.piece_identite_num || "");
                setDatededElivrence(response?.data[0]?.piece_identite_date || "");
                setWilayadedélivrence(response?.data[0]?.piece_identite_wilaya || "");
                setCommunededélivrence(response?.data[0]?.piece_identite_commune || "");
                setHabitation(response?.data[0]?.habitation || "");


                setactivité(response?.data[0]?.activite || '');
                setExperience(response?.data[0]?.experience || '');
                setCertificat(response?.data[0]?.certificat || '');
                setTypeCertificat(response?.data[0]?.certificat_type || '');



                setProfession(response?.data[0]?.profession || '')
                setAdressedelemployeur(response?.data[0]?.adresse_de_lemployeur || '')
                setSalaire(response?.data[0]?.salaire || '')
                setCompte(response?.data[0]?.compte_type || '')
                setCompteCCP(response?.data[0]?.compte_ccp_number || '')
                setCCPClé(response?.data[0]?.compte_ccp_cle || '')
                setBanque(response?.data[0]?.compte_bank || '')
                setSalamBank(response?.data[0]?.compte_al_salam || '')
                setsourcesderevenus(response?.data[0]?.autre_sources_de_revenus || '')
                setTime(response?.data[0]?.duree || '')





                setcréditempruntimmobilier(response?.data[0]?.credit_immobilier || '');
                setMensualitéducréditimmobilier(response?.data[0]?.mensualite_credit_immobilier || '');
                setOrganismeprêteurimmobilier(response?.data[0]?.organisme_credit_immobilier || '');
                setDatedefinducréditimmobilier(response?.data[0]?.date_fin_credit_immobilier || '');



                setcréditvoiture(response?.data[0]?.credit_voiture || '');
                setMensualitéducréditvoiture(response?.data[0]?.mensualite_credit_voiture || '');
                setOrganismeprêteurdecréditvoiture(response?.data[0]?.organisme_credit_voiture || '');
                setDatedefinducréditvoiture(response?.data[0]?.date_fin_credit_voiture || '');


                setAutrecrédit(response?.data[0]?.autre_credit || '');
                setMensualitéduautrecrédit(response?.data[0]?.mensualite_autre_credit || '');
                setOrganismeprêteurautrecrédit(response?.data[0]?.organisme_autre_credit || '');
                setDatedefinduautrecrédit(response?.data[0]?.date_fin_autre_credit || '');

                setLoyerpayer(response?.data[0]?.loyer || '');
                setLoyer(response?.data[0]?.mensualite_loyer || '');


                setSéjourUSA(response?.data[0]?.temporary_residence || '');
                setGreenCard(response?.data[0]?.green_card || '');
                setCompteépargne(response?.data[0]?.compte_epargne || '');
                setCompteépargneText(response?.data[0]?.compte_epargne_price || '');
                setimmeublesoumeubles(response?.data[0]?.immeubles_ou_meubles_price || '');


                setDemandeType(Number(response?.data[0]?.demande_type) || 0);
                setTypeOfPerson(Number(response?.data[0]?.type_of_person) || 0);
                setImmatriculationauregistreCommerce(response?.data[0]?.ImmatriculationauregistreCommerce || '');
                setDateImmatriculationauregistreCommerce(response?.data[0]?.DateImmatriculationauregistreCommerce || '');
                setDatedébutdactivité(response?.data[0]?.Datedebutdactivite || '');

                setLieuImmatriculationauregistreCommerce(response?.data[0]?.LieuImmatriculationauregistreCommerce || '');
                setAdressedusiège(response?.data[0]?.Adressedusiege || '');
                setSituationdulocal(Number(response?.data[0]?.Situationdulocal) || 0);



                setSecteurdactivité(response?.data[0]?.Secteurdactivite || '');
                setTypedecontrat(response?.data[0]?.Typedecontrat || '');
                setPériodedessai(response?.data[0]?.Periodedessai || '');
                setAncienneté(response?.data[0]?.Anciennete || '');
                setNomdelemployeur(response?.data[0]?.Nomdelemployeur || '');
                setWilayadelemployeur(response?.data[0]?.Wilayadelemployeur || '');






            } catch (error) {
                setLoadDataWait(false);

                console.error(error.message);
            }
            setLoadDataWait(false);
        }

        fetchData();

    }, []);

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [errors, seterrors] = useState({})
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)


    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    useEffect(() => {

        const GetCommunedenaissance = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilayadenaissance}`);
                // console.log(response);
                setCommunedenaissanceList(response['communes']);
                // setCommune('')

                if (!ComuneMountdenaissance.current) {
                    setCommunedenaissance('')
                } else {
                    //   setCommune(Local?.communen_naissance || '')
                    ComuneMountdenaissance.current = false
                }
            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilayadenaissance !== '') {
            GetCommunedenaissance();
        }



    }, [Wilayadenaissance])



    useEffect(() => {

        const GetCommune = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);
                // console.log(response);
                setDairaList(response['codepostals'])

                if (!DairaMount.current) {
                    setDaira([])
                    setCommune('')
                    setCodePostal('')
                    setCommuneList([])
                    setCodePostalList([])
                } else {
                    DairaMount.current = false;
                }
            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetCommune();
        }


    }, [Wilaya])

    useEffect(() => {

        const GetDaira = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/commune?valeur=${Daira}`);
                // console.log(response);
                setCommuneList(response['codepostals'])
                if (!ComuneMount.current) {
                    setCommune('')
                    setCodePostal('')
                    setCodePostalList([])
                } else {
                    ComuneMount.current = false
                }

            } catch (error) {
                console.error(error.message);
            }
        }

        if (Daira !== '') {
            GetDaira();
        }


    }, [Daira])

    useEffect(() => {

        const GetCodePostal = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/codepostal?valeur=${Commune}`);
                // console.log(response);
                setCodePostalList(response['codepostals'])
                if (!PostalCodeMount.current) {
                    setCodePostal('')
                } else {
                    PostalCodeMount.current = false

                }
            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Commune !== '') {
            GetCodePostal();
        }


    }, [Commune])


    useEffect(() => {

        const Communededélivrence = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilayadedélivrence}`);
                // console.log(response);
                setCommunededélivrenceList(response['communes'])

                if (!CommunededélivrenceMount.current) {
                    setCommunededélivrence('')

                } else {
                    CommunededélivrenceMount.current = false
                }
            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilayadedélivrence !== '') {
            Communededélivrence();
        }


    }, [Wilayadedélivrence])


    // useEffect(() => {

    //     const GetCommunedenaissence = async () => {
    //         try {
    //             const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilayadenaissence}`);


    //             setCommuneList(response['communes'])
    //             // setCommunedenaissence('')

    //             if (!CommuneDeNaissanceMount.current) {
    //                 setCommune('')
    //             } else {
    //                 // setCommune(Local?.communen || '')
    //                 CommuneDeNaissanceMount.current = false
    //             }

    //         } catch (error) {
    //             // console.error(error.message);
    //         }
    //     }

    //     if (Wilayadenaissence !== '') {
    //         GetCommunedenaissence();
    //     }


    // }, [Wilayadenaissence])


    // useEffect(() => {

    //     const GetDaira = async () => {
    //         try {

    //             const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);

    //             // setCommunes([])
    //             // setCommune('')
    //             // setCodePostals([])
    //             // setCodePostal('')

    //             setDairas(response['codepostals'])
    //             if (!DairaMount.current) {
    //                 setDaira('')
    //                 setCommunes([])
    //                 setCommune('')
    //                 setCodePostals([])
    //                 setCodePostal('')
    //             } else {
    //                 // setCommune(Local?.communen || '')
    //                 DairaMount.current = false
    //             }

    //         } catch (error) {
    //             // console.error(error.message);
    //         }
    //     }

    //     if (Wilaya !== '') {
    //         GetDaira();
    //     }


    // }, [Wilaya])


    // useEffect(() => {

    //     const GetCommunes = async () => {
    //         try {
    //             const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/commune?valeur=${Daira}`);


    //             // setCodePostals([])
    //             // setCodePostal('')


    //             setCommunes(response['codepostals'])

    //             if (!CommuneMount.current) {

    //                 setCommune('')
    //                 setCodePostals([])
    //                 setCodePostal('')
    //             } else {
    //                 // setCommune(Local?.communen || '')
    //                 CommuneMount.current = false
    //             }

    //         } catch (error) {
    //             // console.error(error.message);
    //         }
    //     }

    //     if (Daira !== '') {
    //         GetCommunes();
    //     }


    // }, [Daira])

    // useEffect(() => {

    //     const GetCodePostal = async () => {
    //         try {
    //             const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/codepostal?valeur=${Commune}`);
    //             // console.log(response);
    //             // setCodePostal('')
    //             setCodePostals(response['codepostals'])

    //             if (!PostalCodeMount.current) {
    //                 setCodePostal('')
    //             } else {

    //                 // setCodePostal(Local?.codepostal || '')
    //                 PostalCodeMount.current = false

    //             }





    //         } catch (error) {
    //             // console.error(error.message);
    //         }
    //     }

    //     if (Commune !== '') {
    //         GetCodePostal();
    //     }


    // }, [Commune])

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }






    function GoNext() {
        setShowerr(true)

        // console.log(FormValidate);

        if (!FormValidate) {

            const matches = document.querySelectorAll(".alert-form");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }

            return
        }



        // for (var pair of AllData.entries()) {
        //     console.log(pair[0] + ' - ' + pair[1]);
        // }

        let Data = {
            "civilite": Civilite,
            "nom": Nom,
            "prenom": Prenom,
            "prenom_pere": PrenomPere,
            "nom_mere": NomMere,
            "prenom_mere": PrenomMere,
            "date_naissance": Datedenaissance,
            "wilaya_naissance": Wilayadenaissance,
            "communen_naissance": Communedenaissance,
            "num_act_naissance": Actedenaissance,
            "type_act_naissance": Typeactnaissnce,
            "situation_familiale": Situationfamiliale,
            "niveau_dinstructions": NiveauDinstructions,
            "numbre_enfants": Children.toString(),
            "numbre_personnes": Personnes.toString(),
            "wilaya": Wilaya,
            "daira": Daira,
            "commune": Commune,
            "codepostal": CodePostal,
            "adresse": Adresse.trim(),
            "mobile": Mobile,
            "mobile2": Mobile2,
            "email": Mail.trim(),
            "nin": NIN,
            "piece_identite_num": Numerodecartenational,
            "piece_identite_date": DatededElivrence,
            "piece_identite_wilaya": Wilayadedélivrence,
            "piece_identite_commune": Communededélivrence,
            "habitation": Habitation,
            "activite": activité,
            "duree": Time,
            "certificat": Certificat,
            "certificat_type": TypeCertificat,
            "experience": Experience,
            "credit_immobilier": créditempruntimmobilier,
            "mensualite_credit_immobilier": Mensualitéducréditimmobilier,
            "organisme_credit_immobilier": Organismeprêteurimmobilier,
            "date_fin_credit_immobilier": Datedefinducréditimmobilier,
            "credit_voiture": créditvoiture,
            "mensualite_credit_voiture": Mensualitéducréditvoiture,
            "organisme_credit_voiture": Organismeprêteurdecréditvoiture,
            "date_fin_credit_voiture": Datedefinducréditvoiture,
            "autre_credit": Autrecrédit,
            "mensualite_autre_credit": Mensualitéduautrecrédit,
            "organisme_autre_credit": Organismeprêteurautrecrédit,
            "date_fin_autre_credit": Datedefinduautrecrédit,
            "loyer": Loyerpayer,
            "mensualite_loyer": Loyer,



            "profession": Profession.trim(),
            "adresse_de_lemployeur": Adressedelemployeur.trim(),
            "salaire": Salaire,
            "compte_type": Compte,
            "compte_ccp_number": CompteCCP,
            "compte_ccp_cle": CCPClé,
            "compte_bank": Banque,
            "compte_al_salam": SalamBank,
            "autre_sources_de_revenus": sourcesderevenus,


            "temporary_residence": SéjourUSA,
            "green_card": GreenCard,
            "compte_epargne": Compteépargne,
            "compte_epargne_price": CompteépargneText,
            "immeubles_ou_meubles_price": immeublesoumeubles.trim()



        };

        setLoading(true)

        console.log(Data);

        axios.put(`${process.env.REACT_APP_LOCAL_URL}/vendeur/update_vendeur/${id}`, Data, {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {

                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login-alsalam')

                }

                // if (response.data['email'] && response.data['username'] && response.data['token']) {
                //     console.log(response.data['email'] , response.data['username'] , response.data['token']);
                //     setAuth({email : response.data['email'] , username : response.data['username'] , token :response.data['token']})
                //     setloading(false)
                //     return navigate('/')
                // }

                // console.log(response.data['creditDemandes'][0]);
                // console.log(response.data['creditDemandes'][0]['hash']);

                console.log(response.data);
                setLoading(false)

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'تم التحديث بنجاح',
                        type: 'success',
                        text: 'تم تحديث معلوماتك بنجاح',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location.reload();
                    });
                }


                else {


                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'هناك مشكل',
                        type: 'error',
                        text: "هناك مشكل في تحديث بياناتك",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });
                }


                // if (response.data['creditDemandes'][0]['hash']) {
                //   return navigate('/validation/' + Formdata[0]['hash'])

                // } else {
                //   setWrong('Il y a un problème avec les données que vous avez saisies, veuillez réessayer')
                //   console.log(response.data);
                // }

                // setloading(false)
                // console.log(response.data);

            }).catch(function (error) {
                // setLoading(false)
                setLoading(false)
                Swal.fire({
                    title: 'هناك مشكل',
                    type: 'error',
                    text: "هناك مشكل في تحديث بياناتك",
                    timer: 2000,
                    showConfirmButton: false,
                    icon: 'error',
                    didClose: () => window.scrollTo(0, 0)



                });

                console.log(error);
            });







        // console.log('test', FormValidate);
    }






    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }


    const [Loading, setLoading] = useState(false)

    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];



    const ActiviteList = [
        "تاجر", "ماكثة في البيت", "حرفي", "مؤسسة ناشئة", "مصمم", "مطور برامج", "مؤسسة مصغرة", "أخرى"
    ]

    // function GoNext() {
    //     setShowerr(true)

    //     // console.log(FormValidate);

    //     if (!FormValidate) {

    //         const matches = document.querySelectorAll(".alert-form");

    //         for (let index = 0; index < matches.length; index++) {

    //             if (matches[index].textContent.trim() !== '') {

    //                 let position = matches[index].getBoundingClientRect();


    //                 window.scrollTo(position.left, position.top + window.scrollY - 120);
    //                 return
    //             }
    //             // if (matches[index].textContent.trim() !== '') {

    //             //     matches[index].scrollIntoView({behavior: "smooth"});
    //             //     return
    //             // }              
    //         }

    //         return
    //     }

    //     setFormdata((prev) => [
    //         {
    //             ...prev[0], ...{
    //                 "civilite": Civilite,
    //                 "nom": Nom,
    //                 "prenom": Prenom,
    //                 "prenom_pere": PrenomPere,
    //                 "nom_mere": NomMere,
    //                 "prenom_mere": PrenomMere,
    //                 "date_naissance": Datedenaissance,
    //                 "wilaya_naissance": Wilaya,
    //                 "communen_naissance": Commune,
    //                 "num_act_naissance": Actedenaissance,
    //                 "type_act_naissance": Typeactnaissnce,
    //                 "situation_familiale": Situationfamiliale,
    //                 "niveau_dinstructions": NiveauDinstructions,
    //                 "numbre_enfants": Children.toString(),
    //                 "numbre_personnes": Personnes.toString(),
    //             }
    //         }
    //     ])

    //     const FormUserInfo = {
    //         "civilite": Civilite,
    //         "nom": Nom,
    //         "prenom": Prenom,
    //         "prenom_pere": PrenomPere,
    //         "nom_mere": NomMere,
    //         "prenom_mere": PrenomMere,
    //         "date_naissance": Datedenaissance,
    //         "wilaya_naissance": Wilaya,
    //         "communen_naissance": Commune,
    //         "num_act_naissance": Actedenaissance,
    //         "type_act_naissance": Typeactnaissnce,
    //         "situation_familiale": Situationfamiliale,
    //         "niveau_dinstructions": NiveauDinstructions,
    //         "numbre_enfants": Children.toString(),
    //         "numbre_personnes": Personnes.toString(),
    //     }

    //     // localStorage.setItem('FormUserInfoStep1', JSON.stringify(FormUserInfo))

    //     // setStep(2)
    //     // window.scrollTo(0, 0);


    //     // console.log('test', FormValidate);
    // }
    const projects = [
        { id: 1, name: "إستوديو متنقل" },
        { id: 2, name: "مطور برامج" },
        { id: 3, name: "توزيع المياه والسوائل" },
        { id: 4, name: "حلاق" },
        { id: 5, name: "حلاقة" },
        { id: 6, name: "حلويات ومرطبات" },
        { id: 7, name: "صحفي" },
        { id: 8, name: "فاست فود" },
        { id: 9, name: "مسمكة متنقلة" },
        { id: 10, name: "مصمم" },
        { id: 11, name: "مطعم متنقل" },
        { id: 12, name: "مغسلة متنقلة" },
        { id: 13, name: "مقهى" },
        { id: 14, name: "مؤسسة ناشئة" },
        { id: 15, name: "ورشة أليمينيوم" },
        { id: 16, name: "ورشة تدفئة" },
        { id: 17, name: "ورشة خياطة" },
        { id: 18, name: "ورشة ميكانيك" },
        { id: 19, name: "ورشة نجارة" },
        { id: 20, name: "وكالة سياحية" },
    ]

    function GetProjectUrl(id) {

        console.log('hhhhh');

        let urlProject = ProjectsList?.find(o => o.id == id);


        if (urlProject && urlProject?.slug && urlProject?.valid == 1) {

            return urlProject?.slug;
        } else {
            return false
        }
    }
    
    const [ClientEquipments, setClientEquipments] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam/user/client_equipments/${id_user}`,{
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${Auth?.token}`
                    },
                  });
                console.log(response);

                if (response?.error == false) {

                    setClientEquipments(response?.data)
                    
                    
                }
            } catch (error) {
                // console.error(error.message);
            }
            // setLoading(false);
        }

        // if (activité) {

            fetchData();
        // }

    }, []);

    function GetTotalPriceEquipments() {

        if (ClientEquipments?.length) {
            
            return ClientEquipments?.reduce((n  , { quantity, price }) =>n + (quantity * TTC(parseFloat(price))), 0) || 0


        }

        return 0
      }


      function TTC(Price) {
          return Math.round((Price + (Price * 0.10 + 9000)) * 1.19);
      }

      function ProjectPrice() {
        if (activité) {

            let TitleProject = ProjectsList?.find(o => o.id == activité);

            if (TitleProject && TitleProject?.price) {
                return  TitleProject?.price;
            }
        }

        return null
    }

      console.log(ProjectPrice() + ' ' + GetTotalPriceEquipments());
      console.log(ProjectPrice() + GetTotalPriceEquipments());
      
      const LEFT_TO_RIGHT_MARK = "\u202d";




    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Client Informations<span> Iamal </span></div>
                    <div dir='rtl' className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        {/* <div className='form-store'> */}
                        {
                            LoadDataWait ? <LoadData /> : ''
                        }
                        {/* {
                            !LoadDataWait && NoInfo === true ? 
                            <>
                            <h5 className='text-center'>
                                يرجى إختيار المشروع الذي تود الإستفادة منه وقم بملئ معلوماتك
                                </h5>
                               
                                <div className="new-admin-btn" onClick={() => navigate('/projects')} style={{ cursor: "pointer" }}>
                                    مشاريعنا
                                </div></>
                             : ""
                        } */}

                        {!LoadDataWait ?
                            <div style={{ background: 'none' }} className='info-account saidaty'>



                                {/* <div className="d-flex btn-doc mb-5">
                                    <div className="donwload-document-btn me-4" onClick={() => createPdf()}>Ouverture de compte</div>
                                    <div className="donwload-document-btn me-4" onClick={() => createPdf2()}>CENTRALE DES RISQUES</div>
                                    <div className="donwload-document-btn me-4" onClick={() => createPdf3()}>Fiche FATCA</div>
                                    <div className="donwload-document-btn me-4" onClick={() => createPdf4()}>Demande de Financement</div>
                                    <div className="donwload-document-btn me-4" onClick={() => BMCFastFood()}>BMC FAST FOOD</div>
                                    <div className="donwload-document-btn me-4" onClick={() => BMCVegetablesandfruits()}>BMC خضر وفواكه</div>
                                    <div className="donwload-document-btn me-4" onClick={() => BMCCafeteria()}>BMC Cafeteria</div>
                                    <div className="donwload-document-btn me-4" onClick={() => Rapport()}>Rapport d'entretien</div>
                                    <div className="donwload-document-btn me-4" onClick={() => ANNEXE03()}>ANNEXE 03 تصريح بالالتزام</div>
                                    <a target='blank' href='/ANNEXE 02 طلب تمويل المصغر (مؤسسات صغيرة وحرفيين).docx'><div className="donwload-document-btn me-4"> Demande de Financement TPE /ARTISAN </div></a>


                                </div> */}

                                <div className="row g-5 client-ar-info  form-info">
                                    {err?.length > 0 ?
                                        <div className='container py-3 '>
                                            {err?.length > 0 && err?.map((item, i) => (

                                                item.constructor == String ?
                                                    <div className="alert-store" role="alert">
                                                        - {item}
                                                    </div>
                                                    : ''

                                            ))
                                            }

                                        </div> : ''}



                                    <div className="col-md-12" dir='rtl'>
                                        <div className='mb-2'>نوع الطلبية</div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions199" id="inlineRadio199" checked={DemandeType == 0} onChange={(e) => setDemandeType(e.target.value)} defaultChecked={true} defaultValue="0" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio199">طلب تمويل</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions299" id="inlineRadio299" checked={DemandeType == 1} onChange={(e) => setDemandeType(e.target.value)} defaultValue="1" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio299">
                                                تجديد التمويل
                                            </label>
                                        </div>
                                    </div>



                                    <div className="col-md-12" dir='rtl'>
                                        <div className='mb-2'>{(infoperssonnelle['civilité'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input" disabled type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Civilite == 'M'} onChange={(e) => setCivilite(e.target.value)} defaultChecked={true} defaultValue="M" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio1">{(infoperssonnelle['civ1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input" disabled type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Civilite == 'Mme'} onChange={(e) => setCivilite(e.target.value)} defaultValue="Mme" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio2">{(infoperssonnelle['civ2'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input" disabled type="radio" name="inlineRadioOptions" id="inlineRadio3" checked={Civilite == 'Mle'} onChange={(e) => setCivilite(e.target.value)} defaultValue="Mle" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio3">{(infoperssonnelle['civ3'][Lang])}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-5 mt-5">
                                        <label className="form-label">{(infoperssonnelle['Nom'][Lang])}</label>
                                        <input readOnly maxLength={250} type="text" placeholder={(infoperssonnelle['Nom'][Lang])} onChange={(e) => setNom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Nom} className="form-control" id="inputEmail4" />






                                    </div>
                                    <div className="col-md-6 mb-5 mt-md-5">
                                        <label className="form-label">{(infoperssonnelle['Prénom'][Lang])}</label>
                                        <input readOnly maxLength={250} type="text" placeholder={(infoperssonnelle['Prénom'][Lang])} onChange={(e) => setPrenom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Prenom} className="form-control" id="inputEmail4" />



                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label className="form-label">{(infoperssonnelle['Prénompère'][Lang])}</label>
                                        <input readOnly maxLength={250} type="text" placeholder={(infoperssonnelle['Prénompère'][Lang])} onChange={(e) => setPrenomPere(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={PrenomPere} className="form-control" id="inputEmail4" />



                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label className="form-label">{(infoperssonnelle['Nommère'][Lang])}</label>
                                        <input readOnly maxLength={250} type="text" placeholder={(infoperssonnelle['Nommère'][Lang])} onChange={(e) => setNomMere(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={NomMere} className="form-control" id="inputEmail4" />



                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label className="form-label">{(infoperssonnelle['Prenommère'][Lang])}</label>
                                        <input readOnly maxLength={250} type="text" placeholder={(infoperssonnelle['Prenommère'][Lang])} onChange={(e) => setPrenomMere(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={PrenomMere} className="form-control" id="inputEmail4" />



                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label className="form-label">{(infoperssonnelle['Date_naissance'][Lang])}</label>
                                        <input readOnly type="date" onChange={(e) => setDatedenaissance(e.target.value)} defaultValue={Datedenaissance} className="text-end form-control" id="inputEmail4" />

                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label className="form-label">{(infoperssonnelle['Wilaya_naissance'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setWilayadenaissance(e.target.value)}>
                                            <option disabled selected="selected">{(Global['wilaya'][Lang])}</option>
                                            <option selected={Wilayadenaissance === "ADRAR"} value="ADRAR">(01) ADRAR</option>
                                            <option selected={Wilayadenaissance === "CHLEF"} value="CHLEF">(02) CHLEF</option>
                                            <option selected={Wilayadenaissance === "LAGHOUAT"} value="LAGHOUAT">(03) LAGHOUAT</option>
                                            <option selected={Wilayadenaissance === "OUM EL BOUAGHI"} value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
                                            <option selected={Wilayadenaissance === "BATNA"} value="BATNA">(05) BATNA</option>
                                            <option selected={Wilayadenaissance === "BEJAIA"} value="BEJAIA">(06) BEJAIA</option>
                                            <option selected={Wilayadenaissance === "BISKRA"} value="BISKRA">(07) BISKRA</option>
                                            <option selected={Wilayadenaissance === "BECHAR"} value="BECHAR">(08) BECHAR</option>
                                            <option selected={Wilayadenaissance === "BLIDA"} value="BLIDA">(09) BLIDA</option>
                                            <option selected={Wilayadenaissance === "BOUIRA"} value="BOUIRA">(10) BOUIRA</option>
                                            <option selected={Wilayadenaissance === "TAMANRASSET"} value="TAMANRASSET">(11) TAMANRASSET</option>
                                            <option selected={Wilayadenaissance === "TEBESSA"} value="TEBESSA">(12) TEBESSA</option>
                                            <option selected={Wilayadenaissance === "TLEMCEN"} value="TLEMCEN">(13) TLEMCEN</option>
                                            <option selected={Wilayadenaissance === "TIARET"} value="TIARET">(14) TIARET</option>
                                            <option selected={Wilayadenaissance === "TIZI OUZOU"} value="TIZI OUZOU">(15) TIZI-OUZOU</option>
                                            <option selected={Wilayadenaissance === "ALGER"} value="ALGER">(16) ALGER</option>
                                            <option selected={Wilayadenaissance === "DJELFA"} value="DJELFA">(17) DJELFA</option>
                                            <option selected={Wilayadenaissance === "JIJEL"} value="JIJEL">(18) JIJEL</option>
                                            <option selected={Wilayadenaissance === "SETIF"} value="SETIF">(19) SETIF</option>
                                            <option selected={Wilayadenaissance === "SAIDA"} value="SAIDA">(20) SAIDA</option>
                                            <option selected={Wilayadenaissance === "SKIKDA"} value="SKIKDA">(21) SKIKDA</option>
                                            <option selected={Wilayadenaissance === "SIDI BEL ABBES"} value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
                                            <option selected={Wilayadenaissance === "ANNABA"} value="ANNABA">(23) ANNABA</option>
                                            <option selected={Wilayadenaissance === "GUELMA"} value="GUELMA">(24) GUELMA</option>
                                            <option selected={Wilayadenaissance === "CONSTANTINE"} value="CONSTANTINE">(25) CONSTANTINE</option>
                                            <option selected={Wilayadenaissance === "MEDEA"} value="MEDEA">(26) MEDEA</option>
                                            <option selected={Wilayadenaissance === "MOSTAGANEM"} value="MOSTAGANEM">(27) MOSTAGANEM</option>
                                            <option selected={Wilayadenaissance === "M SILA"} value="M SILA">(28) M-SILA</option>
                                            <option selected={Wilayadenaissance === "MASCARA"} value="MASCARA">(29) MASCARA</option>
                                            <option selected={Wilayadenaissance === "OUARGLA"} value="OUARGLA">(30) OUARGLA</option>
                                            <option selected={Wilayadenaissance === "ORAN"} value="ORAN">(31) ORAN</option>
                                            <option selected={Wilayadenaissance === "EL BAYADH"} value="EL BAYADH">(32) EL-BAYADH</option>
                                            <option selected={Wilayadenaissance === "ILLIZI"} value="ILLIZI">(33) ILLIZI</option>
                                            <option selected={Wilayadenaissance === "BORDJ BOU ARRERIDJ"} value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
                                            <option selected={Wilayadenaissance === "BOUMERDES"} value="BOUMERDES">(35) BOUMERDES</option>
                                            <option selected={Wilayadenaissance === "EL TARF"} value="EL TARF">(36) EL-TARF</option>
                                            <option selected={Wilayadenaissance === "TINDOUF"} value="TINDOUF">(37) TINDOUF</option>
                                            <option selected={Wilayadenaissance === "TISSEMSILT"} value="TISSEMSILT">(38) TISSEMSILT</option>
                                            <option selected={Wilayadenaissance === "EL OUED"} value="EL OUED">(39) EL-OUED</option>
                                            <option selected={Wilayadenaissance === "KHENCHELA"} value="KHENCHELA">(40) KHENCHELA</option>
                                            <option selected={Wilayadenaissance === "SOUK AHRAS"} value="SOUK AHRAS">(41) SOUK-AHRAS</option>
                                            <option selected={Wilayadenaissance === "TIPAZA"} value="TIPAZA">(42) TIPAZA</option>
                                            <option selected={Wilayadenaissance === "MILA"} value="MILA">(43) MILA</option>
                                            <option selected={Wilayadenaissance === "AIN DEFLA"} value="AIN DEFLA">(44) AIN-DEFLA</option>
                                            <option selected={Wilayadenaissance === "NAAMA"} value="NAAMA">(45) NAAMA</option>
                                            <option selected={Wilayadenaissance === "AIN TEMOUCHENT"} value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
                                            <option selected={Wilayadenaissance === "GHARDAIA"} value="GHARDAIA">(47) GHARDAIA</option>
                                            <option selected={Wilayadenaissance === "RELIZANE"} value="RELIZANE">(48) RELIZANE</option>
                                            {/* <option selected={Wilaya === "Timimoun"} value="Timimoun">(49) Timimoun</option>
      <option selected={Wilaya === "Bordj Badji Mokhtar"} value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
      <option selected={Wilaya === "Ouled Djellal"} value="Ouled Djellal">(51) Ouled Djellal</option>
      <option selected={Wilaya === "Béni Abbès"} value="Béni Abbès">(52) Béni Abbès</option>
      <option selected={Wilaya === "In Salah"} value="In Salah">(53) In Salah</option>
      <option selected={Wilaya === "In Guezzam"} value="In Guezzam">(54) In Guezzam</option>
      <option selected={Wilaya === "Touggourt"} value="Touggourt">(55) Touggourt</option>
      <option selected={Wilaya === "Djanet"} value="Djanet">(56) Djanet</option>
      <option selected={Wilaya === "El M'Ghair"} value="El M'Ghair">(57) El M'Ghair</option>
      <option selected={Wilaya === "El Meniaa"} value="El Meniaa">(58) El Meniaa</option> */}
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label htmlFor="inputPassword4" className="form-label">{(infoperssonnelle['Commune_naissance'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setCommunedenaissance(e.target.value)}>

                                            {/* {
        CommuneList.length == 0 && <option disabled selected="selected">{(infoperssonnelle['Commune_naissance'][Lang])}</option>
      }

      {
        CommuneList.length > 0 && <option disabled selected="selected">{(infoperssonnelle['Commune_naissance'][Lang])}</option>
      } */}

                                            <option disabled selected={Communedenaissance == "" ? "selected" : ''}>{(infoperssonnelle['Commune_naissance'][Lang])}</option>

                                            {CommunedenaissanceList.length > 0 && CommunedenaissanceList.map((item, i) => (

                                                <option selected={Communedenaissance === item?.commune} value={item?.commune}>{item?.commune}</option>

                                            ))
                                            }
                                        </select>

                                    </div>
                                    {/* <label htmlFor="inputPassword4" className="form-label">Numéro d'acte de naissance</label> */}
                                    <div className="col-md-6 mb-5">
                                        <label className="form-label">{(infoperssonnelle['Num_act_naissance'][Lang])}</label>
                                        <input readOnly type="tel" maxlength="5" placeholder={(infoperssonnelle['Num_act_naissance'][Lang])} onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g, ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" />


                                    </div>
                                    <div className="col-md-6 mb-5">
                                        {/* <input readOnly  type="text" onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g , ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" /> */}
                                        <label className="form-label">{(infoperssonnelle['type_acte'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setTypeactnaissnce(e.target.value)}>
                                            <option disabled selected="selected">{(infoperssonnelle['type_acte'][Lang])}</option>
                                            <option selected={"biss" === Typeactnaissnce} value="biss">BISS </option>
                                            <option selected={"ter" === Typeactnaissnce} value="ter">TER</option>
                                            <option selected={"autre" === Typeactnaissnce} value="autre">Autre</option>
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label htmlFor="inputPassword4" className="form-label" >{(infoperssonnelle['situation_familiale'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setSituationfamiliale(e.target.value)}>
                                            <option disabled selected="selected">{(infoperssonnelle['situation_familiale'][Lang])}</option>

                                            {infoperssonnelle['situation_familiale_list']?.length > 0 && infoperssonnelle['situation_familiale_list']?.map((item, i) => (
                                                <option selected={Situationfamiliale === item['fr']} value={item['fr']}>{item[Lang]}</option>
                                            ))
                                            }
                                            {/* <option selected={Situationfamiliale === "c"} value="c">Célibataire</option>
      <option selected={Situationfamiliale === "m"} value="m">Marié </option>
      <option selected={Situationfamiliale === "d"} value="d">Divorcé</option>
      <option selected={Situationfamiliale === "v"} value="v">veuf</option> */}
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <label htmlFor="inputPassword4" className="form-label" >{(infoperssonnelle['Niveau'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setNiveauDinstructions(e.target.value)}>
                                            <option disabled selected="selected">{(infoperssonnelle['Niveau'][Lang])}</option>

                                            {infoperssonnelle['Niveau_list']?.length > 0 && infoperssonnelle['Niveau_list']?.map((item, i) => (
                                                <option selected={NiveauDinstructions == item['fr']} value={item['fr']}>{item[Lang]}</option>
                                            ))
                                            }
                                            {/* <option selected={NiveauDinstructions == 1} value={1}>Moins que le niveau secondaire</option>
      <option selected={NiveauDinstructions == 2} value={2}>Niveau secondaire</option>
      <option selected={NiveauDinstructions == 3} value={3}>Bachelier</option>
      <option selected={NiveauDinstructions == 4} value={4}>Universitaire</option> */}
                                        </select>

                                    </div>
                                    <div className="col-md-12 mb-2 mt-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(infoperssonnelle['nbrEnfants'][Lang])}</label>
                                        <div className='product-quantity'>{(infoperssonnelle['1'][Lang])} :
                                            <div className='quantity-btn'>
                                                {/* <button className="qtyminus" onClick={() => Children !== 0 ? setChildren(Children - 1) : ''} aria-hidden="true">−</button> */}
                                                <input readOnly className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Children} />
                                                {/* <button className="qtyplus" onClick={() => Children < 10 ? setChildren(Children + 1) : ''} aria-hidden="true">+</button> */}
                                            </div>
                                        </div>
                                        {/* <input readOnly  type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
                                    </div>
                                    <div className="col-md-12 mb-5 mt-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(infoperssonnelle['nbrpersonnes'][Lang])}</label>
                                        <div className='product-quantity'>{(infoperssonnelle['2'][Lang])} :
                                            <div className='quantity-btn'>
                                                {/* <button className="qtyminus" onClick={() => Personnes !== 0 ? setPersonnes(Personnes - 1) : ''} aria-hidden="true">−</button> */}
                                                <input readOnly className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Personnes} />
                                                {/* <button className="qtyplus" onClick={() => Personnes < 10 ? setPersonnes(Personnes + 1) : ''} aria-hidden="true">+</button> */}
                                            </div>
                                        </div>
                                        {/* <input readOnly  type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
                                    </div>





                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{(adresse['Wilaya'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
                                            <option disabled selected="selected">{(adresse['Wilaya'][Lang])}</option>
                                            <option selected={Wilaya === "ADRAR"} value="ADRAR">(01) ADRAR</option>
                                            <option selected={Wilaya === "CHLEF"} value="CHLEF">(02) CHLEF</option>
                                            <option selected={Wilaya === "LAGHOUAT"} value="LAGHOUAT">(03) LAGHOUAT</option>
                                            <option selected={Wilaya === "OUM EL BOUAGHI"} value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
                                            <option selected={Wilaya === "BATNA"} value="BATNA">(05) BATNA</option>
                                            <option selected={Wilaya === "BEJAIA"} value="BEJAIA">(06) BEJAIA</option>
                                            <option selected={Wilaya === "BISKRA"} value="BISKRA">(07) BISKRA</option>
                                            <option selected={Wilaya === "BECHAR"} value="BECHAR">(08) BECHAR</option>
                                            <option selected={Wilaya === "BLIDA"} value="BLIDA">(09) BLIDA</option>
                                            <option selected={Wilaya === "BOUIRA"} value="BOUIRA">(10) BOUIRA</option>
                                            <option selected={Wilaya === "TAMANRASSET"} value="TAMANRASSET">(11) TAMANRASSET</option>
                                            <option selected={Wilaya === "TEBESSA"} value="TEBESSA">(12) TEBESSA</option>
                                            <option selected={Wilaya === "TLEMCEN"} value="TLEMCEN">(13) TLEMCEN</option>
                                            <option selected={Wilaya === "TIARET"} value="TIARET">(14) TIARET</option>
                                            <option selected={Wilaya === "TIZI OUZOU"} value="TIZI OUZOU">(15) TIZI-OUZOU</option>
                                            <option selected={Wilaya === "ALGER"} value="ALGER">(16) ALGER</option>
                                            <option selected={Wilaya === "DJELFA"} value="DJELFA">(17) DJELFA</option>
                                            <option selected={Wilaya === "JIJEL"} value="JIJEL">(18) JIJEL</option>
                                            <option selected={Wilaya === "SETIF"} value="SETIF">(19) SETIF</option>
                                            <option selected={Wilaya === "SAIDA"} value="SAIDA">(20) SAIDA</option>
                                            <option selected={Wilaya === "SKIKDA"} value="SKIKDA">(21) SKIKDA</option>
                                            <option selected={Wilaya === "SIDI BEL ABBES"} value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
                                            <option selected={Wilaya === "ANNABA"} value="ANNABA">(23) ANNABA</option>
                                            <option selected={Wilaya === "GUELMA"} value="GUELMA">(24) GUELMA</option>
                                            <option selected={Wilaya === "CONSTANTINE"} value="CONSTANTINE">(25) CONSTANTINE</option>
                                            <option selected={Wilaya === "MEDEA"} value="MEDEA">(26) MEDEA</option>
                                            <option selected={Wilaya === "MOSTAGANEM"} value="MOSTAGANEM">(27) MOSTAGANEM</option>
                                            <option selected={Wilaya === "M SILA"} value="M SILA">(28) M-SILA</option>
                                            <option selected={Wilaya === "MASCARA"} value="MASCARA">(29) MASCARA</option>
                                            <option selected={Wilaya === "OUARGLA"} value="OUARGLA">(30) OUARGLA</option>
                                            <option selected={Wilaya === "ORAN"} value="ORAN">(31) ORAN</option>
                                            <option selected={Wilaya === "EL BAYADH"} value="EL BAYADH">(32) EL-BAYADH</option>
                                            <option selected={Wilaya === "ILLIZI"} value="ILLIZI">(33) ILLIZI</option>
                                            <option selected={Wilaya === "BORDJ BOU ARRERIDJ"} value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
                                            <option selected={Wilaya === "BOUMERDES"} value="BOUMERDES">(35) BOUMERDES</option>
                                            <option selected={Wilaya === "EL TARF"} value="EL TARF">(36) EL-TARF</option>
                                            <option selected={Wilaya === "TINDOUF"} value="TINDOUF">(37) TINDOUF</option>
                                            <option selected={Wilaya === "TISSEMSILT"} value="TISSEMSILT">(38) TISSEMSILT</option>
                                            <option selected={Wilaya === "EL OUED"} value="EL OUED">(39) EL-OUED</option>
                                            <option selected={Wilaya === "KHENCHELA"} value="KHENCHELA">(40) KHENCHELA</option>
                                            <option selected={Wilaya === "SOUK AHRAS"} value="SOUK AHRAS">(41) SOUK-AHRAS</option>
                                            <option selected={Wilaya === "TIPAZA"} value="TIPAZA">(42) TIPAZA</option>
                                            <option selected={Wilaya === "MILA"} value="MILA">(43) MILA</option>
                                            <option selected={Wilaya === "AIN DEFLA"} value="AIN DEFLA">(44) AIN-DEFLA</option>
                                            <option selected={Wilaya === "NAAMA"} value="NAAMA">(45) NAAMA</option>
                                            <option selected={Wilaya === "AIN TEMOUCHENT"} value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
                                            <option selected={Wilaya === "GHARDAIA"} value="GHARDAIA">(47) GHARDAIA</option>
                                            <option selected={Wilaya === "RELIZANE"} value="RELIZANE">(48) RELIZANE</option>
                                            {/* <option selected={Wilaya === "Timimoun"} value="Timimoun">(49) Timimoun</option>
        <option selected={Wilaya === "Bordj Badji Mokhtar"} value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
        <option selected={Wilaya === "Ouled Djellal"} value="Ouled Djellal">(51) Ouled Djellal</option>
        <option selected={Wilaya === "Béni Abbès"} value="Béni Abbès">(52) Béni Abbès</option>
        <option selected={Wilaya === "In Salah"} value="In Salah">(53) In Salah</option>
        <option selected={Wilaya === "In Guezzam"} value="In Guezzam">(54) In Guezzam</option>
        <option selected={Wilaya === "Touggourt"} value="Touggourt">(55) Touggourt</option>
        <option selected={Wilaya === "Djanet"} value="Djanet">(56) Djanet</option>
        <option selected={Wilaya === "El M'Ghair"} value="El M'Ghair">(57) El M'Ghair</option>
        <option selected={Wilaya === "El Meniaa"} value="El Meniaa">(58) El Meniaa</option> */}
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(adresse['Daïras'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setDaira(e.target.value)}>

                                            {/* {DairaList.length == 0 || DairaList.length > 0 && */}
                                            <option disabled selected={Daira == '' ? "selected" : ''}>{(adresse['Daïras'][Lang])}</option>
                                            {/* } */}


                                            {DairaList.length > 0 && DairaList.map((item, i) => (
                                                <option selected={Daira === item?.dairas} value={item?.dairas}>{item?.dairas}</option>
                                            ))
                                            }
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(adresse['Commune'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>

                                            {/* {CommuneList.length === 0 || CommuneList.length > 0 && */}
                                            <option disabled selected={Commune == "" ? "selected" : ''}>{(adresse['Commune'][Lang])}</option>
                                            {/* } */}


                                            {CommuneList.length > 0 && CommuneList.map((item, i) => (
                                                <option selected={Commune === item?.commune} value={item?.commune}>{item?.commune}</option>
                                            ))
                                            }
                                        </select>


                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(adresse['Codepostal'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setCodePostal(e.target.value)}>

                                            {/* { CodePostalList.length === 0 || CodePostalList.length > 0 && */}

                                            <option disabled selected={CodePostal == '' ? "selected" : ''}>{(adresse['Codepostal'][Lang])}</option>
                                            {/* } */}

                                            {CodePostalList.length > 0 && CodePostalList.map((item, i) => (
                                                <option selected={CodePostal === item?.codePostal} value={item?.codePostal}>{item?.codePostal}</option>
                                            ))
                                            }
                                        </select>

                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(adresse['Adresse'][Lang])}</label>
                                        <input readOnly maxLength={255} type="text" placeholder={(adresse['Adresse'][Lang])} onChange={(e) => setAdresse(e.target.value.replace(/[^a-zA-Z0-9\-\°\,\.\s]+/g, ''))} value={Adresse} className="form-control" id="inputEmail4" />
                                        {/*  */}

                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(adresse['Mobile'][Lang])}</label>
                                        <input readOnly maxlength="10" type="tel" placeholder={(adresse['Mobile'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setMobile(e.target.value.replace(/[^0-9]+/g, ''))} value={Mobile} />
                                        <span className='note-form'>{(adresse['msg2'][Lang])}</span>


                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(adresse['Mobile2'][Lang])}</label>
                                        <input readOnly maxlength="10" type="tel" placeholder={(adresse['Mobile2'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setMobile2(e.target.value.replace(/[^0-9]+/g, ''))} value={Mobile2} />
                                        <span className='note-form'>{(adresse['msg2'][Lang])}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{(adresse['email'][Lang])}</label>
                                        <input readOnly maxLength={255} type="email" placeholder={(adresse['email'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setMail(e.target.value.trim())} value={Mail.trim()} />


                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{(adresse['confirmemail'][Lang])}</label>
                                        <input readOnly maxLength={255} type="email" placeholder={(adresse['confirmemail'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setReMail(e.target.value.trim())} value={ReMail.trim()} />




                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(adresse['NIN'][Lang])} </label>
                                        <input readOnly maxlength="18" type="tel" placeholder={(adresse['NIN'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setNIN(e.target.value.replace(/[^0-9]+/g, ''))} value={NIN} />
                                        <span className='note-form'>{(adresse['msg3'][Lang])}</span>


                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(adresse['numcn'][Lang])}</label>
                                        <input readOnly maxlength="9" type="tel" placeholder={(adresse['numcn'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setNumerodecartenational(e.target.value.replace(/[^0-9]+/g, ''))} value={Numerodecartenational} />




                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{(adresse['Date_délivrence'][Lang])}</label>
                                        <input readOnly type="date" className="text-end form-control" id="inputEmail4" max={new Date().toISOString().slice(0, 10)} onChange={(e) => setDatededElivrence(e.target.value)} value={DatededElivrence} />
                                        {/* <span className='note-form'>Veuillez choisir une date dans le calendrier</span> */}

                                    </div>


                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">{(adresse['Wilaya_délivrence'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setWilayadedélivrence(e.target.value)}>
                                            <option disabled selected="selected">{(adresse['Wilaya'][Lang])}</option>
                                            <option selected={Wilayadedélivrence === "ADRAR"} value="ADRAR">(01) ADRAR</option>
                                            <option selected={Wilayadedélivrence === "CHLEF"} value="CHLEF">(02) CHLEF</option>
                                            <option selected={Wilayadedélivrence === "LAGHOUAT"} value="LAGHOUAT">(03) LAGHOUAT</option>
                                            <option selected={Wilayadedélivrence === "OUM EL BOUAGHI"} value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
                                            <option selected={Wilayadedélivrence === "BATNA"} value="BATNA">(05) BATNA</option>
                                            <option selected={Wilayadedélivrence === "BEJAIA"} value="BEJAIA">(06) BEJAIA</option>
                                            <option selected={Wilayadedélivrence === "BISKRA"} value="BISKRA">(07) BISKRA</option>
                                            <option selected={Wilayadedélivrence === "BECHAR"} value="BECHAR">(08) BECHAR</option>
                                            <option selected={Wilayadedélivrence === "BLIDA"} value="BLIDA">(09) BLIDA</option>
                                            <option selected={Wilayadedélivrence === "BOUIRA"} value="BOUIRA">(10) BOUIRA</option>
                                            <option selected={Wilayadedélivrence === "TAMANRASSET"} value="TAMANRASSET">(11) TAMANRASSET</option>
                                            <option selected={Wilayadedélivrence === "TEBESSA"} value="TEBESSA">(12) TEBESSA</option>
                                            <option selected={Wilayadedélivrence === "TLEMCEN"} value="TLEMCEN">(13) TLEMCEN</option>
                                            <option selected={Wilayadedélivrence === "TIARET"} value="TIARET">(14) TIARET</option>
                                            <option selected={Wilayadedélivrence === "TIZI OUZOU"} value="TIZI OUZOU">(15) TIZI-OUZOU</option>
                                            <option selected={Wilayadedélivrence === "ALGER"} value="ALGER">(16) ALGER</option>
                                            <option selected={Wilayadedélivrence === "DJELFA"} value="DJELFA">(17) DJELFA</option>
                                            <option selected={Wilayadedélivrence === "JIJEL"} value="JIJEL">(18) JIJEL</option>
                                            <option selected={Wilayadedélivrence === "SETIF"} value="SETIF">(19) SETIF</option>
                                            <option selected={Wilayadedélivrence === "SAIDA"} value="SAIDA">(20) SAIDA</option>
                                            <option selected={Wilayadedélivrence === "SKIKDA"} value="SKIKDA">(21) SKIKDA</option>
                                            <option selected={Wilayadedélivrence === "SIDI BEL ABBES"} value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
                                            <option selected={Wilayadedélivrence === "ANNABA"} value="ANNABA">(23) ANNABA</option>
                                            <option selected={Wilayadedélivrence === "GUELMA"} value="GUELMA">(24) GUELMA</option>
                                            <option selected={Wilayadedélivrence === "CONSTANTINE"} value="CONSTANTINE">(25) CONSTANTINE</option>
                                            <option selected={Wilayadedélivrence === "MEDEA"} value="MEDEA">(26) MEDEA</option>
                                            <option selected={Wilayadedélivrence === "MOSTAGANEM"} value="MOSTAGANEM">(27) MOSTAGANEM</option>
                                            <option selected={Wilayadedélivrence === "M SILA"} value="M SILA">(28) M-SILA</option>
                                            <option selected={Wilayadedélivrence === "MASCARA"} value="MASCARA">(29) MASCARA</option>
                                            <option selected={Wilayadedélivrence === "OUARGLA"} value="OUARGLA">(30) OUARGLA</option>
                                            <option selected={Wilayadedélivrence === "ORAN"} value="ORAN">(31) ORAN</option>
                                            <option selected={Wilayadedélivrence === "EL BAYADH"} value="EL BAYADH">(32) EL-BAYADH</option>
                                            <option selected={Wilayadedélivrence === "ILLIZI"} value="ILLIZI">(33) ILLIZI</option>
                                            <option selected={Wilayadedélivrence === "BORDJ BOU ARRERIDJ"} value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
                                            <option selected={Wilayadedélivrence === "BOUMERDES"} value="BOUMERDES">(35) BOUMERDES</option>
                                            <option selected={Wilayadedélivrence === "EL TARF"} value="EL TARF">(36) EL-TARF</option>
                                            <option selected={Wilayadedélivrence === "TINDOUF"} value="TINDOUF">(37) TINDOUF</option>
                                            <option selected={Wilayadedélivrence === "TISSEMSILT"} value="TISSEMSILT">(38) TISSEMSILT</option>
                                            <option selected={Wilayadedélivrence === "EL OUED"} value="EL OUED">(39) EL-OUED</option>
                                            <option selected={Wilayadedélivrence === "KHENCHELA"} value="KHENCHELA">(40) KHENCHELA</option>
                                            <option selected={Wilayadedélivrence === "SOUK AHRAS"} value="SOUK AHRAS">(41) SOUK-AHRAS</option>
                                            <option selected={Wilayadedélivrence === "TIPAZA"} value="TIPAZA">(42) TIPAZA</option>
                                            <option selected={Wilayadedélivrence === "MILA"} value="MILA">(43) MILA</option>
                                            <option selected={Wilayadedélivrence === "AIN DEFLA"} value="AIN DEFLA">(44) AIN-DEFLA</option>
                                            <option selected={Wilayadedélivrence === "NAAMA"} value="NAAMA">(45) NAAMA</option>
                                            <option selected={Wilayadedélivrence === "AIN TEMOUCHENT"} value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
                                            <option selected={Wilayadedélivrence === "GHARDAIA"} value="GHARDAIA">(47) GHARDAIA</option>
                                            <option selected={Wilayadedélivrence === "RELIZANE"} value="RELIZANE">(48) RELIZANE</option>
                                            {/* <option selected={Wilayadedélivrence === "Timimoun"} value="Timimoun">(49) Timimoun</option>
        <option selected={Wilayadedélivrence === "Bordj Badji Mokhtar"} value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
        <option selected={Wilayadedélivrence === "Ouled Djellal"} value="Ouled Djellal">(51) Ouled Djellal</option>
        <option selected={Wilayadedélivrence === "Béni Abbès"} value="Béni Abbès">(52) Béni Abbès</option>
        <option selected={Wilayadedélivrence === "In Salah"} value="In Salah">(53) In Salah</option>
        <option selected={Wilayadedélivrence === "In Guezzam"} value="In Guezzam">(54) In Guezzam</option>
        <option selected={Wilayadedélivrence === "Touggourt"} value="Touggourt">(55) Touggourt</option>
        <option selected={Wilayadedélivrence === "Djanet"} value="Djanet">(56) Djanet</option>
        <option selected={Wilayadedélivrence === "El M'Ghair"} value="El M'Ghair">(57) El M'Ghair</option>
        <option selected={Wilayadedélivrence === "El Meniaa"} value="El Meniaa">(58) El Meniaa</option> */}
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(adresse['Commune_délivrence'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setCommunededélivrence(e.target.value)}>

                                            <option disabled selected={Communededélivrence == '' ? "selected" : ''}>{(adresse['Commune_délivrence'][Lang])}</option>

                                            {CommunededélivrenceList.length > 0 && CommunededélivrenceList.map((item, i) => (
                                                <option selected={Communededélivrence === item?.commune} value={item?.commune}>{item?.commune}</option>
                                            ))
                                            }
                                        </select>

                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(adresse['Habitation'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setHabitation(e.target.value)}>
                                            <option value={0} disabled selected="selected">{(adresse['Habitation'][Lang])}</option>

                                            {adresse['Habitation_list']?.length > 0 && adresse['Habitation_list']?.map((item, i) => (
                                                <option selected={Habitation == item['fr']} value={item['fr']}>{item[Lang]}</option>
                                            ))
                                            }
                                            {/* <option selected={Habitation == 1} value={1}>Avec les parents</option>
        <option selected={Habitation == 2} value={2}>Locataire</option>
        <option selected={Habitation == 3} value={3}>Propriétaire</option>
        <option selected={Habitation == 4} value={4}>Logt de Fonction</option> */}
                                        </select>


                                    </div>






                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">ما هو النشاط الذي تمارسه ؟</label>
                                        {/* <input readOnly  maxLength={300} type="text" placeholder="ما هو النشاط الذي تمارسه ؟" className="form-control" id="inputEmail4" onChange={(e) => setactivité(e.target.value)} value={activité} /> */}
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setactivité(e.target.value)}>
                                            <option disabled selected="selected">نوع النشاط</option>

                                            {/* {projects?.length > 0 && projects?.map((item, i) => (
                                                <option selected={activité == item?.name} value={item?.name}>{item?.name}</option>
                                            ))
                                            } */}

                                            {/* {ProjectsList?.length > 0 && ProjectsList?.map((item, i) => (
                                                <option selected={activité == `${item?.title} - (${item?.cat_name})`} value={`${item?.title} - (${item?.cat_name})`}>{item?.title} - ({item?.cat_name})</option>
                                            ))
                                            } */}

                                            {ProjectsList?.length > 0 && ProjectsList?.map((item, i) => (
                                                <option selected={activité == item?.id} value={item?.id}>{item?.title} - ({item?.cat_name})</option>
                                            ))
                                            }
                                        </select>

                                        {
                                            GetProjectUrl(activité) ?

                                                <a className='url-project-plus mt-2' href={`https://www.iamal.com.dz/project/${GetProjectUrl(activité)}`} target='blank'>
                                                    <svg className='ms-3' xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                        <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                            <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                            </g>
                                                            <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                            </g>
                                                            <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <div>
                                                        التفاصيل والبطاقة التقنية للمشروع
                                                    </div>
                                                </a> : ''}


                                    </div>


                                    <div className="col-md-6 mb-1 mt-2 m-auto">
                                    <div className="mt-1 d-flex flex-column justify-content-center text-center m-auto mb-1">
                            {
                                ProjectPrice() || GetTotalPriceEquipments() ?
                                <>
<h5 className='text-center' >الدفع الشهري الإجمالي</h5>
                                    <div className="product-price m-auto" dir='ltr' >
                                        {(Lang === 'ar' ? <> <span className="me-2"> دج / للشهر  </span>  {GetTaksitPrice((parseInt(ProjectPrice()) > 0 ? TTC(parseInt(ProjectPrice())) : 0) + GetTotalPriceEquipments() , '60')['getallprice']?.toLocaleString()} </> : <>{GetTaksitPrice(parseInt(ProjectPrice()))['60']?.toLocaleString()} DA <span>/ mois</span></>)}
                                    </div> 
                                    </>
                                    : ''}
                            {/* <div className="mt-4 mb-4 text-center">
                                <a className="alsalam-downlaod-btn mt-5" onClick={() => PaymentStatus(Product['id'])}> <b> طلب الإستفادة من المشروع</b></a>
                            </div> */}

                            {
                                ProjectPrice() || GetTotalPriceEquipments() ?
                                    <div className='product-taksit-bank m-auto text-center'>
                                        <div className='product-taksit-list-price mt-1' dir={Lang === "ar" ? 'rtl' : ''}>
                                            <p>{GetTaksitPrice((parseInt(ProjectPrice()) > 0 ? TTC(parseInt(ProjectPrice())) : 0) + GetTotalPriceEquipments(), '60')['getallprice']?.toLocaleString('fr')} {(ProductLang.gettaksit(('60'))[Lang])}</p>
                                            {/* <p>{GetTaksitPrice((parseInt(ProjectPrice()) > 0 ? TTC(parseInt(ProjectPrice())) : 0) + GetTotalPriceEquipments(), '48')['getallprice']?.toLocaleString('fr')} {(ProductLang.gettaksit(('48'))[Lang])}</p>
                                            <p>{GetTaksitPrice((parseInt(ProjectPrice()) > 0 ? TTC(parseInt(ProjectPrice())) : 0) + GetTotalPriceEquipments(), '36')['getallprice']?.toLocaleString('fr')} {(ProductLang.gettaksit(('36'))[Lang])}</p>
                                            <p>{GetTaksitPrice((parseInt(ProjectPrice()) > 0 ? TTC(parseInt(ProjectPrice())) : 0) + GetTotalPriceEquipments(), '24')['getallprice']?.toLocaleString('fr')} {(ProductLang.gettaksit(('24'))[Lang])}</p>
                                            <p>{GetTaksitPrice((parseInt(ProjectPrice()) > 0 ? TTC(parseInt(ProjectPrice())) : 0) + GetTotalPriceEquipments(), '12')['getallprice']?.toLocaleString('fr')} {(ProductLang.gettaksit(('12'))[Lang])}</p> */}
                                        </div>
                                    </div> : ''}
                        </div>

                                    </div>
                                    

{ClientEquipments?.length > 0 ? <h5 className='text-center mt-2 pt-2'>المعدات</h5>: ''}
                                    <div className='mt-1 d-flex justify-content-between align-items-end flex-wrap-reverse'>

<div dir='ltr' className='equipments-list-box mt-1 mb-5 m-auto mb-3'>


    {ClientEquipments?.length > 0 ? ClientEquipments?.map((item, i) => (
        // <option selected={item?.id_cat == Catégorie ? 'selected' : ''} value={item?.id_cat}>{item?.cat_name}</option>

        <FormProjectEquipmentsAlsalam item={item} EquipmentCart={ClientEquipments} projectPrice={ProjectPrice()} />
    )) : ''
    }
</div>


</div>

                                    

                                    <div className="col-md-6 mb-2 mt-5 m-auto">
                                        <label className="form-label justify-content-center">ما هي مدة سداد قرض المشروع ؟</label>
                                        <div className='remboursement-box' dir={Lang === 'ar' ? 'rtl' : ''}>
                                            <div className={`remb-box ${Time == '12' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>
                                                12 {(dartaksitsim['duréeMois'][Lang])}</div>
                                            <div className={`remb-box ${Time == '24' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>24 {(dartaksitsim['duréeMois'][Lang])}</div>
                                            <div className={`remb-box ${Time == '36' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>36 {(dartaksitsim['duréeMois'][Lang])}</div>


                                            <div className={`remb-box ${Time == '48' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>48 {(dartaksitsim['duréeMois'][Lang])}</div>
                                            <div className={`remb-box ${Time == '60' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>60 {(dartaksitsim['duréeMois'][Lang])}</div>


                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="inputEmail4" className="form-label">{(situationprofessionnelle['secteuractiv'][Lang])}</label>
                                        <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setSecteurdactivité(e.target.value)}>
                                            <option value={0} disabled selected="selected">{(situationprofessionnelle['secteuractiv'][Lang])}</option>


                                            {situationprofessionnelle['secteuractiv_list']?.length > 0 && situationprofessionnelle['secteuractiv_list']?.map((item, i) => (
                                                <option selected={Secteurdactivité == item?.value} value={item?.value}>{item[Lang]}</option>
                                            ))
                                            }
                                        </select>
                                        {/* <div className='alert-form'>{Showerr && (Secteurdactivité !== "p" && Secteurdactivité !== "r" && Secteurdactivité !== "i" && Secteurdactivité !== "v" && Secteurdactivité !== "f" && Secteurdactivité !== "c" ? (situationprofessionnelle['secteuractiv_err'][Lang]) : '')}</div> */}


                                    </div>


                                    <div className={'mt-2'}>

                                        {/* {Secteurdactivité == 'p' || Secteurdactivité == 'v' || Secteurdactivité == 'i' ?
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="inputEmail4" className="form-label">{(situationprofessionnelle['Profession'][Lang])}</label>
                                                <input type="text" placeholder={(situationprofessionnelle['Profession'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setProfession(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Profession} />
                                                <span className='note-form'>{Global['latin'][Lang]}</span>
                                                <div className='alert-form'>{Showerr && (Profession.trim().length < 2 ? (situationprofessionnelle['msgerr1'][Lang]) : '')}</div>
                                            </div> 
                                            
                                            : ''} */}

                                        {Secteurdactivité == 'p' || Secteurdactivité == 'v' ?
                                            <>
                                                <div className="col-md-12 mb-4">
                                                    <label htmlFor="inputPassword4" className="form-label">{(situationprofessionnelle['tyoe_contrat'][Lang])}</label>
                                                    <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setTypedecontrat(e.target.value)}>
                                                        <option value="0" selected="selected">{(situationprofessionnelle['tyoe_contrat'][Lang])}</option>
                                                        <option selected={Typedecontrat === "CDI"} value="CDI">CDI</option>
                                                        <option selected={Typedecontrat === "CDD"} value="CDD">CDD </option>
                                                        <option selected={Typedecontrat === "INTERIM"} value="INTERIM">Intérim</option>
                                                        <option selected={Typedecontrat === "Autre"} value="Autre">Autre</option>
                                                    </select>
                                                    {/* <div className='alert-form'>{Showerr && (Typedecontrat !== "CDD" && Typedecontrat !== "CDI" && Typedecontrat !== "INTERIM" && Typedecontrat !== "Autre" ? (situationprofessionnelle['msgerr12'][Lang]) : '')}</div> */}
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <div className='mb-2'>{(situationprofessionnelle['période_essai'][Lang])}</div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" disabled type="radio" name="inlineRadioOptions33" id="inlineRadio1999" checked={Périodedessai == 'oui'} onChange={(e) => setPériodedessai(e.target.value)} defaultValue={'oui'} />
                                                        <label className="form-check-label opacity-100" htmlFor="inlineRadio1999">{(situationprofessionnelle['r3'][Lang])}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" disabled type="radio" name="inlineRadioOptions3399" id="inlineRadio2999" checked={Périodedessai == 'non'} onChange={(e) => setPériodedessai(e.target.value)} defaultValue={'non'} />
                                                        <label className="form-check-label opacity-100" htmlFor="inlineRadio2999">{(situationprofessionnelle['r4'][Lang])}</label>
                                                    </div>
                                                </div>
                                                <label htmlFor="inputPassword4" className="form-label">{(situationprofessionnelle['Ancienneté'][Lang])}</label>
                                                <div className="col-md-12 d-flex">
                                                    <input readOnly disabled type="date" onChange={(e) => setAncienneté(e.target.value)} defaultValue={Ancienneté} className="form-control" id="inputEmail4" />
                                                </div>
                                                {/* <div className='alert-form mb-4'>{Showerr && (!dateIsValid(new Date(Ancienneté)) ? "يرجى تحديد منذ متى وأنت تعمل" : '')}</div> */}

                                                <div className="col-md-12 mb-4">
                                                    <label htmlFor="inputEmail4" className="form-label">{(situationprofessionnelle['nom_emp'][Lang])}</label>
                                                    <input readOnly disabled type="text" placeholder={(situationprofessionnelle['nom_emp'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setNomdelemployeur(e.target.value)} value={Nomdelemployeur} />
                                                    {/* <span className='note-form'>{Global['latin'][Lang]}</span> */}

                                                    {/* <div className='alert-form'>{Showerr && (Nomdelemployeur?.toString().trim() == '' ? (situationprofessionnelle['msgerr15'][Lang]) : '')}</div> */}
                                                    {/* <div className='alert-form'>{Showerr && (!onlyLetters(Nomdelemployeur) ? (situationprofessionnelle['msgerr15_'][Lang]) : '')}</div> */}
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="inputPassword4" className="form-label">{(situationprofessionnelle['wilaya_emp'][Lang])}</label>
                                                    <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setWilayadelemployeur(e.target.value)}>
                                                        <option disabled selected="selected">{(adresse['Wilaya'][Lang])}</option>
                                                        <option selected={Wilayadelemployeur == "ADRAR"} value="ADRAR">(01) ADRAR</option>
                                                        <option selected={Wilayadelemployeur == "CHLEF"} value="CHLEF">(02) CHLEF</option>
                                                        <option selected={Wilayadelemployeur == "LAGHOUAT"} value="LAGHOUAT">(03) LAGHOUAT</option>
                                                        <option selected={Wilayadelemployeur == "OUM EL BOUAGHI"} value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
                                                        <option selected={Wilayadelemployeur == "BATNA"} value="BATNA">(05) BATNA</option>
                                                        <option selected={Wilayadelemployeur == "BEJAIA"} value="BEJAIA">(06) BEJAIA</option>
                                                        <option selected={Wilayadelemployeur == "BISKRA"} value="BISKRA">(07) BISKRA</option>
                                                        <option selected={Wilayadelemployeur == "BECHAR"} value="BECHAR">(08) BECHAR</option>
                                                        <option selected={Wilayadelemployeur == "BLIDA"} value="BLIDA">(09) BLIDA</option>
                                                        <option selected={Wilayadelemployeur == "BOUIRA"} value="BOUIRA">(10) BOUIRA</option>
                                                        <option selected={Wilayadelemployeur == "TAMANRASSET"} value="TAMANRASSET">(11) TAMANRASSET</option>
                                                        <option selected={Wilayadelemployeur == "TEBESSA"} value="TEBESSA">(12) TEBESSA</option>
                                                        <option selected={Wilayadelemployeur == "TLEMCEN"} value="TLEMCEN">(13) TLEMCEN</option>
                                                        <option selected={Wilayadelemployeur == "TIARET"} value="TIARET">(14) TIARET</option>
                                                        <option selected={Wilayadelemployeur == "TIZI OUZOU"} value="TIZI OUZOU">(15) TIZI-OUZOU</option>
                                                        <option selected={Wilayadelemployeur == "ALGER"} value="ALGER">(16) ALGER</option>
                                                        <option selected={Wilayadelemployeur == "DJELFA"} value="DJELFA">(17) DJELFA</option>
                                                        <option selected={Wilayadelemployeur == "JIJEL"} value="JIJEL">(18) JIJEL</option>
                                                        <option selected={Wilayadelemployeur == "SETIF"} value="SETIF">(19) SETIF</option>
                                                        <option selected={Wilayadelemployeur == "SAIDA"} value="SAIDA">(20) SAIDA</option>
                                                        <option selected={Wilayadelemployeur == "SKIKDA"} value="SKIKDA">(21) SKIKDA</option>
                                                        <option selected={Wilayadelemployeur == "SIDI BEL ABBES"} value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
                                                        <option selected={Wilayadelemployeur == "ANNABA"} value="ANNABA">(23) ANNABA</option>
                                                        <option selected={Wilayadelemployeur == "GUELMA"} value="GUELMA">(24) GUELMA</option>
                                                        <option selected={Wilayadelemployeur == "CONSTANTINE"} value="CONSTANTINE">(25) CONSTANTINE</option>
                                                        <option selected={Wilayadelemployeur == "MEDEA"} value="MEDEA">(26) MEDEA</option>
                                                        <option selected={Wilayadelemployeur == "MOSTAGANEM"} value="MOSTAGANEM">(27) MOSTAGANEM</option>
                                                        <option selected={Wilayadelemployeur == "M SILA"} value="M SILA">(28) M-SILA</option>
                                                        <option selected={Wilayadelemployeur == "MASCARA"} value="MASCARA">(29) MASCARA</option>
                                                        <option selected={Wilayadelemployeur == "OUARGLA"} value="OUARGLA">(30) OUARGLA</option>
                                                        <option selected={Wilayadelemployeur == "ORAN"} value="ORAN">(31) ORAN</option>
                                                        <option selected={Wilayadelemployeur == "EL BAYADH"} value="EL BAYADH">(32) EL-BAYADH</option>
                                                        <option selected={Wilayadelemployeur == "ILLIZI"} value="ILLIZI">(33) ILLIZI</option>
                                                        <option selected={Wilayadelemployeur == "BORDJ BOU ARRERIDJ"} value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
                                                        <option selected={Wilayadelemployeur == "BOUMERDES"} value="BOUMERDES">(35) BOUMERDES</option>
                                                        <option selected={Wilayadelemployeur == "EL TARF"} value="EL TARF">(36) EL-TARF</option>
                                                        <option selected={Wilayadelemployeur == "TINDOUF"} value="TINDOUF">(37) TINDOUF</option>
                                                        <option selected={Wilayadelemployeur == "TISSEMSILT"} value="TISSEMSILT">(38) TISSEMSILT</option>
                                                        <option selected={Wilayadelemployeur == "EL OUED"} value="EL OUED">(39) EL-OUED</option>
                                                        <option selected={Wilayadelemployeur == "KHENCHELA"} value="KHENCHELA">(40) KHENCHELA</option>
                                                        <option selected={Wilayadelemployeur == "SOUK AHRAS"} value="SOUK AHRAS">(41) SOUK-AHRAS</option>
                                                        <option selected={Wilayadelemployeur == "TIPAZA"} value="TIPAZA">(42) TIPAZA</option>
                                                        <option selected={Wilayadelemployeur == "MILA"} value="MILA">(43) MILA</option>
                                                        <option selected={Wilayadelemployeur == "AIN DEFLA"} value="AIN DEFLA">(44) AIN-DEFLA</option>
                                                        <option selected={Wilayadelemployeur == "NAAMA"} value="NAAMA">(45) NAAMA</option>
                                                        <option selected={Wilayadelemployeur == "AIN TEMOUCHENT"} value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
                                                        <option selected={Wilayadelemployeur == "GHARDAIA"} value="GHARDAIA">(47) GHARDAIA</option>
                                                        <option selected={Wilayadelemployeur == "RELIZANE"} value="RELIZANE">(48) RELIZANE</option>
                                                        {/* <option selected={ Wilayadelemployeur == "Timimoun"} value="Timimoun">(49) Timimoun</option>
                  <option selected={ Wilayadelemployeur == "Bordj Badji Mokhtar"} value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
                  <option selected={ Wilayadelemployeur == "Ouled Djellal"} value="Ouled Djellal">(51) Ouled Djellal</option>
                  <option selected={ Wilayadelemployeur == "Béni Abbès"} value="Béni Abbès">(52) Béni Abbès</option>
                  <option selected={ Wilayadelemployeur == "In Salah"} value="In Salah">(53) In Salah</option>
                  <option selected={ Wilayadelemployeur == "In Guezzam"} value="In Guezzam">(54) In Guezzam</option>
                  <option selected={ Wilayadelemployeur == "Touggourt"} value="Touggourt">(55) Touggourt</option>
                  <option selected={ Wilayadelemployeur == "Djanet"} value="Djanet">(56) Djanet</option>
                  <option selected={ Wilayadelemployeur == "El M'Ghair"} value="El M'Ghair">(57) El M'Ghair</option>
                  <option selected={ Wilayadelemployeur == "El Meniaa"} value="El Meniaa">(58) El Meniaa</option> */}
                                                    </select>
                                                    {/* <div className='alert-form'>{Showerr && (Wilayadelemployeur == "" ? (situationprofessionnelle['msgerr16'][Lang]) : '')}</div> */}
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="inputEmail4" className="form-label">{(situationprofessionnelle['adresse_emp'][Lang])}</label>
                                                    <input readOnly disabled type="text" placeholder={(situationprofessionnelle['adresse_emp'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setAdressedelemployeur(e.target.value)} value={Adressedelemployeur} />
                                                    {/* <span className='note-form'>{Global['latin'][Lang]}</span> */}

                                                    {/* <div className='alert-form'>{Showerr && (Adressedelemployeur?.toString().trim() == "" || Adressedelemployeur?.toString().trim().length > 300 ? (situationprofessionnelle['msgerr17'][Lang]) : '')}</div> */}
                                                </div>




                                            </> : ''}

                                        {/* {Secteurdactivité == 'p' || Secteurdactivité == 'v' || Secteurdactivité == 'i' ?
            <div className="col-md-12 mb-3">
              <label htmlFor="inputEmail4" className="form-label">{(situationprofessionnelle['tel_prof'][Lang])}</label>
              <input type="text" placeholder="Téléphone professionnel" className="form-control" id="inputEmail4" onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} />
              <div className='alert-form'>{Showerr && (!onlyNumbers(Tel) ? ' Veuillez saisir votre nom sans chiffres ni caractères spéciaux.' : '')}</div>
              <div className='alert-form'>{Showerr && (Tel.length > 13 || Tel.length < 9 ? 'Votre numéro de téléphone ne doit pas être inférieur à 9 chiffres ni supérieur à 13 chiffres' : '')}</div>

            </div> : ''} */}
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(situationprofessionnelle['Profession'][Lang])} (إختياري)</label>
                                        <input readOnly type="text" placeholder={(situationprofessionnelle['Profession'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setProfession(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Profession} />



                                    </div>

                                    {/* <div className="col-md-12 mb-3">
                                        <label className="form-label">{(situationprofessionnelle['adresse_emp'][Lang])} (إختياري)</label>
                                        <input readOnly type="text" placeholder={(situationprofessionnelle['adresse_emp'][Lang])} className="form-control" id="inputEmail4" onChange={(e) => setAdressedelemployeur(e.target.value.replace(/[^a-zA-Z0-9\s]+/g, ''))} value={Adressedelemployeur} />



                                    </div> */}





                                    <div className="col-md-12 mb-2 mt-5" dir='rtl'>
                                        <div className='mb-2'>هل انت حامل لشهادة؟</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions19" id="inlineRadio19" checked={Certificat == 'oui'} onChange={(e) => setCertificat(e.target.value)} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio19">{(situationprofessionnelle['r3'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions29" id="inlineRadio29" checked={Certificat == 'non'} onChange={(e) => setCertificat(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio29">{(situationprofessionnelle['r4'][Lang])}</label>
                                        </div>
                                        {Certificat === "oui" &&
                                            <>
                                                <div className="col-md-12 mb-1">
                                                    <label htmlFor="inputPassword4" className="form-label">نوع الشهادة</label>
                                                    <input readOnly type="text" placeholder='نوع الشهادة' maxLength={250} className="form-control" onChange={(e) => setTypeCertificat(e.target.value)} value={TypeCertificat} id="inputPassword4" />

                                                </div>
                                            </>}
                                    </div>



                                    <div className="col-md-12 mb-2" dir='rtl'>
                                        <div className='mb-2'>هل لديك خبرة في هذا المجال؟</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions18" id="inlineRadio18" checked={Experience == 'oui'} onChange={(e) => setExperience(e.target.value)} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio18">{(situationprofessionnelle['r3'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions28" id="inlineRadio28" checked={Experience == 'non'} onChange={(e) => setExperience(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio28">{(situationprofessionnelle['r4'][Lang])}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">{(situationprofessionnelle['saliare'][Lang])} (إختياري)</label>
                                        <input readOnly maxlength="7" type="tel" placeholder={(situationprofessionnelle['saliare'][Lang])} className="form-control" onChange={(e) => setSalaire(e.target.value.replace(/[^0-9]+/g, ''))} value={Salaire} id="inputEmail4" />

                                        {/* <div>{situationprofessionnelle['saliare_info'][Lang]} </div> */}

                                    </div>
                                    <div className="col-md-12 mb-2" dir='rtl'>
                                        <div className='mb-2'>{(situationprofessionnelle['cmp'][Lang])}</div>
                                        {/* <div className="form-check form-check-inline">
                <input readOnly  className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions" id="inlineRadio1" defaultChecked defaultValue="option1" />
                <label className="form-check-label opacity-100" htmlFor="inlineRadio1">CCP</label>
            </div>
            <div className="form-check form-check-inline">
                <input readOnly  className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                <label className="form-check-label opacity-100" htmlFor="inlineRadio2">Banque</label>
            </div> */}

                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions331" id="inlineRadio331" checked={Compte == 'Non'} onChange={(e) => setCompte(e.target.value)} defaultChecked={true} defaultValue="Non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio331">لا يوجد</label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions131" id="inlineRadio131" checked={Compte == 'CCP'} onChange={(e) => setCompte(e.target.value)} defaultValue="CCP" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio131">{(situationprofessionnelle['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions231" id="inlineRadio231" checked={Compte == 'Banque'} onChange={(e) => setCompte(e.target.value)} defaultValue="Banque" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio231">{(situationprofessionnelle['r2'][Lang])}</label>
                                        </div>

                                    </div>

                                    {Compte == 'CCP' ?
                                        <>
                                            {/* <label htmlFor="inputPassword4" className="form-label">Compte CCP</label> */}
                                            <div className="col-md-12 d-flex mt-0" dir='rtl'>
                                                <div className='row'>
                                                    <div className="col-md-6 col-12" dir='rtl'>
                                                        <input readOnly maxlength="18" type="tel" placeholder="Compte" onChange={(e) => setCompteCCP(e.target.value.replace(/[^0-9]+/g, ''))} value={CompteCCP} className="form-control ms-2" id="inputPassword4" />


                                                    </div>
                                                    <div className='col-md-5 col-12 me-0 me-md-3' dir='rtl'>
                                                        <input readOnly maxlength="2" type="tel" placeholder="Clé" onChange={(e) => setCCPClé(e.target.value.replace(/[^0-9]+/g, ''))} value={CCPClé} className="form-control ms-2" id="inputPassword4" />



                                                    </div></div>
                                            </div>
                                        </> : ''}

                                    {Compte == 'Banque' ?
                                        <div className="col-md-12 mt-1 mb-3">
                                            {/* <label htmlFor="inputPassword4" className="form-label">Banque</label> */}
                                            <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setBanque(e.target.value)}>
                                                <option disabled selected="selected">Choisissez</option>
                                                <option selected={Banque == "AL-SALAM"} value="AL-SALAM">Al salam Bank Algeria</option>
                                                <option selected={Banque == "BEA"} value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                                <option selected={Banque == "BNA"} value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                                <option selected={Banque == "CPA"} value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                                <option selected={Banque == "BDL"} value="BDL">BDL (Banque de Développement Local)</option>
                                                <option selected={Banque == "CNEP"} value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                                <option selected={Banque == "BADR"} value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                                <option selected={Banque == "AL-BARAKA"} value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                                <option selected={Banque == "CITIBANK"} value="CITIBANK">CITIBANK</option>
                                                <option selected={Banque == "ABC"} value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                                <option selected={Banque == "NATIXIS"} value="NATIXIS">Natixis ALGERIE</option>
                                                <option selected={Banque == "SGA"} value="SGA"> Société Générale Algérie</option>
                                                <option selected={Banque == "BNP"} value="BNP"> BNP Paribas Al-djazair</option>
                                                <option selected={Banque == "TRUST-BANK"} value="TRUST-BANK"> TRUST BANK Algeria</option>
                                                <option selected={Banque == "Housing-Bank"} value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                                <option selected={Banque == "AGB"} value="AGB"> AGB (Gulf Bank Algérie)</option>
                                                <option selected={Banque == "FRANSABANK"} value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                                <option selected={Banque == "FRANSABANK"} value="FRANSABANK">Fransabank Al-Djazair</option>
                                                <option selected={Banque == "HSBC"} value="HSBC"> HSBC Algeria</option>
                                            </select>

                                        </div> : ''
                                    }





                                    <div className="col-md-12 mb-2" dir='rtl'>
                                        <div className='mb-2'>{(situationprofessionnelle['Comptesalam'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions190" id="inlineRadio190" checked={SalamBank == 'oui'} onChange={(e) => setSalamBank(e.target.value)} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio190">{(situationprofessionnelle['r3'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions290" id="inlineRadio290" checked={SalamBank == 'non'} onChange={(e) => setSalamBank(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio290">{(situationprofessionnelle['r4'][Lang])}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2" dir='rtl'>
                                        <div className='mb-2'>{(situationprofessionnelle['revenus'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions180" id="inlineRadio180" checked={sourcesderevenus == 'oui'} onChange={(e) => setsourcesderevenus(e.target.value)} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio180">{(situationprofessionnelle['r3'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions280" id="inlineRadio280" checked={sourcesderevenus == 'non'} onChange={(e) => setsourcesderevenus(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio280">{(situationprofessionnelle['r4'][Lang])}</label>
                                        </div>
                                    </div>

                                    

                                    <div className="col-md-12" dir='rtl'>
                                        <div className='mb-2'>
                                            أنت شخص ؟
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions1991" id="inlineRadio1991" checked={TypeOfPerson == 0} onChange={(e) => setTypeOfPerson(e.target.value)} defaultChecked={true} defaultValue="0" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio1991">
                                                شخص طبيعي</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions2992" id="inlineRadio2992" checked={TypeOfPerson == 1} onChange={(e) => setTypeOfPerson(e.target.value)} defaultValue="1" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio2992">
                                                شخص معنوي
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions2993" id="inlineRadio2993" checked={TypeOfPerson == 2} onChange={(e) => setTypeOfPerson(e.target.value)} defaultValue="2" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio2993">
                                                حرفي
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions2994" id="inlineRadio2994" checked={TypeOfPerson == 3} onChange={(e) => setTypeOfPerson(e.target.value)} defaultValue="3" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio2994">
                                                تاجر متنقل
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions2995" id="inlineRadio2995" checked={TypeOfPerson == 4} onChange={(e) => setTypeOfPerson(e.target.value)} defaultValue="4" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio2995">
                                                شركة ناشئة
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">تاريخ بداية النشاط</label>
                                        <input readOnly disabled type="date" onChange={(e) => setDatedébutdactivité(e.target.value)} defaultValue={Datedébutdactivité} className="form-control" id="inputEmail4" />
                                        {/* <div className='alert-form'>{Showerr && (Datedébutdactivité !== "" && !dateIsValid(new Date(Datedébutdactivité)) ? 'يرجى تحديد تاريخ بداية النشاط' : '')}</div> */}
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">رقم التسجيل في السجل التجاري </label>

                                        <input readOnly disabled type="tel" dir="rtl" placeholder={'رقم التسجيل في السجل التجاري'} className="form-control" id="inputEmail4" onChange={(e) => setImmatriculationauregistreCommerce(e.target.value)} value={LEFT_TO_RIGHT_MARK +  ImmatriculationauregistreCommerce} />
                                        {/* <span className='note-form'>{(adresse['msg3'][Lang])}</span> */}
                                        {/* <div className='alert-form'>{Showerr && (ImmatriculationauregistreCommerce !== "" && !onlyNumbers(ImmatriculationauregistreCommerce) ? "يرجى إدخال رقم التسجيل في السجل التجاري" : '')}</div> */}
                                        {/* <div className='alert-form'>{Showerr && (ImmatriculationauregistreCommerce.length !== 18 ? (adresse['msgerr11'][Lang]) : '')}</div> */}
                                    </div>


                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">
                                            تاريخ التسجيل في السجل التجاري
                                        </label>
                                        <input readOnly disabled type="date" onChange={(e) => setDateImmatriculationauregistreCommerce(e.target.value)} defaultValue={DateImmatriculationauregistreCommerce} className="form-control" id="inputEmail4" />
                                        {/* <div className='alert-form'>{Showerr && (DateImmatriculationauregistreCommerce !== "" && !dateIsValid(new Date(DateImmatriculationauregistreCommerce)) ? "يرجى تحديد تاريخ التسجيل في السجل التجاري" : '')}</div> */}
                                    </div>


                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">
                                            مكان التسجيل في السجل التجاري
                                        </label>
                                        <input readOnly disabled maxLength={255} type="text" placeholder={"مكان التسجيل في السجل التجاري"} onChange={(e) => setLieuImmatriculationauregistreCommerce(e.target.value.replace(/[^a-zA-Z0-9\-/\°\,\.\'\s]+/g, ''))} value={LieuImmatriculationauregistreCommerce} className="form-control" id="inputEmail4" />
                                        {/* <span className='note-form'>الكتابة بالأحرف الاتينية</span> */}
                                        {/* <div className='alert-form'>{Showerr && (LieuImmatriculationauregistreCommerce?.toString().trim().length > 300 ? "يرجى عدم تجاوز عدد الأحرف المقدرة بـ 300 حرف" : '')}</div> */}
                                    </div>


                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">
                                            عنوان مقر المؤسسة
                                        </label>
                                        <input readOnly disabled maxLength={255} type="text" placeholder={"عنوان مقر المؤسسة"} onChange={(e) => setAdressedusiège(e.target.value.replace(/[^a-zA-Z0-9\-/\°\,\.\'\s]+/g, ''))} value={Adressedusiège} className="form-control" id="inputEmail4" />
                                        {/* <span className='note-form'>الكتابة بالأحرف الاتينية</span> */}
                                        {/* <div className='alert-form'>{Showerr && (Adressedusiège?.toString().trim().length > 300 ? "يرجى عدم تجاوز عدد الأحرف المقدرة بـ 300 حرف" : '')}</div> */}
                                    </div>


                                    <div className="col-md-12" dir='rtl'>
                                        <div className='mb-2'>
                                            وضعية المقر النشاط
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions19918" id="inlineRadio19918" checked={Situationdulocal == "0"} onChange={(e) => setSituationdulocal(e.target.value)} defaultChecked={true} defaultValue="0" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio19918">
                                                إيجار
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions29928" id="inlineRadio29928" checked={Situationdulocal == "1"} onChange={(e) => setSituationdulocal(e.target.value)} defaultValue="1" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio29928">
                                                ملكية
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" disabled type="radio" name="inlineRadioOptions29929" id="inlineRadio29929" checked={Situationdulocal == "2"} onChange={(e) => setSituationdulocal(e.target.value)} defaultValue="2" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio29929">
                                                لايوجد
                                            </label>
                                        </div>
                                    </div>








                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q1'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadio18" id="inlineRadio1899" checked={créditempruntimmobilier == 'oui'} onChange={(e) => setcréditempruntimmobilier(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio1899">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadio18" id="inlineRadio2999" checked={créditempruntimmobilier == 'non'} onChange={(e) => setcréditempruntimmobilier(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio2999">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>

                                    {
                                        créditempruntimmobilier === "oui" &&
                                        <>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q1Mensualité'][Lang])}</label>
                                                <input readOnly placeholder='DA/دج' maxLength={10} type="text" className="form-control" onChange={(e) => setMensualitéducréditimmobilier(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéducréditimmobilier} id="inputPassword4" />

                                            </div>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q1Organisme'][Lang])}</label>
                                                <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurimmobilier(e.target.value)}>
                                                    <option disabled selected="selected">Choisissez</option>
                                                    <option value="AL-SALAM" selected={Organismeprêteurimmobilier === "AL-SALAM"}>Al salam Bank Algeria</option>
                                                    <option value="BEA" selected={Organismeprêteurimmobilier === "BEA"}>BEA (Banque Extérieure d’Algérie)</option>
                                                    <option value="BNA" selected={Organismeprêteurimmobilier === "BNA"}>BNA (Banque Nationale d’Algérie) </option>
                                                    <option value="CPA" selected={Organismeprêteurimmobilier === "CPA"}>CPA (Crédit Populaire d’Algérie)</option>
                                                    <option value="BDL" selected={Organismeprêteurimmobilier === "BDL"}>BDL (Banque de Développement Local)</option>
                                                    <option value="CNEP" selected={Organismeprêteurimmobilier === "CNEP"}>CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                                    <option value="BADR" selected={Organismeprêteurimmobilier === "BADR"}> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                                    <option value="AL-BARAKA" selected={Organismeprêteurimmobilier === "AL-BARAKA"}>AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                                    <option value="CITIBANK" selected={Organismeprêteurimmobilier === "CITIBANK"}>CITIBANK</option>
                                                    <option value="ABC" selected={Organismeprêteurimmobilier === "ABC"}>ABC (Arab Banking Corporation Algeria)</option>
                                                    <option value="NATIXIS" selected={Organismeprêteurimmobilier === "NATIXIS"}>Natixis ALGERIE</option>
                                                    <option value="SGA" selected={Organismeprêteurimmobilier === "SGA"}> Société Générale Algérie</option>
                                                    <option value="BNP" selected={Organismeprêteurimmobilier === "BNP"}> BNP Paribas Al-djazair</option>
                                                    <option value="TRUST-BANK" selected={Organismeprêteurimmobilier === "TRUST-BANK"}> TRUST BANK Algeria</option>
                                                    <option value="Housing-Bank" selected={Organismeprêteurimmobilier === "Housing-Bank"}>Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                                    <option value="AGB" selected={Organismeprêteurimmobilier === "AGB"}> AGB (Gulf Bank Algérie)</option>
                                                    <option value="FRANSABANK" selected={Organismeprêteurimmobilier === "FRANSABANK"}>Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                                    <option value="FRANSABANK" selected={Organismeprêteurimmobilier === "FRANSABANK"}>Fransabank Al-Djazair</option>
                                                    <option value="HSBC" selected={Organismeprêteurimmobilier === "HSBC"}> HSBC Algeria</option>
                                                </select>

                                            </div>
                                            <div className="col-md-12 mb-5">
                                                <label className="form-label">{(chargesetpatrimoine['Q1Date'][Lang])}</label>
                                                <input readOnly type="date" onChange={(e) => setDatedefinducréditimmobilier(e.target.value)} value={Datedefinducréditimmobilier} className="text-end form-control" id="inputEmail4" />

                                            </div>
                                        </>
                                    }




                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q2'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions3" id="inlineRadio33" checked={créditvoiture == 'oui'} onChange={(e) => setcréditvoiture(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio33">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions3" id="inlineRadio43" checked={créditvoiture == 'non'} onChange={(e) => setcréditvoiture(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio43">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>

                                    {
                                        créditvoiture === "oui" &&
                                        <>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q2Mensualité'][Lang])}</label>
                                                <input readOnly placeholder='DA/دج' maxLength={10} type="text" className="form-control" onChange={(e) => setMensualitéducréditvoiture(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéducréditvoiture} id="inputPassword4" />


                                            </div>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q2Organisme'][Lang])}</label>
                                                <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurdecréditvoiture(e.target.value)}>
                                                    <option disabled selected="selected">Choisissez</option>
                                                    <option value="AL-SALAM" selected={Organismeprêteurdecréditvoiture === "AL-SALAM"}>Al salam Bank Algeria</option>
                                                    <option value="BEA" selected={Organismeprêteurdecréditvoiture === "BEA"}>BEA (Banque Extérieure d’Algérie)</option>
                                                    <option value="BNA" selected={Organismeprêteurdecréditvoiture === "BNA"}>BNA (Banque Nationale d’Algérie) </option>
                                                    <option value="CPA" selected={Organismeprêteurdecréditvoiture === "CPA"}>CPA (Crédit Populaire d’Algérie)</option>
                                                    <option value="BDL" selected={Organismeprêteurdecréditvoiture === "BDL"}>BDL (Banque de Développement Local)</option>
                                                    <option value="CNEP" selected={Organismeprêteurdecréditvoiture === "CNEP"}>CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                                    <option value="BADR" selected={Organismeprêteurdecréditvoiture === "BADR"}> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                                    <option value="AL-BARAKA" selected={Organismeprêteurdecréditvoiture === "AL-BARAKA"}>AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                                    <option value="CITIBANK" selected={Organismeprêteurdecréditvoiture === "CITIBANK"}>CITIBANK</option>
                                                    <option value="ABC" selected={Organismeprêteurdecréditvoiture === "ABC"}>ABC (Arab Banking Corporation Algeria)</option>
                                                    <option value="NATIXIS" selected={Organismeprêteurdecréditvoiture === "NATIXIS"}>Natixis ALGERIE</option>
                                                    <option value="SGA" selected={Organismeprêteurdecréditvoiture === "SGA"}> Société Générale Algérie</option>
                                                    <option value="BNP" selected={Organismeprêteurdecréditvoiture === "BNP"}> BNP Paribas Al-djazair</option>
                                                    <option value="TRUST-BANK" selected={Organismeprêteurdecréditvoiture === "TRUST-BANK"}> TRUST BANK Algeria</option>
                                                    <option value="Housing-Bank" selected={Organismeprêteurdecréditvoiture === "Housing-Bank"}>Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                                    <option value="AGB" selected={Organismeprêteurdecréditvoiture === "AGB"}> AGB (Gulf Bank Algérie)</option>
                                                    <option value="FRANSABANK" selected={Organismeprêteurdecréditvoiture === "FRANSABANK"}>Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                                    <option value="FRANSABANK" selected={Organismeprêteurdecréditvoiture === "FRANSABANK"}>Fransabank Al-Djazair</option>
                                                    <option value="HSBC" selected={Organismeprêteurdecréditvoiture === "HSBC"}> HSBC Algeria</option>
                                                </select>

                                            </div>
                                            <div className="col-md-12 mb-5">
                                                <label className="form-label">{(chargesetpatrimoine['Q2Date'][Lang])}</label>
                                                <input readOnly type="date" onChange={(e) => setDatedefinducréditvoiture(e.target.value)} value={Datedefinducréditvoiture} className="text-endform-control" id="inputEmail4" />

                                            </div>
                                        </>
                                    }

                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q3'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions4" id="inlineRadio53" checked={Autrecrédit == 'oui'} onChange={(e) => setAutrecrédit(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio53">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions4" id="inlineRadio64" checked={Autrecrédit == 'non'} onChange={(e) => setAutrecrédit(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio64">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>


                                    {
                                        Autrecrédit === "oui" &&
                                        <>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q3Mensualité'][Lang])}</label>
                                                <input readOnly placeholder='DA/دج' maxLength={10} type="text" className="form-control" onChange={(e) => setMensualitéduautrecrédit(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéduautrecrédit} id="inputPassword4" />

                                            </div>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q3Organisme'][Lang])}</label>
                                                <select disabled class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurautrecrédit(e.target.value)}>
                                                    <option disabled selected="selected">Choisissez</option>
                                                    <option value="AL-SALAM" selected={Organismeprêteurautrecrédit === "AL-SALAM"}>Al salam Bank Algeria</option>
                                                    <option value="BEA" selected={Organismeprêteurautrecrédit === "BEA"}>BEA (Banque Extérieure d’Algérie)</option>
                                                    <option value="BNA" selected={Organismeprêteurautrecrédit === "BNA"}>BNA (Banque Nationale d’Algérie) </option>
                                                    <option value="CPA" selected={Organismeprêteurautrecrédit === "CPA"}>CPA (Crédit Populaire d’Algérie)</option>
                                                    <option value="BDL" selected={Organismeprêteurautrecrédit === "BDL"}>BDL (Banque de Développement Local)</option>
                                                    <option value="CNEP" selected={Organismeprêteurautrecrédit === "CNEP"}>CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                                    <option value="BADR" selected={Organismeprêteurautrecrédit === "BADR"}> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                                    <option value="AL-BARAKA" selected={Organismeprêteurautrecrédit === "AL-BARAKA"}>AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                                    <option value="CITIBANK" selected={Organismeprêteurautrecrédit === "CITIBANK"}>CITIBANK</option>
                                                    <option value="ABC" selected={Organismeprêteurautrecrédit === "ABC"}>ABC (Arab Banking Corporation Algeria)</option>
                                                    <option value="NATIXIS" selected={Organismeprêteurautrecrédit === "NATIXIS"}>Natixis ALGERIE</option>
                                                    <option value="SGA" selected={Organismeprêteurautrecrédit === "SGA"}> Société Générale Algérie</option>
                                                    <option value="BNP" selected={Organismeprêteurautrecrédit === "BNP"}> BNP Paribas Al-djazair</option>
                                                    <option value="TRUST-BANK" selected={Organismeprêteurautrecrédit === "TRUST-BANK"}> TRUST BANK Algeria</option>
                                                    <option value="Housing-Bank" selected={Organismeprêteurautrecrédit === "Housing-Bank"}>Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                                    <option value="AGB" selected={Organismeprêteurautrecrédit === "AGB"}> AGB (Gulf Bank Algérie)</option>
                                                    <option value="FRANSABANK" selected={Organismeprêteurautrecrédit === "FRANSABANK"}>Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                                    <option value="FRANSABANK" selected={Organismeprêteurautrecrédit === "FRANSABANK"}>Fransabank Al-Djazair</option>
                                                    <option value="HSBC" selected={Organismeprêteurautrecrédit === "HSBC"}> HSBC Algeria</option>
                                                </select>

                                            </div>
                                            <div className="col-md-12 mb-5">
                                                <label className="form-label">{(chargesetpatrimoine['Q3Date'][Lang])}</label>
                                                <input readOnly type="date" onChange={(e) => setDatedefinduautrecrédit(e.target.value)} value={Datedefinduautrecrédit} className="text-end form-control" id="inputEmail4" />

                                            </div>
                                        </>
                                    }



                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q4'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions5" id="inlineRadio76" checked={Loyerpayer == 'oui'} onChange={(e) => setLoyerpayer(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio76">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions5" id="inlineRadio86" checked={Loyerpayer == 'non'} onChange={(e) => setLoyerpayer(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio86">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>

                                    {Loyerpayer === "oui" &&
                                        <>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q4Loyer'][Lang])}</label>
                                                <input readOnly type="text" placeholder='DA/دج' maxLength={10} className="form-control" onChange={(e) => setLoyer(e.target.value.replace(/[^0-9]+/g, ''))} value={Loyer} id="inputPassword4" />

                                            </div>
                                        </>}




                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q5'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions688" id="inlineRadio6887" checked={SéjourUSA == 'oui'} onChange={(e) => setSéjourUSA(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio6887">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions1017" id="inlineRadio1017" checked={SéjourUSA == 'non'} onChange={(e) => setSéjourUSA(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio1017">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q6'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions1111" id="inlineRadio1111" checked={GreenCard == 'oui'} onChange={(e) => setGreenCard(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio1111">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions7300" id="inlineRadio7300" checked={GreenCard == 'non'} onChange={(e) => setGreenCard(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio7300">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <div className='mb-2'>{(chargesetpatrimoine['Q7'][Lang])}</div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions130" id="inlineRadio130" checked={Compteépargne == 'oui'} onChange={(e) => setCompteépargne(e.target.value)} defaultChecked={true} defaultValue="oui" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio130">{(chargesetpatrimoine['r1'][Lang])}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input readOnly className="form-check-input opacity-100" type="radio" disabled name="inlineRadioOptions140" id="inlineRadio140" checked={Compteépargne == 'non'} onChange={(e) => setCompteépargne(e.target.value)} defaultValue="non" />
                                            <label className="form-check-label opacity-100" htmlFor="inlineRadio140">{(chargesetpatrimoine['r2'][Lang])}</label>
                                        </div>
                                    </div>
                                    {Compteépargne === "oui" &&
                                        <>
                                            <div className="col-md-12 mb-1">
                                                <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q7épargne'][Lang])}</label>
                                                <input readOnly type="tel" maxLength={10} placeholder='DA/دج' className="form-control" onChange={(e) => setCompteépargneText(e.target.value.replace(/[^0-9]+/g, ''))} value={CompteépargneText} id="inputPassword4" />



                                            </div>
                                        </>}

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q8'][Lang])}</label>
                                        <input readOnly maxLength={10} type="tel" placeholder='DA/دج' className="form-control" id="inputPassword4" onChange={(e) => setimmeublesoumeubles(e.target.value.replace(/[^0-9]+/g, ''))} value={immeublesoumeubles} />
                                        <br />
                                    </div>



                                    {/* <div className="col-12 mt-5">
                                        <div onClick={() => GoNext()} className="store-next-btn">Modifier</div>
                                    </div> */}
                                </div></div>
                            : ''
                        }


                    </div>
                </div>
            </div>
        </div>

        // </div>
    )
}

export default VoirDemande