import React, { createContext, useState, useEffect } from 'react'

const AuthContext = createContext();

export function AuthProvider({ children }) {

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  const getToken = localStorage.getItem('Token') !== '' && localStorage.getItem('Token') !== null && isJsonString(localStorage.getItem('Token')) ? JSON.parse(localStorage.getItem('Token')) : false;


  const [Auth, setAuth] = useState(getToken ? getToken : false);

  console.log(Auth);

  useEffect(() => {
    localStorage.setItem('Token', JSON.stringify(Auth));
  }, [Auth]);


  const [Permission, setPermission] = useState('')
  console.log(Permission);



  const Actions = [
    { id: 0, name: 'En attente' ,service : "Niveau 1 (centre d'appel)"},
    { id: 13, name: "Intéressé par formation" ,service : "Niveau 1 (centre d'appel)"},
{ id: 3, name: "Client informé en attente paiement 1000 DA" ,service : "Niveau 1 (centre d'appel)"},
{ id: 2, name: "Tentative échouée N01" ,service : "Niveau 1 (centre d'appel)"},
{ id: 4, name: "Paiement CCP effectuè" ,service : "Niveau 1 (centre d'appel)"},
{ id: 5, name: "Paiement en espèce effectuè" ,service : "Niveau 1 (centre d'appel)"},
{ id: 27, name: "Pas intéressé" , service : "Niveau 1 (centre d'appel)"},
{ id: 28, name: "Annulation" , service : "Niveau 1 (centre d'appel)"},
{ id: 29, name: "Client informer N01" , service : "Niveau 1 (centre d'appel)"},
{ id: 30, name: "Faux numéro" , service : "Niveau 1 (centre d'appel)"},
{ id: 31, name: "Test" , service : "Niveau 1 (centre d'appel)"},



{ id: 6, name: "Confirmé en attente paiement 5000 DA" , service : "Niveau 2 (relation client)"},
{ id: 34, name: "Confirmé Paiement effectué 5000 DA" , service : "Niveau 2 (relation client)"},
{ id: 7, name: "Confirmè WhatsApp" , service : "Niveau 2 (relation client)"},
{ id: 8, name: "Confirmè dossier incomplet" , service : "Niveau 2 (relation client)"},
{ id: 9, name: "Demande ramassage" , service : "Niveau 2 (relation client)"},
{ id: 10, name: "Demande ramassage WhatsApp" , service : "Niveau 2 (relation client)"},
{ id: 11, name: "Tentative échouée N02" , service : "Niveau 2 (relation client)"},
{ id: 32, name: "Faux numéro" , service : "Niveau 2 (relation client)"},
{ id: 33, name: "Test" , service : "Niveau 2 (relation client)"},
{ id: 12, name: "Annulation" , service : "Niveau 2 (relation client)"},

{ id: 1, name: "Accusé niveau 03" , service : "Niveau 3 (relation banque)"},
{ id: 14, name: "Dossier complet" , service : "Niveau 3 (relation banque)"},
{ id: 15, name: "Dossier incomplet" , service : "Niveau 3 (relation banque)"},
{ id: 16, name: "Dossier en cours de traitement" , service : "Niveau 3 (relation banque)"},
{ id: 17, name: "Dossier physique traité" , service : "Niveau 3 (relation banque)"},


{ id: 18, name: "Panier en préparation" , service : "Commercial"},
{ id: 19, name: "Prêt pour expédition" , service : "Commercial"},


{ id: 20, name: "Sortie ramassage dossier" ,service : "Ramassage"},
{ id: 21, name: "Tentative échouée N03" ,service : "Ramassage"},
{ id: 22, name: "Ramassage dossier reçu" ,service : "Ramassage"},
{ id: 23, name: "Sortie ramassage pick up" ,service : "Ramassage"},
{ id: 24, name: "Ramassage pick up reçu" ,service : "Ramassage"},
{ id: 25, name: "Dossier reçu incomplet" ,service : "Ramassage"},

{ id: 26, name: "Programmé pour livraison" ,service : "Livraison"},
{ id: 35, name: "Sortie de dépôt" ,service : "Livraison"},
{ id: 36, name: "Sortie de dépôt (Espèces)" ,service : "Livraison"}


];

const ActionsFiltred = Actions?.reduce((acc, option) => {
  if (!acc[option?.service]) {
    acc[option?.service] = [];
  }
  acc[option?.service].push(option);
  return acc;
}, {});

function FindActionPerId(action) {

  let action_name = Actions?.find(o => o.id == action)

  if (action_name) {
      return action_name?.name;

  } else {
      return 'En attente'
  }


}










  return <AuthContext.Provider value={{ Auth, setAuth ,Permission, setPermission, Actions,ActionsFiltred,FindActionPerId}}>{children}</AuthContext.Provider>

}


export default AuthContext