import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAlsalam/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import LoadData from '../../components/Global/LoadData';

const ListDemandes = () => {

    const { Auth, setAuth } = useContext(AuthContext);

    const navigate = useNavigate();


    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

    const [Word, setWord] = useState(searchParams.get('nomdevendeur') ? searchParams.get('nomdevendeur') : '')
    const [Date_start, setDate_start] = useState(searchParams.get('date_start') ? searchParams.get('date_start') : '')
    const [User_Id, setUser_Id] = useState(searchParams.get('userid') ? searchParams.get('userid') : '')
    const [Demande_Id, setDemande_Id] = useState(searchParams.get('demande_id') ? searchParams.get('demande_id') : '')

    const [Tel, setTel] = useState(searchParams.get('tel') ? searchParams.get('tel') : '')
    const [Validvendeur, setValidvendeur] = useState(searchParams.get('validvendeur') ? searchParams.get('validvendeur') : 'vide')
    const [Wilaya, setWilaya] = useState(searchParams.get('wilaya') ? searchParams.get('wilaya') : 'all')



    const [UrlPagination, setUrlPagination] = useState('')




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            const date_start = Date_start !== '' ? '&date_start=' + Date_start : '';
            const keyword = Word.trim() !== '' ? '&nomdevendeur=' + Word : '';
            const userid = User_Id.trim() !== '' ? '&userid=' + User_Id : '';
            const demande_id = Demande_Id.trim() !== '' ? '&demande_id=' + Demande_Id : '';

            const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
            const valid_vendeur = Validvendeur == 1 || Validvendeur == 0 || Validvendeur == 2 ? '&validvendeur=' + Validvendeur : '';
            const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';


            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam?page=${page}${userid}${demande_id}${keyword}${date_start}${tel_vendeur}${valid_vendeur}${wilaya_boutique}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login-alsalam')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    async function removeVendeur(id) {

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Voulez-vous vraiment supprimer ce Client ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam/delete/delete_vendeur/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/login-alsalam')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {

                        const updatefListVentes = ListVentes.filter(item => item.user_id !== id);
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            'Supprimée!',
                            'Ce Client a été supprimé',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })

    }

    async function validVendeur(id, etat) {


        Swal.fire({
            title: 'Êtes-vous sûr',
            text: etat == 1 ? "Êtes-vous sûr d'activer ce vendeur ?" : "Êtes-vous sûr de désactiver ce vendeur ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam/valid_projet/${id}/${etat}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/login-alsalam')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {
                        const updatefListVentes = ListVentes.map(obj =>
                            obj.user_id == id ? { ...obj, valid: etat } : obj
                        );
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            etat == 1 ? 'Activé!' : 'Désactiver!',
                            etat == 1 ? 'Ce vendeur a été activé' : 'Ce vendeur a été désactivé',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })
    }


    useEffect(() => {

        const date_start = Date_start !== '' ? '&date_start=' + Date_start : '';
        const keyword = Word.trim() !== '' ? '&nomdevendeur=' + Word : '';
        const userid = User_Id.trim() !== '' ? '&userid=' + User_Id : '';
        const demande_id = Demande_Id.trim() !== '' ? '&demande_id=' + Demande_Id : '';
        const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
        const valid_vendeur = Validvendeur == 1 || Validvendeur == 0 || Validvendeur == 2  ? '&validvendeur=' + Validvendeur : '';
        const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';

        let url = userid + demande_id + keyword + date_start + tel_vendeur + valid_vendeur + wilaya_boutique

        setUrlPagination(url)



    }, [User_Id,Demande_Id, Word, Tel, Validvendeur, Wilaya, Date_start])

    function SearchQUERY() {
        const fetchData = async () => {
            setLoading(true);



            const keyword = Word.trim() !== '' ? '&nomdevendeur=' + Word : '';
            const userid = User_Id.trim() !== '' ? '&userid=' + User_Id : '';
            const demande_id = Demande_Id.trim() !== '' ? '&demande_id=' + Demande_Id : '';
            const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
            const valid_vendeur = Validvendeur == 1 || Validvendeur == 0  || Validvendeur == 2 ? '&validvendeur=' + Validvendeur : '';
            const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';
            const date_start = Date_start !== '' ? '&date_start=' + Date_start : '';



            setSearchParams({ page: 1, ...(User_Id.trim() !== '' && { 'userid': User_Id }), ...(Demande_Id.trim() !== '' && { 'demande_id': Demande_Id }), ...(Word.trim() !== '' && { nomdevendeur: Word }), ...(Date_start !== '' && { date_start: Date_start }), ...(Tel.trim() !== '' && { tel: Tel }), ...((Validvendeur == 1 || Validvendeur == 0 || Validvendeur == 2) && { validvendeur: Validvendeur }), ...((Wilaya.trim() !== '' && Wilaya.trim() !== 'all') && { wilaya: Wilaya }) })
            // setSearchParams({page : 1,nomdeboutique: NomBoutique.trim() !== '' ?  NomBoutique :  ''})

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam?page=${1}${userid}${demande_id}${keyword}${date_start}${tel_vendeur}${valid_vendeur}${wilaya_boutique}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login-alsalam')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            SearchQUERY();
        }
    };


    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];


    async function ShowRaisonOfRejection(text) {
        await Swal.fire(
            'Raison du rejet !',
            text,
            // 'success'
        );
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }
    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Liste Des Clients<span>Iamal</span></div>

                    <div className='store-search-section'>
                        <div>
                            <input style={{ maxWidth: "100px" }} onKeyDown={handleKeyDown} type="tel" placeholder={"Id Demande"} onChange={(e) => setDemande_Id(e.target.value.replace(/[^0-9]+/g, ''))} value={Demande_Id} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <input style={{ maxWidth: "100px" }} onKeyDown={handleKeyDown} type="tel" placeholder={"Id Client"} onChange={(e) => setUser_Id(e.target.value.replace(/[^0-9]+/g, ''))} value={User_Id} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <input onKeyDown={handleKeyDown} type="text" placeholder={"Nom"} onChange={(e) => setWord(e.target.value)} value={Word} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <input onKeyDown={handleKeyDown} type="tel" placeholder={"Mobile"} onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <input onKeyDown={handleKeyDown} type="date" onChange={(e) => setDate_start(e.target.value)} value={Date_start} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setValidvendeur(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Validité</option>
                                <option selected={Validvendeur == 'all'} value={'all'}>Tous</option>
                                <option selected={Validvendeur == 0} value={0}>En attent</option>
                                <option selected={Validvendeur == 1} value={1}>Validé</option>
                                <option selected={Validvendeur == 2} value={2}>Rejeté</option>
                            </select>
                        </div>
                        <div>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setWilaya(e.target.value)}>
                                <option value={'all'} selected={Wilaya == "all" ? "selected" : ''}>Tous Les Wilayas</option>
                                {WilayaList?.length > 0 && WilayaList?.map((item, i) => (
                                    <option selected={Wilaya == item?.value} value={item?.value}>{item?.text}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className='search-btn-dashboard' onClick={SearchQUERY}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.621" height="30.621" viewBox="0 0 30.621 30.621">
                                <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1.5 1.5)">
                                    <path id="Tracé_1" data-name="Tracé 1" d="M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Tracé_2" data-name="Tracé 2" d="M31.5,31.5l-6.525-6.525" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>


                        </div>

                    </div>


                    <div className='dashboard-table'>
                        <table>

                            <thead>
                                <tr>
                                    <th scope="col">Id Demande</th>
                                    <th scope="col">Id Client</th>
                                    <th scope="col">Nom</th>
                                    {/* <th className='d-xl-block d-none' scope="col">email</th> */}
                                    <th scope="col">Wilaya</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Date d'approbation <br /> ou de rejet</th>
                                    <th scope="col">Status</th>

                                    
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            {!Loading && <tbody>

                                {ListVentes?.length > 0 && ListVentes?.map((item, i) => (

                                    <tr>
                                        {/* <td data-label="Produits"><img src={`${process.env.REACT_APP_LOCAL_URL}/images/${item?.image1}`} /><div>{item?.titre_produit}</div></td> */}
                                        <td data-label="Id Demande">{item?.id}</td>
                                        <td data-label="Id Client">{item?.IdUser}</td>
                                        <td data-label="Nom">{item?.nom} {item?.prenom}</td>
                                        {/* <td className='d-xl-block d-none' data-label="email">{item?.email}</td> */}
                                        <td data-label="Wilaya">{item?.wilaya}</td>
                                        <td data-label="Date">{new Date(item?.created_at).toLocaleDateString('fr') }</td>
                                        <td data-label="Tel">{item?.mobile}</td>
                                        <td data-label="Date d'approbation ou de rejet">{item?.date_validation && dateIsValid(new Date(item?.date_validation)) ? new Date(item?.date_validation).toLocaleDateString('fr') : '-'}</td>

                                        <td data-label="Valid">

                                            {item?.status == 1 ?

                                            <a href={`/status-project/${item?.id}/${item?.IdUser}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg></a>
                                                :
                                                ""

                                            }
                                            {item?.status == 0 ?
                                            <a href={`/status-project/${item?.id}/${item?.IdUser}`}>
                                                <img src="https://cdn-icons-png.flaticon.com/512/3858/3858433.png" width={'38'} /></a>
                                                :
                                                ""

                                            }

                                            {item?.status == 2 ?
                                            <a onClick={() => ShowRaisonOfRejection(item?.reason_for_rejection)}>
                                            {/* <a href={`/status-project/${item?.id}/${item?.IdUser}`}> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                                {/* </a> */}

<div className="mt-2 delete-btn " >Raison du rejet</div>
</a>
                                                :
                                                ""

                                            }




                                        </td>
                                        <td className="vendeursadminlist" data-label=" ">
                                            
                                            <a href={`/voir-demande/${item?.id}/${item?.IdUser}`}> <div className='voir-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                    </g>
                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                    </g>
                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </svg>
                                            </div></a>


                                            <a href={`/status-project/${item?.id}/${item?.IdUser}`}> <div className='update-btn mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="21.167" height="20.59" viewBox="0 0 21.167 20.59">
                                                <g id="edit-246" transform="translate(-134.2 -129)">
                                                    <path id="Tracé_9269" data-name="Tracé 9269" d="M152.96,169.464a4,4,0,0,1-3.993,3.993h-9.63a4,4,0,0,1-3.993-3.993v-9.628a4,4,0,0,1,3.993-3.993h3.478V154.7h-3.478a5.143,5.143,0,0,0-5.137,5.137v9.628a5.143,5.143,0,0,0,5.137,5.137h9.628a5.143,5.143,0,0,0,5.137-5.137v-3.478h-1.144v3.478Z" transform="translate(0 -25.011)" fill="#fff" />
                                                    <path id="Tracé_9270" data-name="Tracé 9270" d="M278.443,129.922a3.15,3.15,0,0,0-4.453,0l-11.026,11.026a2.943,2.943,0,0,0-.836,1.675l-.509,3.558a.5.5,0,0,0,.493.571.66.66,0,0,0,.072-.005l3.558-.509a2.959,2.959,0,0,0,1.675-.836l11.026-11.026A3.149,3.149,0,0,0,278.443,129.922ZM264.3,141.227l9.333-9.333,2.832,2.835-9.33,9.333Zm1.278,3.877-2.709.386.386-2.709a1.835,1.835,0,0,1,.265-.715l2.771,2.771A1.747,1.747,0,0,1,265.581,145.1Zm12.053-11.541-.356.356-2.832-2.835.356-.356a2,2,0,1,1,2.832,2.835Z" transform="translate(-123.999)" fill="#fff" />
                                                </g>
                                            </svg>
                                            </div></a>

                                            <a href={`/client-documents/${item?.id_user}`}> <div className='mx-1'>
                                                <img src="https://cdn-icons-png.flaticon.com/512/6747/6747196.png" width={"30"} />

                                            </div></a>

                                            <a href={`/scanned-documents/${item?.id_user}`}> <div className='mx-1 mt-2'>
                                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3QQT1DZtNJvOM5K4ab-99E8lpyEsb4zu8Q3dw8XYC2j_T2GS_" width={"35"} />

                                            </div></a>

                                            {/* <a onClick={() => removeVendeur(item?.user_id)} > <div className='delete-btn my-1 '><svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                            </svg>
                                            </div></a> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                        {Loading &&
                            <LoadData />
                        }

                        {
                            ListVentes?.length === 0 || !ListVentes && !Loading &&
                            <div className='no-results'>Il n'y a pas de données</div>

                        }

                    </div>
                    <div>
                        {PageNumber > 1 &&
                            <div class="text-center mt-5 pt-1 mb-5">
                                <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${PageNumber} ${CurrentPage < PageNumber}`}>
                                    {CurrentPage > 1 ? <a href={`?page=1${UrlPagination}`}  >{'<<'}</a> : <a>{'<<'}</a>}

                                    {CurrentPage !== 1 ? <a href={`?page=${parseInt(CurrentPage) - 1}${UrlPagination}`}>&laquo;</a> : <a>&laquo;</a>}



                                    {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                                        <>
                                            {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                                                <a href={`?page=${item + 1}${UrlPagination}`} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                                                : ''}
                                        </>

                                    ))}



                                    {CurrentPage < PageNumber ? <a href={`?page=${parseInt(CurrentPage) + 1}${UrlPagination}`}>&raquo;</a> : <a>&raquo;</a>}

                                    {CurrentPage < PageNumber ? <a href={`?page=${PageNumber}${UrlPagination}`}  >{'>>'}</a> : <a>{'>>'}</a>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ListDemandes