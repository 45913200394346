import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const ScanDocuments = () => {

    const { id } = useParams();
    const [ProductData, setProductData] = useState('')

    const [searchParams, setSearchParams] = useSearchParams();
    const _ROLE = searchParams.get('role');

    const [AccountStatus, setAccountStatus] = useState()

    const [progress, setProgress] = useState({ 'ouverture_de_compte': 0, 'centrale_des_risques': 0, 'fiche_fatca': 0, 'demande_de_financement': 0, 'rapport_dentretien': 0, 'declaration_dengagement': 0, 'fiche_de_signature': 0, 'annexe_2': 0, 'business_plan': 0, 'annexe_6': 0, 'la_facture': 0, 'documents_supplementaires': 0, 'carte_dartisanat': 0, 'registre_de_commerce': 0, 'diplome': 0, 'demander_permis': 0, 'engagement': 0 , 'echeancier': 0 ,"assurance_cartegrise" : 0})
    const [StartUpload, setStartUpload] = useState({ 'ouverture_de_compte': null, 'centrale_des_risques': null, 'fiche_fatca': null, 'demande_de_financement': null, 'rapport_dentretien': null, 'declaration_dengagement': null, 'fiche_de_signature': null, 'annexe_2': null, 'business_plan': null, 'annexe_6': null, 'la_facture': null, 'documents_supplementaires': null, 'carte_dartisanat': null, 'registre_de_commerce': null, 'diplome': null, 'demander_permis': null, 'engagement': null ,'echeancier' : null ,"assurance_cartegrise" : null})

    const [ResponseMsgSuccsess, setResponseMsgSuccsess] = useState({ 'ouverture_de_compte': null, 'centrale_des_risques': null, 'fiche_fatca': null, 'demande_de_financement': null, 'rapport_dentretien': null, 'declaration_dengagement': null, 'fiche_de_signature': null, 'annexe_2': null, 'business_plan': null, 'annexe_6': null, 'la_facture': null, 'documents_supplementaires': null, 'carte_dartisanat': null, 'registre_de_commerce': null, 'diplome': null, 'demander_permis': null, 'engagement': null ,'echeancier' : null ,"assurance_cartegrise" : null})
    const [ResponseMsgFail, setResponseMsgFail] = useState({ 'ouverture_de_compte': null, 'centrale_des_risques': null, 'fiche_fatca': null, 'demande_de_financement': null, 'rapport_dentretien': null, 'declaration_dengagement': null, 'fiche_de_signature': null, 'annexe_2': null, 'business_plan': null, 'annexe_6': null, 'la_facture': null, 'documents_supplementaires': null, 'carte_dartisanat': null, 'registre_de_commerce': null, 'diplome': null, 'demander_permis': null, 'engagement': null ,'echeancier' : null ,"assurance_cartegrise" : null})


    const [PaymentDcumentsStatus, setPaymentDcumentsStatus] = useState(false)
    const [PaymentDcumentsTypeOnline, setPaymentDcumentsTypeOnline] = useState(false)

    const [Name, setName] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Payment, setPayment] = useState('')


    const Ouverture_de_compteRef = useRef(null);
    const centrale_des_risquesRef = useRef(null);
    const fiche_fatcaRef = useRef(null);
    const demande_de_financementRef = useRef(null);
    const rapport_dentretienRef = useRef(null);
    const declaration_dengagementRef = useRef(null);
    const fiche_de_signatureRef = useRef(null);
    const annexe_2Ref = useRef(null);
    const business_planRef = useRef(null);
    const annexe_6Ref = useRef(null);
    const la_factureRef = useRef(null);
    const documents_supplementairesRef = useRef(null);
    const carte_dartisanatRef = useRef(null);
    const registre_de_commerceRef = useRef(null);
    const diplomeRef = useRef(null);

    const demander_permisRef = useRef(null);
    const engagementRef = useRef(null);
    const echeancierRef = useRef(null);

    const assurance_cartegriseRef = useRef(null);




    // const VerifyPaymentDocuments = async () => {
    //     setLoading(true)
    //     try {
    //         const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL_Documents}/vendeur/verifypaymentdossierstatus`, {}, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${Auth?.token}`

    //             },
    //         });
    //         console.log(response);

    //         // console.log(response['errorCode']);

    //         if (response['payment_valid'] == true) {
    //            setPaymentDcumentsStatus(true)
    //         }



    //         setLoading(false)

    //         if (response['payment_dossier_methode'] == "2") {
    //            setPaymentDcumentsTypeOnline(true)

    //         }

    //         if (response['payment_dossier_methode'] == "3") {
    //             setPaymentDcumentsTypeOnline(true)

    //         }


    //     } catch (error) {
    //         // console.error(error.message);
    //         setLoading(false)


    //     }
    // }

    // useEffect(() => {

    //     VerifyPaymentDocuments();

    // }, [])

    const ValidateToken = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL_Documents}/vendeur/scan_document_check_admin/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Auth?.token}`
                },
            });

            console.log(response);

            if (response['exist'] == true) {
                setAccountStatus(true)

                setName(response?.data?.name || '')
                setMobile(response?.data?.mobile || '')
                setPayment(response?.data?.payment_dossier_valid || '')


                setOldOuverturedecompte(response?.data?.ouverture_de_compte || '')
                setOldCentraledesRisques(response?.data?.centrale_des_risques || '')
                setOldFicheFatca(response?.data?.fiche_fatca || '')
                setOldDemandedeFinancement(response?.data?.demande_de_financement || '')
                setOldRapportdentretien(response?.data?.rapport_dentretien || '')
                setOldDéclarationdengagement(response?.data?.declaration_dengagement || '')
                setOldFicheDeSignature(response?.data?.fiche_de_signature || '')
                setOldAnnexe2(response?.data?.annexe_2 || '')
                setOldBusinessPlan(response?.data?.business_plan || '')
                setOldAnnexe6(response?.data?.annexe_6 || '')
                setOldFacture(response?.data?.la_facture || '')
                setOldDocumentsSupplémentaires(response?.data?.documents_supplementaires || '')
                setOldCarteDartisanat(response?.data?.carte_dartisanat || '')
                setOldRegistreDeCommerce(response?.data?.registre_de_commerce || '')
                setOldDiplome(response?.data?.diplome || '')

                setOldDemanderunpermis(response?.data?.demander_permis || '')
                setOldEngagement(response?.data?.engagement || '')

                setOldEcheancier(response?.data?.echeancier || '')

                setOldAssuranceCarteGrise(response?.data?.assurance_cartegrise || '')






            } else {
                setAccountStatus(false)
            }

            // console.log(response['errorCode']);
            if (response['error'] == true) {
                // return navigate('/')
            }



            setLoading(false)


        } catch (error) {
            // console.error(error.message);
            setLoading(false)

        }
    }

    useEffect(() => {

        ValidateToken();

    }, [])



    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)



    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Ouverturedecompte, setOuverturedecompte] = useState('');
    const [CentraledesRisques, setCentraledesRisques] = useState('')
    const [FicheFatca, setFicheFatca] = useState('');
    const [DemandedeFinancement, setDemandedeFinancement] = useState('');
    const [Rapportdentretien, setRapportdentretien] = useState('');
    const [Déclarationdengagement, setDéclarationdengagement] = useState('');
    const [FicheDeSignature, setFicheDeSignature] = useState('');
    const [Annexe2, setAnnexe2] = useState('');
    const [BusinessPlan, setBusinessPlan] = useState('');
    const [Annexe6, setAnnexe6] = useState('');
    const [Facture, setFacture] = useState('');
    const [DocumentsSupplémentaires, setDocumentsSupplémentaires] = useState('');
    const [CarteDartisanat, setCarteDartisanat] = useState('');
    const [RegistreDeCommerce, setRegistreDeCommerce] = useState('');
    const [Diplome, setDiplome] = useState('');

    const [Demanderunpermis, setDemanderunpermis] = useState('');
    const [Engagement, setEngagement] = useState('');
    const [Echeancier, setEcheancier] = useState('');

    const [AssuranceCarteGrise, setAssuranceCarteGrise] = useState('');










    const [OldOuverturedecompte, setOldOuverturedecompte] = useState('');
    const [OldCentraledesRisques, setOldCentraledesRisques] = useState('')
    const [OldFicheFatca, setOldFicheFatca] = useState('');
    const [OldDemandedeFinancement, setOldDemandedeFinancement] = useState('');
    const [OldRapportdentretien, setOldRapportdentretien] = useState('');
    const [OldDéclarationdengagement, setOldDéclarationdengagement] = useState('');
    const [OldFicheDeSignature, setOldFicheDeSignature] = useState('');
    const [OldAnnexe2, setOldAnnexe2] = useState('');
    const [OldBusinessPlan, setOldBusinessPlan] = useState('');
    const [OldAnnexe6, setOldAnnexe6] = useState('');
    const [OldFacture, setOldFacture] = useState('');
    const [OldDocumentsSupplémentaires, setOldDocumentsSupplémentaires] = useState('');
    const [OldCarteDartisanat, setOldCarteDartisanat] = useState('');
    const [OldRegistreDeCommerce, setOldRegistreDeCommerce] = useState('');
    const [OldDiplome, setOldDiplome] = useState('');


    const [OldDemanderunpermis, setOldDemanderunpermis] = useState('');
    const [OldEngagement, setOldEngagement] = useState('');
    const [OldEcheancier, setOldEcheancier] = useState('');

    const [OldAssuranceCarteGrise, setOldAssuranceCarteGrise] = useState('');





    const [Loading, setLoading] = useState(false)
    const [errors, seterrors] = useState({ Titreduproduit: '', Réferenceduproduit: '', Marque: '' })
    const [err, seterr] = useState([])
    const [Showerrors, setShowerrors] = useState(false)


    const recaptchaRef = useRef('');
    const [CaptchaChecked, setCaptchaChecked] = useState(false)
    const [captchaerror, setcaptchaerror] = useState('')



    function Validate() {

        if (Ouverturedecompte == '' || Ouverturedecompte.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Ouverturedecompte: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Ouverturedecompte: '' }))
        }

        if (CentraledesRisques == '' || CentraledesRisques.size > 20971520) {
            // seterrors((prev) => ({ ...prev, CentraledesRisques : "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, CentraledesRisques: '' }))
        }

        if (FicheFatca == '' || FicheFatca.size > 20971520) {
            // seterrors((prev) => ({ ...prev, FicheFatca: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FicheFatca: '' }))
        }


        if (DemandedeFinancement == '' || DemandedeFinancement.size > 20971520) {
            // seterrors((prev) => ({ ...prev, DemandedeFinancement: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, DemandedeFinancement: '' }))
        }

        if (Rapportdentretien == '' || Rapportdentretien.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Rapportdentretien: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Rapportdentretien: '' }))
        }

        if (Déclarationdengagement == '' || Déclarationdengagement.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Déclarationdengagement: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Déclarationdengagement: '' }))
        }

        if (FicheDeSignature == '' || FicheDeSignature.size > 20971520) {
            // seterrors((prev) => ({ ...prev, FicheDeSignature: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FicheDeSignature: '' }))
        }

        if (Annexe2 == '' || Annexe2.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Annexe2: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Annexe2: '' }))
        }
        if (BusinessPlan == '' || BusinessPlan.size > 20971520) {
            // seterrors((prev) => ({ ...prev, BusinessPlan: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, BusinessPlan: '' }))
        }

        if (Annexe6 == '' || Annexe6.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Annexe6: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Annexe6: '' }))
        }
        if (Facture == '' || Facture.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Facture: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Facture: '' }))
        }
        if (DocumentsSupplémentaires == '' || DocumentsSupplémentaires.size > 20971520) {
            // seterrors((prev) => ({ ...prev, DocumentsSupplémentaires: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, DocumentsSupplémentaires: '' }))
        }


        if (CarteDartisanat == '' || CarteDartisanat.size > 20971520) {
            // seterrors((prev) => ({ ...prev, CarteDartisanat: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, CarteDartisanat: '' }))
        }
        if (RegistreDeCommerce == '' || RegistreDeCommerce.size > 20971520) {
            // seterrors((prev) => ({ ...prev, RegistreDeCommerce: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, RegistreDeCommerce: '' }))
        }
        if (Diplome == '' || Diplome.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Diplome: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Diplome: '' }))
        }


        if (Demanderunpermis == '' || Demanderunpermis.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Demanderunpermis: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Demanderunpermis: '' }))
        }


        if (Engagement == '' || Engagement.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Engagement: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Engagement: '' }))
        }

        if (Echeancier == '' || Echeancier.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Echeancier: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Echeancier: '' }))
        }

        if (AssuranceCarteGrise == '' || AssuranceCarteGrise.size > 20971520) {
            // seterrors((prev) => ({ ...prev, Echeancier: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, AssuranceCarteGrise: '' }))
        }

    }


    useEffect(() => {

        Validate();

    }, [Ouverturedecompte,Echeancier, CentraledesRisques,AssuranceCarteGrise, FicheFatca, DemandedeFinancement, Rapportdentretien, Déclarationdengagement, FicheDeSignature, Annexe2, BusinessPlan, Annexe6, Facture, DocumentsSupplémentaires, CarteDartisanat, RegistreDeCommerce, Diplome, Demanderunpermis, Engagement])

    useEffect(() => {

        // if (Showerrors) {
        //     const matches = document.querySelectorAll(".err-cart-sum");

        //     for (let index = 0; index < matches.length; index++) {

        //         if (matches[index].textContent.trim() !== '') {

        //             let position = matches[index].getBoundingClientRect();


        //             window.scrollTo(position.left, position.top + window.scrollY - 120);
        //             return
        //         }
        //         // if (matches[index].textContent.trim() !== '') {

        //         //     matches[index].scrollIntoView({behavior: "smooth"});
        //         //     return
        //         // }              
        //     }
        // }


    }, [Showerrors])



    function Register(e, name, value, errText) {

        e.preventDefault();

        setShowerrors(true)

        setResponseMsgSuccsess((prev) => ({ ...prev, [name]: null }))
        setResponseMsgFail((prev) => ({ ...prev, [name]: null }))


        if (value == '' || value.size > 20971520) {
            seterrors((prev) => ({ ...prev, [errText]: "Le fichier requis est obligatoire et ne doit pas dépasser 20 Mo" }))
            const matches = document.querySelectorAll(".err-cart-sum");
            setLoading(false)


            // for (let index = 0; index < matches.length; index++) {

            //     if (matches[index].textContent.trim() !== '') {

            //         let position = matches[index].getBoundingClientRect();


            //         window.scrollTo(position.left, position.top + window.scrollY - 120);
            //         return
            //     }
            // }

            console.log(errors);
            return;
        } else {
            seterrors((prev) => ({ ...prev, [errText]: '' }))
        }
        console.log('hhhhhhhhhh');

        setLoading(true)

        console.log(errors["Déclarationdengagement"]);

        // if (errors[errText] !== '') {
        //     const matches = document.querySelectorAll(".err-cart-sum");
        //     setLoading(false)


        //     for (let index = 0; index < matches.length; index++) {

        //         if (matches[index].textContent.trim() !== '') {

        //             let position = matches[index].getBoundingClientRect();


        //             window.scrollTo(position.left, position.top + window.scrollY - 120);
        //             return
        //         }
        //     }

        //     console.log(errors);
        //     return;
        // }

        // window.scrollTo({ top: 0, behavior: 'smooth' })

        const formData = new FormData();
        formData.append('documentName', name || null);
        formData.append(name, value || null);
        setStartUpload((prev) => ({ ...prev, [name]: true }))




        axios.post(`${process.env.REACT_APP_LOCAL_URL_Documents}/vendeur/insert_scan_document_admin/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress((prev) => ({ ...prev, [name]: Math.round((100 * data.loaded) / data.total) }))
            },
        })
            .then(function (response) {
                console.log(response.data);
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login')

                }
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    setLoading(false)

                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }
                    setResponseMsgFail((prev) => ({ ...prev, [name]: "Il y a un problème lors de l'ajout de données." }))

                    setResponseMsgSuccsess((prev) => ({ ...prev, [name]: null }))


                    // Swal.fire({
                    //     title: 'Problème',
                    //     type: 'error',
                    //     text: "Il y a un problème lors de l'ajout de données.",
                    //     timer: 2000,
                    //     showConfirmButton: false,
                    //     icon: 'error',
                    //     didClose: () => window.scrollTo(0, 0)



                    // });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    setLoading(false)

                    console.log(response.data['err']);
                    setResponseMsgFail((prev) => ({ ...prev, [name]: null }))

                    setResponseMsgSuccsess((prev) => ({ ...prev, [name]: "Les données ont été ajoutées avec succès." }))

                    if (name == 'ouverture_de_compte') {
                        setOldOuverturedecompte(response?.data?.files || '')
                        setOuverturedecompte('')
                        Ouverture_de_compteRef.current.value = "";
                    }

                    if (name == 'centrale_des_risques') {
                        setOldCentraledesRisques(response?.data?.files || '')
                        setCentraledesRisques('')
                        centrale_des_risquesRef.current.value = "";

                    }

                    if (name == 'fiche_fatca') {
                        setOldFicheFatca(response?.data?.files || '')
                        setFicheFatca('')
                        fiche_fatcaRef.current.value = "";

                    }

                    if (name == 'demande_de_financement') {
                        setOldDemandedeFinancement(response?.data?.files || '')
                        setDemandedeFinancement('')
                        demande_de_financementRef.current.value = "";

                    }

                    if (name == 'rapport_dentretien') {
                        setOldRapportdentretien(response?.data?.files || '')
                        setRapportdentretien('')
                        rapport_dentretienRef.current.value = "";

                    }

                    if (name == 'declaration_dengagement') {
                        setOldDéclarationdengagement(response?.data?.files || '')
                        setDéclarationdengagement('')
                        declaration_dengagementRef.current.value = "";

                    }

                    if (name == 'fiche_de_signature') {
                        setOldFicheDeSignature(response?.data?.files || '')
                        setFicheDeSignature('')
                        fiche_de_signatureRef.current.value = "";

                    }
                    if (name == 'annexe_2') {
                        setOldAnnexe2(response?.data?.files || '')
                        setAnnexe2('')
                        annexe_2Ref.current.value = "";

                    }
                    if (name == 'annexe_6') {
                        setOldAnnexe6(response?.data?.files || '')
                        setAnnexe6('')
                        annexe_6Ref.current.value = "";

                    }
                    if (name == 'la_facture') {
                        setOldFacture(response?.data?.files || '')
                        setFacture('')
                        la_factureRef.current.value = "";

                    }
                    if (name == 'documents_supplementaires') {
                        setOldDocumentsSupplémentaires(response?.data?.files || '')
                        setDocumentsSupplémentaires('')
                        documents_supplementairesRef.current.value = "";

                    }
                    if (name == 'business_plan') {
                        setOldBusinessPlan(response?.data?.files || '')
                        setBusinessPlan('')
                        business_planRef.current.value = "";
                    }
                    if (name == 'carte_dartisanat') {
                        setOldCarteDartisanat(response?.data?.files || '')
                        setCarteDartisanat('')
                        carte_dartisanatRef.current.value = "";

                    }
                    if (name == 'registre_de_commerce') {
                        setOldRegistreDeCommerce(response?.data?.files || '')
                        setRegistreDeCommerce('')
                        registre_de_commerceRef.current.value = "";

                    }
                    if (name == 'diplome') {
                        setOldDiplome(response?.data?.files || '')
                        setDiplome('')
                        diplomeRef.current.value = "";
                    }
                    if (name == 'demander_permis') {
                        setOldDemanderunpermis(response?.data?.files || '')
                        setDemanderunpermis('')
                        demander_permisRef.current.value = "";
                    }
                    if (name == 'engagement') {
                        setOldEngagement(response?.data?.files || '')
                        setEngagement('')
                        engagementRef.current.value = "";
                    }
                    if (name == 'echeancier') {
                        setOldEcheancier(response?.data?.files || '')
                        setEcheancier('')
                        echeancierRef.current.value = "";

                    }

                    if (name == "assurance_cartegrise") {
                        setOldAssuranceCarteGrise(response?.data?.files || '')
                        setAssuranceCarteGrise('')
                        assurance_cartegriseRef.current.value = "";

                    }
                }


                setStartUpload({ [name]: false })
                setProgress({ [name]: 0 })
            }).catch(function (error) {
                setResponseMsgSuccsess((prev) => ({ ...prev, [name]: null }))
                setResponseMsgFail((prev) => ({ ...prev, [name]: "Il y a un problème lors de l'ajout de données." }))


                setStartUpload({ [name]: false })
                setProgress({ [name]: 0 })

                setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    // Swal.fire({
                    //     title: 'Problème',
                    //     type: 'error',
                    //     text: "Il y a un problème lors de l'ajout de données.",
                    //     timer: 2000,
                    //     showConfirmButton: false,
                    //     icon: 'error',

                    // });

                }
            });






    }



    // console.log(progress);

    // console.log(errors);

    const DeleteImage = async (type) => {
        // setLoading(true);

        Swal.fire({
            title: 'يرجى التأكيد',
            text: "هل أنت متأكد من حذف الملف ؟",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'نعم !',
            cancelButtonText: 'لا !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {

                ResponseMsgSuccsess[type] = null;
                ResponseMsgFail[type] = null;

                try {
                    const { data: response } = await axios.delete(`${process.env.REACT_APP_LOCAL_URL_Documents}/vendeur/delete_scan_document_admin/${id}`, {
                        data: { "documentName": type, "idcat": 'IdCat' },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/login')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Deleted!',
                            "لم يتم حذف الصورة بنجاح",
                            'error'
                        );

                    }

                    if (response['error'] == false) {


                        // setPreview1(null)
                        // setImage1(null)
                        // setinitPreview1(false)
                        // setOldRecuCCPIamge('')

                        if (type == 'ouverture_de_compte') {
                            setOldOuverturedecompte('')
                            setOuverturedecompte('')
                            Ouverture_de_compteRef.current.value = "";
                        }

                        if (type == 'centrale_des_risques') {
                            setOldCentraledesRisques('')
                            setCentraledesRisques('')
                            centrale_des_risquesRef.current.value = "";

                        }

                        if (type == 'fiche_fatca') {
                            setOldFicheFatca('')
                            setFicheFatca('')
                            fiche_fatcaRef.current.value = "";

                        }

                        if (type == 'demande_de_financement') {
                            setOldDemandedeFinancement('')
                            setDemandedeFinancement('')
                            demande_de_financementRef.current.value = "";

                        }

                        if (type == 'rapport_dentretien') {
                            setOldRapportdentretien('')
                            setRapportdentretien('')
                            rapport_dentretienRef.current.value = "";

                        }

                        if (type == 'declaration_dengagement') {
                            setOldDéclarationdengagement('')
                            setDéclarationdengagement('')
                            declaration_dengagementRef.current.value = "";

                        }

                        if (type == 'fiche_de_signature') {
                            setOldFicheDeSignature('')
                            setFicheDeSignature('')
                            fiche_de_signatureRef.current.value = "";

                        }
                        if (type == 'annexe_2') {
                            setOldAnnexe2('')
                            setAnnexe2('')
                            annexe_2Ref.current.value = "";

                        }
                        if (type == 'annexe_6') {
                            setOldAnnexe6('')
                            setAnnexe6('')
                            annexe_6Ref.current.value = "";

                        }
                        if (type == 'la_facture') {
                            setOldFacture('')
                            setFacture('')
                            la_factureRef.current.value = "";

                        }
                        if (type == 'documents_supplementaires') {
                            setOldDocumentsSupplémentaires('')
                            setDocumentsSupplémentaires('')
                            documents_supplementairesRef.current.value = "";

                        }
                        if (type == 'business_plan') {
                            setOldBusinessPlan('')
                            setBusinessPlan('')
                            business_planRef.current.value = "";
                        }

                        if (type == 'carte_dartisanat') {
                            setOldCarteDartisanat(response?.data?.files || '')
                            setCarteDartisanat('')
                            carte_dartisanatRef.current.value = "";

                        }
                        if (type == 'registre_de_commerce') {
                            setOldRegistreDeCommerce(response?.data?.files || '')
                            setRegistreDeCommerce('')
                            registre_de_commerceRef.current.value = "";

                        }
                        if (type == 'diplome') {
                            setOldDiplome(response?.data?.files || '')
                            setDiplome('')
                            diplomeRef.current.value = "";
                        }
                        if (type == 'demander_permis') {
                            setOldDemanderunpermis(response?.data?.files || '')
                            setDemanderunpermis('')
                            demander_permisRef.current.value = "";
                        }
                        if (type == 'engagement') {
                            setOldEngagement(response?.data?.files || '')
                            setEngagement('')
                            engagementRef.current.value = "";
                        }
                        if (type == 'echeancier') {
                            setOldEcheancier('')
                            setEcheancier('')
                            echeancierRef.current.value = "";
    
                        }
                        if (type == "assurance_cartegrise") {
                            setOldAssuranceCarteGrise(response?.data?.files || '')
                            setAssuranceCarteGrise('')
                            assurance_cartegriseRef.current.value = "";
    
                        }



                        await Swal.fire(
                            'Deleted!',
                            "تم حذف الصورة بنجاح",
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                    await Swal.fire(
                        'Deleted!',
                        "لم يتم حذف الصورة بنجاح",
                        'error'
                    );
                }
            },
        })
        // .then(async (result) => {
        //     if (result.isConfirmed) {
        //         // await axios.get(`${process.env.REACT_APP_LOCAL_URL_Documents}/admin_DiarDzair/delete_admin/${id}`);






        //     } 
        // })


        // setLoading(false);
    }




    return (

        <div className='dashboard'>

            {/* {Loading && 
            <Loader />
            } */}




            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Scan Documents <span>Iamal</span></div>


                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

            <div className="alert alert-danger" role="alert">
                {item}
            </div>

        ))
        } */}

                        {Loading && !StartUpload ?
                            <Loader /> : ''
                        }


                        <form className="row text-center m-auto">
                            <div className="m-auto col-md-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom du client</label>
                                <input type="text" readOnly value={Name} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
                            </div></form>
                        <form className="row text-center m-auto mt-4">
                            <div className="m-auto col-md-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Mobile</label>
                                <input type="text" readOnly value={Mobile} className="form-control" id="inputEmail4" />

                            </div></form>


                        <form className="row g-0">
                            <div className='container py-0 pt-0'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>

                            {StartUpload && Loading ?
                                // <div className="screen">
                                //     <div className="loader">

                                //         <div>
                                //             <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="400" height="81.078" viewBox="0 0 406.418 81.078">
                                //                 <defs>
                                //                     <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                //                         <stop offset="0" stop-color="#fca01f" />
                                //                         <stop offset="1" stop-color="#f27c00" />
                                //                     </linearGradient>
                                //                     <linearGradient id="linear-gradient-5" x1="0.627" y1="-63.466" x2="2.169" y2="-63.466" xlinkHref="#linear-gradient" />
                                //                 </defs>
                                //                 <g id="Groupe_136" data-name="Groupe 136" transform="translate(-85.678 -423.155)">
                                //                     <g id="Groupe_93" data-name="Groupe 93" transform="translate(195.312 430.316)">
                                //                         <path id="Tracé_211" data-name="Tracé 211" d="M221.726,432.043h3.944l11.65,35.5,12.136-35.376h5.037l-15.171,42.779h-4.066l-11.65-34.466L211.9,474.944h-4l-15.171-42.779h5.218l12.137,35.376Z" transform="translate(-192.721 -430.101)" fill="#fff" />
                                //                         <path id="Tracé_212" data-name="Tracé 212" d="M259.9,447.352a28.292,28.292,0,0,0-4.46,1.669l-1.4-3.822a31.254,31.254,0,0,1,5.28-1.912,24.34,24.34,0,0,1,6.129-.7q6.431,0,9.891,3.185t3.459,9.436v19.175h-4.49v-4.733a15.354,15.354,0,0,1-4.4,3.762,13.435,13.435,0,0,1-6.947,1.639,15.323,15.323,0,0,1-4.278-.607,11.625,11.625,0,0,1-3.762-1.851,9.462,9.462,0,0,1-2.67-3.064,8.8,8.8,0,0,1-1-4.308,9.588,9.588,0,0,1,.971-4.4,8.684,8.684,0,0,1,2.761-3.185,13.239,13.239,0,0,1,4.247-1.942,20.714,20.714,0,0,1,5.431-.668,33.423,33.423,0,0,1,5.279.365,38.349,38.349,0,0,1,4.369.971v-1.093q0-4.246-2.458-6.4a10.108,10.108,0,0,0-6.887-2.154A18.765,18.765,0,0,0,259.9,447.352Zm-1.494,12.895a5.66,5.66,0,0,0-2.359,4.793,5.435,5.435,0,0,0,.635,2.67,6.162,6.162,0,0,0,1.724,1.972,7.854,7.854,0,0,0,2.509,1.244,10.388,10.388,0,0,0,2.995.424,12.786,12.786,0,0,0,4.081-.637,10.845,10.845,0,0,0,3.327-1.76,8.331,8.331,0,0,0,2.238-2.669,7.24,7.24,0,0,0,.816-3.429v-3.034q-1.754-.486-4.022-.911a28.333,28.333,0,0,0-5.171-.425Q260.768,458.487,258.409,460.247Z" transform="translate(-191.304 -429.846)" fill="#fff" />
                                //                         <path id="Tracé_213" data-name="Tracé 213" d="M301.232,443.436v4H291.34v27.246h-4.671V447.441H282.36v-4.065h4.309v-2.731q0-5.157,2.457-7.828a9.054,9.054,0,0,1,7.01-2.67,18.92,18.92,0,0,1,2.821.182,12.046,12.046,0,0,1,2.336.607V435q-1.274-.364-2.306-.577a11.174,11.174,0,0,0-2.246-.212q-5.46,0-5.461,6.675v2.548Z" transform="translate(-190.552 -430.147)" fill="#fff" />
                                //                         <path id="Tracé_214" data-name="Tracé 214" d="M307.089,464.955a10.91,10.91,0,0,0,2.488,3.338,10.506,10.506,0,0,0,3.4,2.063,11.252,11.252,0,0,0,3.944.7,12.026,12.026,0,0,0,5.613-1.215,16.59,16.59,0,0,0,4.278-3.216l2.913,2.61a19.167,19.167,0,0,1-5.4,4.278,15.94,15.94,0,0,1-7.524,1.608,15.661,15.661,0,0,1-6.068-1.184,15.1,15.1,0,0,1-4.99-3.337,15.9,15.9,0,0,1-3.4-5.189,17.217,17.217,0,0,1-1.261-6.675,18.109,18.109,0,0,1,1.122-6.4,16.07,16.07,0,0,1,3.126-5.218,14.583,14.583,0,0,1,4.763-3.489,15.136,15.136,0,0,1,12.227.031,13.564,13.564,0,0,1,4.611,3.55,15.462,15.462,0,0,1,2.852,5.279,21.217,21.217,0,0,1,.971,6.492v.668a5.672,5.672,0,0,1-.06.789H305.876A12.886,12.886,0,0,0,307.089,464.955Zm18.872-7.98a16.169,16.169,0,0,0-.875-4.065,10.883,10.883,0,0,0-1.93-3.4,9.343,9.343,0,0,0-3.017-2.336,9.22,9.22,0,0,0-4.132-.88,8.852,8.852,0,0,0-3.8.819,9.913,9.913,0,0,0-3.076,2.245,11.81,11.81,0,0,0-2.171,3.368,14.289,14.289,0,0,0-1.085,4.247Z" transform="translate(-190.098 -429.852)" fill="#fff" />
                                //                         <path id="Tracé_215" data-name="Tracé 215" d="M339.141,464.955a10.893,10.893,0,0,0,2.488,3.338,10.506,10.506,0,0,0,3.4,2.063,11.254,11.254,0,0,0,3.945.7,12.022,12.022,0,0,0,5.612-1.215,16.588,16.588,0,0,0,4.278-3.216l2.913,2.61a19.168,19.168,0,0,1-5.4,4.278,15.939,15.939,0,0,1-7.524,1.608,15.662,15.662,0,0,1-6.068-1.184,15.077,15.077,0,0,1-4.99-3.337,15.918,15.918,0,0,1-3.4-5.189,17.235,17.235,0,0,1-1.26-6.675,18.107,18.107,0,0,1,1.122-6.4,16.087,16.087,0,0,1,3.125-5.218,14.6,14.6,0,0,1,4.764-3.489,15.137,15.137,0,0,1,12.227.031,13.6,13.6,0,0,1,4.612,3.55,15.459,15.459,0,0,1,2.851,5.279,21.218,21.218,0,0,1,.971,6.492v.668a5.675,5.675,0,0,1-.06.789H337.927A12.9,12.9,0,0,0,339.141,464.955Zm18.872-7.98a16.082,16.082,0,0,0-.876-4.065,10.839,10.839,0,0,0-1.929-3.4,9.361,9.361,0,0,0-3.016-2.336,9.227,9.227,0,0,0-4.133-.88,8.852,8.852,0,0,0-3.8.819,9.889,9.889,0,0,0-3.076,2.245,11.811,11.811,0,0,0-2.171,3.368,14.319,14.319,0,0,0-1.086,4.247Z" transform="translate(-189.323 -429.852)" fill="#fff" />
                                //                         <path id="Tracé_216" data-name="Tracé 216" d="M371.754,474.388h-4.673V443.016h4.673v8.193a16.711,16.711,0,0,1,2.123-3.581,14.278,14.278,0,0,1,2.913-2.822,12.4,12.4,0,0,1,3.581-1.79,12.855,12.855,0,0,1,4.187-.546v5.037h-.365a12.876,12.876,0,0,0-4.824.909,10.956,10.956,0,0,0-3.975,2.731,12.944,12.944,0,0,0-2.669,4.521,18.318,18.318,0,0,0-.971,6.22Z" transform="translate(-188.501 -429.849)" fill="#fff" />
                                //                         <path id="Tracé_217" data-name="Tracé 217" d="M419.842,452.732a12.98,12.98,0,0,1-3.65,4.55,16.245,16.245,0,0,1-5.413,2.761,22.716,22.716,0,0,1-6.631.94h-8.453v13.654h-7.4V432.162h16.748a21.111,21.111,0,0,1,6.7,1,14.262,14.262,0,0,1,5.1,2.883,12.741,12.741,0,0,1,3.217,4.489,14.778,14.778,0,0,1,1.121,5.887A14.165,14.165,0,0,1,419.842,452.732ZM411.1,440.817a10.717,10.717,0,0,0-6.7-1.919h-8.706v15.352H404.4a10.041,10.041,0,0,0,6.728-2.1,6.961,6.961,0,0,0,2.466-5.575A6.809,6.809,0,0,0,411.1,440.817Z" transform="translate(-187.988 -430.098)" fill="url(#linear-gradient)" />
                                //                         <path id="Tracé_218" data-name="Tracé 218" d="M430.945,447.352a28.3,28.3,0,0,0-4.46,1.669l-1.4-3.822a31.285,31.285,0,0,1,5.28-1.912,24.327,24.327,0,0,1,6.129-.7q6.431,0,9.891,3.185t3.459,9.436v19.175h-4.49v-4.733a15.362,15.362,0,0,1-4.4,3.762,13.443,13.443,0,0,1-6.949,1.639,15.326,15.326,0,0,1-4.277-.607,11.636,11.636,0,0,1-3.762-1.851,9.478,9.478,0,0,1-2.671-3.064,8.811,8.811,0,0,1-1-4.308,9.587,9.587,0,0,1,.971-4.4,8.7,8.7,0,0,1,2.76-3.185,13.254,13.254,0,0,1,4.248-1.942,20.709,20.709,0,0,1,5.431-.668,33.423,33.423,0,0,1,5.279.365,38.345,38.345,0,0,1,4.369.971v-1.093q0-4.246-2.458-6.4a10.108,10.108,0,0,0-6.887-2.154A18.764,18.764,0,0,0,430.945,447.352Zm-1.494,12.895a5.66,5.66,0,0,0-2.359,4.793,5.447,5.447,0,0,0,.635,2.67,6.149,6.149,0,0,0,1.724,1.972,7.853,7.853,0,0,0,2.509,1.244,10.387,10.387,0,0,0,2.994.424,12.788,12.788,0,0,0,4.082-.637,10.851,10.851,0,0,0,3.328-1.76,8.341,8.341,0,0,0,2.237-2.669,7.239,7.239,0,0,0,.816-3.429v-3.034q-1.754-.486-4.022-.911a28.33,28.33,0,0,0-5.17-.425Q431.81,458.487,429.451,460.247Z" transform="translate(-187.165 -429.846)" fill="#fff" />
                                //                         <path id="Tracé_219" data-name="Tracé 219" d="M470.072,475.407q-2,4.853-4.46,6.857a9.16,9.16,0,0,1-5.977,2,14.556,14.556,0,0,1-3.519-.394,15.565,15.565,0,0,1-3.156-1.183l1.578-3.7a9.977,9.977,0,0,0,2.275.88,11.3,11.3,0,0,0,2.64.272,5.243,5.243,0,0,0,3.58-1.274,12.764,12.764,0,0,0,2.852-4.612L451.625,443h5.157l11.409,26.154L478.263,443h4.976Z" transform="translate(-186.455 -429.836)" fill="#fff" />
                                //                     </g>
                                //                     <g id="Groupe_94" data-name="Groupe 94" transform="translate(85.678 423.155)">
                                //                         <path id="Tracé_220" data-name="Tracé 220" d="M85.678,425.32,120,481.1a20.381,20.381,0,0,0,23.926,8.612l2.582-.88-33.4-55.47a21.075,21.075,0,0,0-26.307-8.52Z" transform="translate(-85.678 -423.155)" fill="url(#linear-gradient)" />
                                //                         <path id="Tracé_221" data-name="Tracé 221" d="M119.672,425.305l19.846,33.506a21.692,21.692,0,0,0,27.023,8.963h0l-20.59-33.7a21.488,21.488,0,0,0-26.279-8.764Z" transform="translate(-84.855 -423.14)" fill="url(#linear-gradient)" />
                                //                         <path id="Tracé_222" data-name="Tracé 222" d="M169.04,451.926l-9.851-17.172a4.051,4.051,0,0,1,.346-4.54c3.081-3.881,10.769-10.561,24.235-4.324Z" transform="translate(-83.912 -423.151)" fill="url(#linear-gradient)" />
                                //                     </g>
                                //                     <text id="وفير" transform="translate(411.464 494.233)" fill="#fff" font-size="24" font-family="GESSTwoLight-Light, GE SS Two" font-weight="300"><tspan x="0" y="0">وفير</tspan></text>
                                //                     <path id="Tracé_223" data-name="Tracé 223" d="M397.344,497.674l-9.32.143a1.141,1.141,0,0,1-1.186-1.375c.192-2.507,1.556-7.966,9.222-12.751Z" transform="translate(7.289 1.465)" fill="url(#linear-gradient-5)" />
                                //                 </g>
                                //             </svg>


                                //         </div>
                                //         <div className="dot" />
                                //         <div className="dot" />
                                //         <div className="dot" />
                                //         <div className='loader-wait'>S'il vous plaît, attendez</div>
                                //         <div
                                //             className="progress"
                                //             role="progressbar"
                                //             aria-label="Example with label"
                                //             aria-valuenow={progress}
                                //             aria-valuemin={0}
                                //             aria-valuemax={100}
                                //         >
                                //             <div className="progress-bar" style={{ width: progress + '%' }}>
                                //                 {progress + '%'}
                                //             </div>
                                //         </div>

                                //     </div>
                                // </div>
                                ""
                                : ''}





                            <section className='pt-0 mt-4'>
                                <div className="container-fluid">

                                    <div className='d-flex login-div'>



                                        <div className="wafeer-form pt-0">

                                            <div className="container text-center">
                                                <div style={{ fontWeight: 500 }} data-aos="fade-up" className="section-sub mt-4">
                                                    Scan Documents
                                                </div>
                                                <div data-aos="fade-down" className="wafeer-vertical-separation mt-3" />
                                            </div>





                                            <div className="col-lg-6">
                                                <div className='d-flex justify-content-center align-items-center mb-2'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">
                                                   
                                                        Ouverture de compte

                                                        {OldOuverturedecompte ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>
                                                {OldOuverturedecompte ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldOuverturedecompte}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('ouverture_de_compte')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                {/* {OldOuverturedecompte ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldOuverturedecompte}`}> Voir le Fichier actuel </a> : ''} */}
                                                {/* {OldOuverturedecompte ? <div onClick={() => DeleteImage('ouverture_de_compte')} className='delete-current-file' >Supprimer</div> : ''} */}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>
                                                        <input type="file" ref={Ouverture_de_compteRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setOuverturedecompte(e.target.files[0]) : setOuverturedecompte('')} className="form-control" id="inputCity" />

                                                    </div>
                                                    {Ouverturedecompte ? <div onClick={(e) => !StartUpload.ouverture_de_compte ? Register(e, 'ouverture_de_compte', Ouverturedecompte, 'Ouverturedecompte') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ""}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Ouverturedecompte !== '' ? errors.Ouverturedecompte : ''}</div>
                                                {ResponseMsgSuccsess.ouverture_de_compte != null ? <div className='ok'>{ResponseMsgSuccsess.ouverture_de_compte}</div> : ''}
                                                {ResponseMsgFail.ouverture_de_compte != null ? <div className='no'>{ResponseMsgFail.ouverture_de_compte}</div> : ''}
                                                {
                                                    StartUpload.ouverture_de_compte ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.ouverture_de_compte}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.ouverture_de_compte + '%' }}>
                                                                {progress.ouverture_de_compte + '%'}
                                                            </div>
                                                        </div> : ''}
                                            </div>

                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0"> 
                                                    Documents projet
                                                    {/* Centrale des Risques */}
                                                        {OldCentraledesRisques ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>
                                                {OldCentraledesRisques ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldCentraledesRisques}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('centrale_des_risques')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>
                                                        <input type="file" ref={centrale_des_risquesRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setCentraledesRisques(e.target.files[0]) : setCentraledesRisques('')} className="form-control" id="inputCity" />
                                                        {/* {OldCentraledesRisques  ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldCentraledesRisques }`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {CentraledesRisques ? <div onClick={(e) => !StartUpload.centrale_des_risques ? Register(e, 'centrale_des_risques', CentraledesRisques, 'CentraledesRisques') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.CentraledesRisques !== '' ? errors.CentraledesRisques : ''}</div>
                                                {ResponseMsgSuccsess.centrale_des_risques != null ? <div className='ok'>{ResponseMsgSuccsess.centrale_des_risques}</div> : ''}
                                                {ResponseMsgFail.centrale_des_risques != null ? <div className='no'>{ResponseMsgFail.centrale_des_risques}</div> : ''}
                                                {
                                                    StartUpload.centrale_des_risques ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.centrale_des_risques}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.centrale_des_risques + '%' }}>
                                                                {progress.centrale_des_risques + '%'}
                                                            </div>
                                                        </div> : ''}
                                            </div>

                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">
                                                         {/* Fiche FATCA */}

                                                         État civil

                                                        {OldFicheFatca ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>
                                                {OldFicheFatca ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFicheFatca}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('fiche_fatca')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>
                                                        <input type="file" ref={fiche_fatcaRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setFicheFatca(e.target.files[0]) : setFicheFatca('')} className="form-control" id="inputCity" />
                                                        {/* {OldFicheFatca ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFicheFatca}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {FicheFatca ? <div onClick={(e) => !StartUpload.fiche_fatca ? Register(e, 'fiche_fatca', FicheFatca, 'FicheFatca') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.FicheFatca !== '' ? errors.FicheFatca : ''}</div>
                                                {ResponseMsgSuccsess.fiche_fatca != null ? <div className='ok'>{ResponseMsgSuccsess.fiche_fatca}</div> : ''}
                                                {ResponseMsgFail.fiche_fatca != null ? <div className='no'>{ResponseMsgFail.fiche_fatca}</div> : ''}
                                                {
                                                    StartUpload.fiche_fatca ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.fiche_fatca}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.fiche_fatca + '%' }}>
                                                                {progress.fiche_fatca + '%'}
                                                            </div>
                                                        </div> : ''}
                                            </div>

                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Demande de Financement

                                                        {OldDemandedeFinancement ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>

                                                {OldDemandedeFinancement ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDemandedeFinancement}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('demande_de_financement')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>
                                                        <input type="file" ref={demande_de_financementRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setDemandedeFinancement(e.target.files[0]) : setDemandedeFinancement('')} className="form-control" id="inputCity" />
                                                        {/* {OldDemandedeFinancement ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDemandedeFinancement}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {DemandedeFinancement ? <div onClick={(e) => !StartUpload.demande_de_financement ? Register(e, 'demande_de_financement', DemandedeFinancement, 'DemandedeFinancement') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.DemandedeFinancement !== '' ? errors.DemandedeFinancement : ''}</div>
                                                {ResponseMsgSuccsess.demande_de_financement != null ? <div className='ok'>{ResponseMsgSuccsess.demande_de_financement}</div> : ''}
                                                {ResponseMsgFail.demande_de_financement != null ? <div className='no'>{ResponseMsgFail.demande_de_financement}</div> : ''}
                                                {
                                                    StartUpload.demande_de_financement ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.demande_de_financement}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.demande_de_financement + '%' }}>
                                                                {progress.demande_de_financement + '%'}
                                                            </div>
                                                        </div> : ''}
                                            </div>

                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Rapport d'entretien
                                                        {OldRapportdentretien ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>

                                                {OldRapportdentretien ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldRapportdentretien}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('rapport_dentretien')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>
                                                        <input type="file" ref={rapport_dentretienRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setRapportdentretien(e.target.files[0]) : setRapportdentretien('')} className="form-control" id="inputCity" />
                                                        {/* {OldRapportdentretien ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldRapportdentretien}`}> Voir le Fichier actuel </a> : ''} */}

                                                    </div>

                                                    {Rapportdentretien ? <div onClick={(e) => !StartUpload.rapport_dentretien ? Register(e, 'rapport_dentretien', Rapportdentretien, 'Rapportdentretien') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Rapportdentretien !== '' ? errors.Rapportdentretien : ''}</div>
                                                {ResponseMsgSuccsess.rapport_dentretien != null ? <div className='ok'>{ResponseMsgSuccsess.rapport_dentretien}</div> : ''}
                                                {ResponseMsgFail.rapport_dentretien != null ? <div className='no'>{ResponseMsgFail.rapport_dentretien}</div> : ''}
                                                {
                                                    StartUpload.rapport_dentretien ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.rapport_dentretien}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.rapport_dentretien + '%' }}>
                                                                {progress.rapport_dentretien + '%'}
                                                            </div>
                                                        </div> : ''}
                                            </div>

                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">
                                                        {/* Déclaration d'engagement */}
                                                        Dossier garantie
                                                        {OldDéclarationdengagement ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>
                                                {OldDéclarationdengagement ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDéclarationdengagement}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('declaration_dengagement')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={declaration_dengagementRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setDéclarationdengagement(e.target.files[0]) : setDéclarationdengagement('')} className="form-control" id="inputCity" />
                                                        {/* {OldDéclarationdengagement ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDéclarationdengagement}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Déclarationdengagement ? <div onClick={(e) => !StartUpload.declaration_dengagement ? Register(e, 'declaration_dengagement', Déclarationdengagement, 'Déclarationdengagement') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Déclarationdengagement !== '' ? errors.Déclarationdengagement : ''}</div>
                                                {ResponseMsgSuccsess.declaration_dengagement != null ? <div className='ok'>{ResponseMsgSuccsess.declaration_dengagement}</div> : ''}
                                                {ResponseMsgFail.declaration_dengagement != null ? <div className='no'>{ResponseMsgFail.declaration_dengagement}</div> : ''}
                                                {
                                                    StartUpload.declaration_dengagement ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.declaration_dengagement}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.declaration_dengagement + '%' }}>
                                                                {progress.declaration_dengagement + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>


                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Fiche de signature

                                                        {OldFicheDeSignature ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>
                                                {OldFicheDeSignature ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFicheDeSignature}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('fiche_de_signature')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={fiche_de_signatureRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setFicheDeSignature(e.target.files[0]) : setFicheDeSignature('')} className="form-control" id="inputCity" />
                                                        {/* {OldFicheDeSignature ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFicheDeSignature}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {FicheDeSignature ? <div onClick={(e) => !StartUpload.fiche_de_signature ? Register(e, 'fiche_de_signature', FicheDeSignature, 'FicheDeSignature') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.FicheDeSignature !== '' ? errors.FicheDeSignature : ''}</div>
                                                {ResponseMsgSuccsess.fiche_de_signature != null ? <div className='ok'>{ResponseMsgSuccsess.fiche_de_signature}</div> : ''}
                                                {ResponseMsgFail.fiche_de_signature != null ? <div className='no'>{ResponseMsgFail.fiche_de_signature}</div> : ''}
                                                {
                                                    StartUpload.fiche_de_signature ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.fiche_de_signature}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.fiche_de_signature + '%' }}>
                                                                {progress.fiche_de_signature + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>
                                            

                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Business Plan

                                                        {OldBusinessPlan ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }


                                                    </label>
                                                </div>
                                                {OldBusinessPlan ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldBusinessPlan}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('business_plan')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={business_planRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setBusinessPlan(e.target.files[0]) : setBusinessPlan('')} className="form-control" id="inputCity" />
                                                        {/* {OldBusinessPlan ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldBusinessPlan}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {BusinessPlan ? <div onClick={(e) => !StartUpload.business_plan ? Register(e, 'business_plan', BusinessPlan, 'BusinessPlan') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.BusinessPlan !== '' ? errors.BusinessPlan : ''}</div>
                                                {ResponseMsgSuccsess.business_plan != null ? <div className='ok'>{ResponseMsgSuccsess.business_plan}</div> : ''}
                                                {ResponseMsgFail.business_plan != null ? <div className='no'>{ResponseMsgFail.business_plan}</div> : ''}
                                                {
                                                    StartUpload.business_plan ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.business_plan}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.business_plan + '%' }}>
                                                                {progress.business_plan + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>
                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Annexe 6

                                                        {OldAnnexe6 ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>

                                                {OldAnnexe6 ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldAnnexe6}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('annexe_6')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={annexe_6Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setAnnexe6(e.target.files[0]) : setAnnexe6('')} className="form-control" id="inputCity" />
                                                        {/* {OldAnnexe6 ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldAnnexe6}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Annexe6 ? <div onClick={(e) => !StartUpload.annexe_6 ? Register(e, 'annexe_6', Annexe6, 'Annexe6') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Annexe6 !== '' ? errors.Annexe6 : ''}</div>
                                                {ResponseMsgSuccsess.annexe_6 != null ? <div className='ok'>{ResponseMsgSuccsess.annexe_6}</div> : ''}
                                                {ResponseMsgFail.annexe_6 != null ? <div className='no'>{ResponseMsgFail.annexe_6}</div> : ''}
                                                {
                                                    StartUpload.annexe_6 ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.annexe_6}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.annexe_6 + '%' }}>
                                                                {progress.annexe_6 + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>
                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0"> Facture

                                                        {OldFacture ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>

                                                {OldFacture ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFacture}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('la_facture')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={la_factureRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setFacture(e.target.files[0]) : setFacture('')} className="form-control" id="inputCity" />
                                                        {/* {OldFacture ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFacture}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Facture ? <div onClick={(e) => !StartUpload.la_facture ? Register(e, 'la_facture', Facture, 'Facture') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Facture !== '' ? errors.Facture : ''}</div>
                                                {ResponseMsgSuccsess.la_facture != null ? <div className='ok'>{ResponseMsgSuccsess.la_facture}</div> : ''}
                                                {ResponseMsgFail.la_facture != null ? <div className='no'>{ResponseMsgFail.la_facture}</div> : ''}
                                                {
                                                    StartUpload.la_facture ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.la_facture}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.la_facture + '%' }}>
                                                                {progress.la_facture + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>


                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Documents supplémentaires
                                                        {OldDocumentsSupplémentaires ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>


                                                {OldDocumentsSupplémentaires ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('documents_supplementaires')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={documents_supplementairesRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setDocumentsSupplémentaires(e.target.files[0]) : setDocumentsSupplémentaires('')} className="form-control" id="inputCity" />
                                                        {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {DocumentsSupplémentaires ? <div onClick={(e) => !StartUpload.documents_supplementaires ? Register(e, 'documents_supplementaires', DocumentsSupplémentaires, 'DocumentsSupplémentaires') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.documents_supplementaires !== '' ? errors.documents_supplementaires : ''}</div>
                                                {ResponseMsgSuccsess.documents_supplementaires != null ? <div className='ok'>{ResponseMsgSuccsess.documents_supplementaires}</div> : ''}
                                                {ResponseMsgFail.documents_supplementaires != null ? <div className='no'>{ResponseMsgFail.documents_supplementaires}</div> : ''}
                                                {
                                                    StartUpload.documents_supplementaires ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.documents_supplementaires}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.documents_supplementaires + '%' }}>
                                                                {progress.documents_supplementaires + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>

                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Carte d’artisanat
                                                        {OldCarteDartisanat ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>


                                                {OldCarteDartisanat ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldCarteDartisanat}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('carte_dartisanat')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={carte_dartisanatRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setCarteDartisanat(e.target.files[0]) : setCarteDartisanat('')} className="form-control" id="inputCity" />
                                                        {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {CarteDartisanat ? <div onClick={(e) => !StartUpload.carte_dartisanat ? Register(e, 'carte_dartisanat', CarteDartisanat, 'carte_dartisanat') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.carte_dartisanat !== '' ? errors.carte_dartisanat : ''}</div>
                                                {ResponseMsgSuccsess.carte_dartisanat != null ? <div className='ok'>{ResponseMsgSuccsess.carte_dartisanat}</div> : ''}
                                                {ResponseMsgFail.carte_dartisanat != null ? <div className='no'>{ResponseMsgFail.carte_dartisanat}</div> : ''}
                                                {
                                                    StartUpload.carte_dartisanat ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.carte_dartisanat}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.carte_dartisanat + '%' }}>
                                                                {progress.carte_dartisanat + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>

                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Registre de Commerce
                                                        {OldRegistreDeCommerce ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>


                                                {OldRegistreDeCommerce ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldRegistreDeCommerce}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('registre_de_commerce')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={registre_de_commerceRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setRegistreDeCommerce(e.target.files[0]) : setRegistreDeCommerce('')} className="form-control" id="inputCity" />
                                                        {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {RegistreDeCommerce ? <div onClick={(e) => !StartUpload.registre_de_commerce ? Register(e, 'registre_de_commerce', RegistreDeCommerce, 'registre_de_commerce') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.registre_de_commerce !== '' ? errors.registre_de_commerce : ''}</div>
                                                {ResponseMsgSuccsess.registre_de_commerce != null ? <div className='ok'>{ResponseMsgSuccsess.registre_de_commerce}</div> : ''}
                                                {ResponseMsgFail.registre_de_commerce != null ? <div className='no'>{ResponseMsgFail.registre_de_commerce}</div> : ''}
                                                {
                                                    StartUpload.registre_de_commerce ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.registre_de_commerce}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.registre_de_commerce + '%' }}>
                                                                {progress.registre_de_commerce + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>

                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Diplôme
                                                        {OldDiplome ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>


                                                {OldDiplome ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDiplome}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('diplome')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={diplomeRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setDiplome(e.target.files[0]) : setDiplome('')} className="form-control" id="inputCity" />
                                                        {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Diplome ? <div onClick={(e) => !StartUpload.diplome ? Register(e, 'diplome', Diplome, 'diplome') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.diplome !== '' ? errors.diplome : ''}</div>
                                                {ResponseMsgSuccsess.diplome != null ? <div className='ok'>{ResponseMsgSuccsess.diplome}</div> : ''}
                                                {ResponseMsgFail.diplome != null ? <div className='no'>{ResponseMsgFail.diplome}</div> : ''}
                                                {
                                                    StartUpload.diplome ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.diplome}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.diplome + '%' }}>
                                                                {progress.diplome + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>

                                            {/* <div className="have-already mt-3">
                        <a href="/login" style={{ color: '#4b4b4b' }}>
                            J'ai déjà un compte ? Log in
                        </a>
                    </div> */}


                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">  طلب رخصة وضع عربة تاجر متنقل  
                                                        {OldDemanderunpermis ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }
                                                    </label>
                                                </div>


                                                {OldDemanderunpermis ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDemanderunpermis}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('demander_permis')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={demander_permisRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setDemanderunpermis(e.target.files[0]) : setDemanderunpermis('')} className="form-control" id="inputCity" />
                                                        {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Demanderunpermis ? <div onClick={(e) => !StartUpload.demander_permis ? Register(e, 'demander_permis', Demanderunpermis, 'Demanderunpermis') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.demander_permis !== '' ? errors.demander_permis : ''}</div>
                                                {ResponseMsgSuccsess.demander_permis != null ? <div className='ok'>{ResponseMsgSuccsess.demander_permis}</div> : ''}
                                                {ResponseMsgFail.demander_permis != null ? <div className='no'>{ResponseMsgFail.demander_permis}</div> : ''}
                                                {
                                                    StartUpload.demander_permis ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.demander_permis}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.demander_permis + '%' }}>
                                                                {progress.demander_permis + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>

                                            <div className="col-lg-6 mt-5 d-none">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0"> تعهد شرفي

                                                        {OldEngagement ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>
                                                {OldEngagement ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldEngagement}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('engagement')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={engagementRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setEngagement(e.target.files[0]) : setEngagement('')} className="form-control" id="inputCity" />
                                                        {/* {OldFicheDeSignature ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldFicheDeSignature}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Engagement ? <div onClick={(e) => !StartUpload.engagement ? Register(e, 'engagement', Engagement, 'Engagement') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.FicheDeSignature !== '' ? errors.FicheDeSignature : ''}</div>
                                                {ResponseMsgSuccsess.engagement != null ? <div className='ok'>{ResponseMsgSuccsess.engagement}</div> : ''}
                                                {ResponseMsgFail.engagement != null ? <div className='no'>{ResponseMsgFail.engagement}</div> : ''}
                                                {
                                                    StartUpload.engagement ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.engagement}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.engagement + '%' }}>
                                                                {progress.engagement + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>


                                            <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Bon de réception (Stock)
                                                        {OldAnnexe2 ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>
                                                {OldAnnexe2 ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldAnnexe2}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('annexe_2')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={annexe_2Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setAnnexe2(e.target.files[0]) : setAnnexe2('')} className="form-control" id="inputCity" />
                                                        {/* {OldAnnexe2 ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldAnnexe2}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {Annexe2 ? <div onClick={(e) => !StartUpload.annexe_2 ? Register(e, 'annexe_2', Annexe2, 'Annexe2') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Annexe2 !== '' ? errors.Annexe2 : ''}</div>
                                                {ResponseMsgSuccsess.annexe_2 != null ? <div className='ok'>{ResponseMsgSuccsess.annexe_2}</div> : ''}
                                                {ResponseMsgFail.annexe_2 != null ? <div className='no'>{ResponseMsgFail.annexe_2}</div> : ''}
                                                {
                                                    StartUpload.annexe_2 ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.annexe_2}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.annexe_2 + '%' }}>
                                                                {progress.annexe_2 + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>



                                            <div className="col-lg-6 mt-5">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0"> Echeancier
                                        
                                        {OldEcheancier ?
                                                <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            } 
                                        </label>
                                    </div>
                                    
                                    {OldEcheancier ?
                                    <div className='document-btn'>

                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldEcheancier}`}>
                                            <div style={{color : 'white'}} className='me-2'>
                                            Voir Le Fichier
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                            <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                    <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                </g>
                                                <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                    <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                </g>
                                                <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                    <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                </g>
                                            </g>
                                        </svg>
                                        </a> 

                                        {/* <a onClick={() => DeleteImage('echeancier')} > 
                                        <div className='delete-btn my-1 d-flex'>
                                        <div style={{color : 'white'}} className='me-2'>
                                        Supprimer Le Fichier
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                            </svg>
                                            </div></a> */}
                                            
                                    </div>
                                            : <div className='text-center empty-scan'>Vide</div> }
                                    {/* <div className='d-md-flex justify-content-center'>
                                        <div>

                                            <input type="file" ref={echeancierRef}  accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setEcheancier(e.target.files[0]) : setEcheancier('')} className="form-control" id="inputCity" />
                                            
                                        </div>
                                        {Echeancier ?     <div onClick={(e) => !StartUpload.echeancier ? Register(e, 'echeancier', Echeancier, 'echeancier') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            Upload
                                        </div> : ''}
                                    </div> */}
                                    {/* <div className='err-cart-sum'>{Showerrors && errors.echeancier !== '' ? errors.echeancier : ''}</div>
                                    {ResponseMsgSuccsess.echeancier != null ? <div className='ok'>{ResponseMsgSuccsess.echeancier}</div> : ''}
                                    {ResponseMsgFail.echeancier != null ? <div className='no'>{ResponseMsgFail.echeancier}</div> : ''}
                                    {
                                        StartUpload.echeancier ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.echeancier}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.echeancier + '%' }}>
                                                    {progress.echeancier + '%'}
                                                </div>
                                            </div> : ''} */}

                                </div>


                                <div className="col-lg-6 mt-5">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                                        </g>
                                                    </svg>
                                                    <label htmlFor="inputState" className="form-label mb-0">Assurance / Carte Grise
                                                        {OldAssuranceCarteGrise ?
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#7acf7d" />
                                                                        <stop offset="1" stop-color="#84e0be" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                                    <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                                </g>
                                                            </svg>
                                                            :
                                                            <svg className='ms-1' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                                <defs>
                                                                    <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                                        <stop offset="0" stop-color="#ff5858" />
                                                                        <stop offset="1" stop-color="#e084a6" />
                                                                    </linearGradient>
                                                                </defs>
                                                                <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                            </svg>
                                                        }

                                                    </label>
                                                </div>
                                                {OldAssuranceCarteGrise ?
                                                    <div className='document-btn'>

                                                        <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldAssuranceCarteGrise}`}>
                                                            <div style={{ color: 'white' }} className='me-2'>
                                                                Voir Le Fichier
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                                    </g>
                                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>

                                                        <a onClick={() => DeleteImage('assurance_cartegrise')} >
                                                            <div className='delete-btn my-1 d-flex'>
                                                                <div style={{ color: 'white' }} className='me-2'>
                                                                    Supprimer Le Fichier
                                                                </div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                                    <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                                </svg>
                                                            </div></a>

                                                    </div>
                                                    : ''}
                                                <div className='d-md-flex justify-content-center'>
                                                    <div>

                                                        <input type="file" ref={assurance_cartegriseRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setAssuranceCarteGrise(e.target.files[0]) : setAssuranceCarteGrise('')} className="form-control" id="inputCity" />
                                                        {/* {OldAnnexe2 ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldAnnexe2}`}> Voir le Fichier actuel </a> : ''} */}
                                                    </div>
                                                    {AssuranceCarteGrise ? <div onClick={(e) => !StartUpload.assurance_cartegrise ? Register(e, 'assurance_cartegrise', AssuranceCarteGrise, 'AssuranceCarteGrise') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                                        Upload
                                                    </div> : ''}
                                                </div>
                                                <div className='err-cart-sum'>{Showerrors && errors.Annexe2 !== '' ? errors.Annexe2 : ''}</div>
                                                {ResponseMsgSuccsess.assurance_cartegrise != null ? <div className='ok'>{ResponseMsgSuccsess.assurance_cartegrise}</div> : ''}
                                                {ResponseMsgFail.assurance_cartegrise != null ? <div className='no'>{ResponseMsgFail.assurance_cartegrise}</div> : ''}
                                                {
                                                    StartUpload.assurance_cartegrise ?


                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Example with label"
                                                            aria-valuenow={progress.assurance_cartegrise}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            <div className="progress-bar" style={{ width: progress.assurance_cartegrise + '%' }}>
                                                                {progress.assurance_cartegrise + '%'}
                                                            </div>
                                                        </div> : ''}

                                            </div>






                                        </div>



                                    </div>
                                </div>
                            </section>












                        </form>
                    </div ></div>
            </div>

        </div>
    )
}

export default ScanDocuments