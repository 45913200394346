import {useState} from 'react'
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

function GoBack({url}) {

    let navigate = useNavigate();

    function Back() {
        if (document.referrer && document.referrer.length > 0 && document.referrer.startsWith(`${process.env.REACT_APP_URL}`)) {
            // user came from an internal link
            return navigate(-1);
          }
          else {
            // user came from a bookmark or an external link
           return navigate(url);
          }
        
    }

    return (
        <div className='container'><div onClick={()=>  Back()} className='dashboard-back'>{'<<'} Retour </div></div>
    )
}

export default GoBack